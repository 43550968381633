import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BrandCreate from '../components/Brands/CreateBrand';
import DashboardSideBar from '../components/Shop/Layout/DashboardSideBar';
import BrandSelection from '../components/Brands/BrandSelection';
import DashboardHeader from '../components/Shop/Layout/DashboardHeader';
import CreateBrandPrompt from '../components/Brands/CreateBrandPrompt'; 
import { fetchOwnedBrands } from '../redux/actions/brand'; 

const BrandPage = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const seller = useSelector((state) => state.seller.seller);
  const ownedBrands = useSelector((state) => state.brand.ownedBrands); 

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (seller) {
      dispatch(fetchOwnedBrands());
    }
  }, [seller, dispatch]);

  return (
    <div>
      <DashboardHeader />

      <div className="brand-page-container">
        <div className="brand-page-sidebar">
          <DashboardSideBar active={9} />
        </div>
        <div className="brand-page-main-container">
          <div className="brand-page-grid-container">
            <div>
              
              {ownedBrands?.length === 0 ? (
                <CreateBrandPrompt handleOpen={handleOpen} />
              ) : (
                <button className="brand-page-button" onClick={handleOpen}>
                  Create New Brand
                </button>
              )}
              {open && (
                <>
                  <div className="brand-page-modal-overlay" onClick={handleClose}></div>
                  <div
                    className="brand-page-modal"
                    role="dialog"
                    aria-labelledby="create-brand-modal-title"
                    aria-describedby="create-brand-modal-description"
                  >
                    <div className="brand-page-modal-header">
                      <h2 id="create-brand-modal-title" className="brand-page-modal-title">
                        Create a New Brand
                      </h2>
                      <button className="brand-page-modal-close-button" onClick={handleClose}>
                        &times;
                      </button>
                    </div>
                    <BrandCreate />
                  </div>
                </>
              )}
            </div>
            <div>
              <BrandSelection ownedBrands={ownedBrands} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandPage;

