import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import DualSponsoredDashboard from '../../components/Sponsored/DualSponsoredDashboard';
import AdTypeDistributionChart from '../../components/Sponsored/AdTypeDistributionChart';
import TopPerformingAds from '../../components/Sponsored/TopPerformingAds';
import { adsServer } from '../../server';
import CampaignCreateModal from '../../components/Sponsored/CampaignCreatorModal';
import Loader from '../../components/Layout/Loader';

const ShopCreateAdvertisement = () => {
  const [sponsoredAds, setSponsoredAds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchSponsoredAdsAndMetrics = async () => {
      setIsLoading(true);
      try {
        const token = localStorage.getItem('token');

        // Fetch sponsored ads
        const response = await axios.get(`${adsServer}/sponsored/sponsored-ads`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
          },
        });

        const sortedAds = response.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        // Fetch metrics for all ads in a single request
        const adIds = sortedAds.map(ad => ad._id);
        const metricsData = await fetchAdMetricsBatch(adIds);

        // Combine ads with metrics
        const adsWithMetrics = sortedAds.map(ad => ({
          ...ad,
          metrics: metricsData[ad._id] || { clicks: [], viewedImpressions: [] },
        }));

        setSponsoredAds(adsWithMetrics);
      } catch (error) {
        console.error('Error fetching sponsored ads:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSponsoredAdsAndMetrics();
  }, []);

  const fetchAdMetricsBatch = async (adIds) => {
    try {
      const response = await axios.post(
        `${adsServer}/sponsored/metrics/batch`,
        { adIds },
        {
          withCredentials: true,
          headers: {
            'current-profile': sessionStorage.getItem('currentProfile') || 'defaultProfile',
          },
        }
      );
      return response.data; // Response should contain metrics for all ad IDs
    } catch (error) {
      console.error('Error fetching metrics:', error);
      return [];
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="shop-create-advertisement-campaign-container">
      <DashboardHeader />
      <div className="ad-dashboard-layout">
        <DashboardSideBar active={8} />
        <div className="shop-create-advertisement-campaign-content">
          <div className="shop-create-advertisement-campaign-sidebar">
            <div className="shop-create-advertisement-campaign-box">
              <button className="create-ad-button" onClick={openModal}>
                Create Campaign
              </button>

              {/* Modal component */}
              <CampaignCreateModal isOpen={isModalOpen} onClose={closeModal} />
            </div>

            {/* Only render these charts when NOT loading */}
            {!isLoading && (
              <>
                <AdTypeDistributionChart ads={sponsoredAds} />
                <TopPerformingAds ads={sponsoredAds} />
              </>
            )}
          </div>

          <div className="shop-create-advertisement-campaign-main">
            {!isLoading && (
              <DualSponsoredDashboard
                sponsoredAds={sponsoredAds}
                onUpdateAds={setSponsoredAds}
                isLoading={isLoading}
              />
            )}
          </div>
        </div>
      </div>

      {/* Optional backdrop overlay if the modal is open */}
      {isModalOpen && <div className="mobile-background-overlay"></div>}

      {/* 2) Display Loader if isLoading === true */}
      {isLoading && <Loader />}
    </div>
  );
};

export default ShopCreateAdvertisement;


