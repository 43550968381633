import React, { useEffect, useState } from "react";
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfAdmin } from "../redux/actions/order";
import Loader from "../components/Layout/Loader";

// Chart.js imports
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { format, parseISO } from "date-fns";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const AdminDashboardOrders = () => {
  const dispatch = useDispatch();

  // Redux state
  const { adminOrders, adminOrderLoading, error } = useSelector(
    (state) => state.order
  );

  // Local state for timeframe filtering
  const [timeframe, setTimeframe] = useState("daily"); // "daily" | "monthly"

  // Chart states
  const [ordersOverTimeData, setOrdersOverTimeData] = useState({
    labels: [],
    datasets: [
      {
        label: "Orders Over Time",
        data: [],
        borderColor: "#4BC0C0",
        backgroundColor: "rgba(75,192,192,0.2)",
        tension: 0.4,
        fill: true,
      },
    ],
  });

  const [ordersPerShopData, setOrdersPerShopData] = useState({
    labels: [],
    datasets: [
      {
        label: "Orders Per Shop",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  });

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
  }, [dispatch]);

  // Once adminOrders loads or timeframe changes, process the charts
  useEffect(() => {
    if (adminOrders && adminOrders.length > 0) {
      processOrdersOverTime(adminOrders, timeframe);
      processOrdersPerShop(adminOrders);
    }
  }, [adminOrders, timeframe]);

  // --------------------------
  //   PROCESS: ORDERS OVER TIME
  // --------------------------
  const processOrdersOverTime = (orders, timeframe) => {
    // Sort by creation date (ascending)
    const sorted = [...orders].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );

    const counts = {};

    sorted.forEach((order) => {
      if (!order.createdAt) return;

      // Safely parse the date
      const dateObj = new Date(order.createdAt);
      const isoDate = parseISO(dateObj.toISOString());

      let label = "";
      if (timeframe === "daily") {
        // Example: "01/23/2025"
        label = format(isoDate, "MM/dd/yyyy");
      } else {
        // timeframe === "monthly"
        // Example: "01/2025"
        label = format(isoDate, "MM/yyyy");
      }

      counts[label] = (counts[label] || 0) + 1;
    });

    // Sort labels in chronological order
    const labels = Object.keys(counts).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    setOrdersOverTimeData({
      labels,
      datasets: [
        {
          label: "Orders Over Time",
          data: labels.map((lbl) => counts[lbl]),
          borderColor: "#4BC0C0",
          backgroundColor: "rgba(75,192,192,0.2)",
          tension: 0.4,
          fill: true,
        },
      ],
    });
  };

  // --------------------------
  //   PROCESS: ORDERS PER SHOP
  // --------------------------
  const processOrdersPerShop = (orders) => {
    // Count how many orders each shop has
    const shopCounts = {};

    orders.forEach((order) => {
      // Some orders may contain multiple shops in cart
      const shopSet = new Set();
      order.cart.forEach((item) => {
        // Use shopName (not shopId)
        // Fallback to "Unknown" if neither is available
        const shopName = item.shopName || "Unknown";
        shopSet.add(shopName);
      });
      // Increment each shop's order count by 1
      shopSet.forEach((shop) => {
        shopCounts[shop] = (shopCounts[shop] || 0) + 1;
      });
    });

    const shopLabels = Object.keys(shopCounts);
    const shopValues = Object.values(shopCounts);

    setOrdersPerShopData({
      labels: shopLabels,
      datasets: [
        {
          label: "Orders Per Shop",
          data: shopValues,
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    });
  };

  // --------------------------
  //   TABLE DATA
  // --------------------------
  const transformedOrders = adminOrders?.map((order) => ({
    id: order._id,
    itemsQty: order.cart.reduce((acc, item) => acc + item.qty, 0),
    total: `${order.totalPrice.toFixed(2)} $`,
    status: order.status,
    createdAt: new Date(order.createdAt).toLocaleDateString(),
  }));

  return (
    <div className="admin-dashboard-orders">
      <AdminHeader />
      <div className="admin-dashboard-orders__content">
        <AdminSideBar active={2} />

        <main className="admin-dashboard-orders__main">
          {adminOrderLoading ? (
            <Loader />
          ) : error ? (
            <div className="admin-dashboard-orders__error">
              <p>{error}</p>
            </div>
          ) : (
            <>
              {/* Timeframe Buttons (Styled Like Example) */}
              <div className="admin-all-products-component-timeframe-container">
                <button
                  className={`admin-all-products-component-timeframe-button ${
                    timeframe === "daily" ? "active" : ""
                  }`}
                  onClick={() => setTimeframe("daily")}
                >
                  Daily
                </button>
                <button
                  className={`admin-all-products-component-timeframe-button ${
                    timeframe === "monthly" ? "active" : ""
                  }`}
                  onClick={() => setTimeframe("monthly")}
                >
                  Monthly
                </button>
              </div>

              {/* Charts Container */}
              <div className="admin-charts" >
    
                {/* Line Chart - Orders Over Time */}
                <div style={{ flex: 1, height: "400px" }}>
                  <Line
                    data={ordersOverTimeData}
                    options={{
                      maintainAspectRatio: false,
                      plugins: {
                        legend: { position: "top" },
                        title: {
                          display: true,
                          text: `Orders Over Time (${timeframe})`,
                        },
                      },
                      scales: {
                        x: {
                          type: "time",
                          time: {
                            parser:
                              timeframe === "daily" ? "MM/dd/yyyy" : "MM/yyyy",
                            unit: timeframe === "daily" ? "day" : "month",
                          },
                        },
                        y: { beginAtZero: true },
                      },
                    }}
                  />
                </div>

                {/* Bar Chart - Orders Per Shop */}
                <div style={{ flex: 1, height: "400px" }}>
                  <Bar
                    data={ordersPerShopData}
                    options={{
                      maintainAspectRatio: false,
                      plugins: {
                        legend: { position: "top" },
                        title: {
                          display: true,
                          text: "Orders Per Shop",
                        },
                      },
                      scales: {
                        y: { beginAtZero: true },
                      },
                    }}
                  />
                </div>
              </div>

              {/* Orders Table */}
              <section className="admin-dashboard-orders__section">
                <h2 className="admin-dashboard-orders__title">All Orders</h2>
                <div className="admin-dashboard-orders__table-container">
                  <table className="admin-dashboard-orders__table">
                    <thead className="admin-dashboard-orders__thead">
                      <tr>
                        <th>Order ID</th>
                        <th>Status</th>
                        <th>Items Qty</th>
                        <th>Total</th>
                        <th>Order Date</th>
                      </tr>
                    </thead>
                    <tbody className="admin-dashboard-orders__tbody">
                      {transformedOrders?.map((order) => (
                        <tr
                          key={order.id}
                          className="admin-dashboard-orders__tr"
                        >
                          <td>{order.id}</td>
                          <td
                            className={`admin-dashboard-orders__status ${
                              order.status === "Delivered"
                                ? "admin-dashboard-orders__status--delivered"
                                : "admin-dashboard-orders__status--pending"
                            }`}
                          >
                            {order.status}
                          </td>
                          <td>{order.itemsQty}</td>
                          <td>{order.total}</td>
                          <td>{order.createdAt}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </section>
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default AdminDashboardOrders;




