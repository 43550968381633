import React, { useState } from 'react';
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import ShippingRules from '../../components/Shipping/ShippingRules';
import RegionsList from '../../components/Region/RegionsList';
import PackingSlipForm from '../../components/Shipping/PackingSlipForm';
import USPSRateCalculator from '../../components/Shipping/USPSRateCalculator';
import ShippingCalculator from '../../components/Shipping/ShippingCostCalculator';
import CarrierSelection from '../../components/Shipping/CarrierSelection';
import CarriersOnboardingRadio from '../../components/Shipping/CarriersOnboardingRadio';
import DashboardCarriersSettings from '../../components/Shipping/DashboardCarriersSettings';
import SellerOrdersTracking from '../../components/Shipping/SellerOrdersTracking';

const ShopShipping = () => {
    const [showPackingSlipModal, setShowPackingSlipModal] = useState(false);

    const handleOpenModal = () => {
        setShowPackingSlipModal(true);
    };

    const handleCloseModal = () => {
        setShowPackingSlipModal(false);
    };

    return (
        <div>
            <DashboardHeader />
            <div className="shop-shipping-page-container">
                <div className="shop-shipping-page-sidebar">
                    <DashboardSideBar active={5} />
                </div>
                <div className="shop-shipping-page-main">
                    <h1 className="shop-shipping-page-title">Shipping</h1>

                    {/* Standard Shipping Section */}
                    <div className="shop-shipping-page-section">
                        <DashboardCarriersSettings />
                        <ShippingCalculator />
                    </div>

                    <SellerOrdersTracking />

                    {/* Custom Shipping Section */}
                    <div className="shop-shipping-page-section-custom">
                        <h2 className="shop-shipping-page-subtitle">Custom Shipping</h2>
                        <RegionsList />
                        <div className="shop-shipping-page-shipping-rules">
                            <ShippingRules />
                        </div>
                        <button 
                            onClick={handleOpenModal} 
                            className="shop-shipping-page-button-open-modal"
                        >
                            Generate Packing Slip
                        </button>                 
                        <USPSRateCalculator />
                    </div>
                </div>
            </div>
            {showPackingSlipModal && <PackingSlipForm onClose={handleCloseModal} />}
        </div>
    );
};

export default ShopShipping;


