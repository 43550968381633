import React from "react";
import { AiOutlineGift } from "react-icons/ai";
import { MdOutlineLocalOffer } from "react-icons/md";
import { FiPackage, FiShoppingBag } from "react-icons/fi";
import { BiMessageSquareDetail } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const DashboardHeader = () => {
  const { seller } = useSelector((state) => state.seller);

  // Fetch site logo from Redux
  const siteLogoUrl = useSelector((state) => state.theme.theme.siteLogoUrl);
  const fallbackLogo = "https://res.cloudinary.com/dzlopmfj8/image/upload/v1706549678/Asset-1_yjnmot.png";
  const logoToUse = siteLogoUrl || fallbackLogo;

  return (
    <div className="shop-dashboard-header-container">
      {/* Site Logo */}
      <div>
        <Link to={`/dashboard/${seller?.handle}`}>
          <img
            src={logoToUse}
            alt="Site Logo"
            className="shop-dashboard-header-logo"
          />
        </Link>
      </div>

      {/* Navigation Icons */}
      <div className="shop-dashboard-header-links">
        <div className="shop-dashboard-header-icons">
          <Link to="/dashboard-coupons" className="shop-dashboard-header-icon-link">
            <AiOutlineGift className="shop-dashboard-header-icon" />
          </Link>
          <Link to="/dashboard-events" className="shop-dashboard-header-icon-link">
            <MdOutlineLocalOffer className="shop-dashboard-header-icon" />
          </Link>
          <Link to="/dashboard-products" className="shop-dashboard-header-icon-link">
            <FiShoppingBag className="shop-dashboard-header-icon" />
          </Link>
          <Link to="/dashboard-orders" className="shop-dashboard-header-icon-link">
            <FiPackage className="shop-dashboard-header-icon" />
          </Link>
          <Link to="/dashboard-messages" className="shop-dashboard-header-icon-link">
            <BiMessageSquareDetail className="shop-dashboard-header-icon" />
          </Link>

          {/* Seller Avatar */}
          <Link to={`/shop/preview/${seller?.handle}`}>
            <img
              src={`${seller?.avatar?.url}`}
              alt=""
              className="shop-dashboard-header-avatar"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;


