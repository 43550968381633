import React, { useState, useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import { BsCartPlus } from "react-icons/bs";
import { AiOutlineHeart, AiOutlineStar } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { removeFromWishlist } from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { setWishlistOpen } from "../../state/uiSlice";

const Wishlist = () => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const isWishlistOpen = useSelector((state) => state.ui.isWishlistOpen);
  const dispatch = useDispatch();

  const removeFromWishlistHandler = (data) => {
    dispatch(removeFromWishlist(data));
  };

  useEffect(() => {
    console.log('Wishlist open state:', isWishlistOpen);
  }, [isWishlistOpen]);

  const addToCartHandler = (data) => {
    const newData = { ...data, qty: 1 };
    dispatch(addTocart(newData));
    dispatch(setWishlistOpen(false));
  };

  return (
    <div className="wishlist-component-overlay">
      <div className="wishlist-component-container">
        {wishlist && wishlist.length === 0 ? (
          <div className="wishlist-component-empty">
            <div className="wishlist-component-close-button">
            <RxCross1
              size={25}
              className="wishlist-component-icon"
              onClick={(e) => {
                console.log("Close button clicked");
                e.stopPropagation();
                dispatch(setWishlistOpen(false));
              }}
            />

            </div>
            <h5>Wishlist is empty.</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="wishlist-component-close-button">
              <RxCross1
                size={25}
                className="wishlist-component-icon"
                onClick={(e) => {
                  console.log("Close button clicked");
                  e.stopPropagation();
                  dispatch(setWishlistOpen(false));
                }}
              />

              </div>
              <div className="wishlist-component-header">
                <AiOutlineStar size={25} />
                <h5 className="wishlist-component-header-title">
                  {wishlist && wishlist.length} items
                </h5>
              </div>

              <div className="wishlist-component-items-container">
                {wishlist &&
                  wishlist.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      removeFromWishlistHandler={removeFromWishlistHandler}
                      addToCartHandler={addToCartHandler}
                    />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({ data, removeFromWishlistHandler, addToCartHandler }) => {
  const [value, setValue] = useState(1);
  const totalPrice = data.discountPrice * value;

  return (
    <div className="wishlist-component-item">
      <div className="wishlist-component-item-content">
        <RxCross1
          className="wishlist-component-item-remove"
          onClick={() => removeFromWishlistHandler(data)}
        />
        <img
          src={`${data?.images[0]?.url}`}
          alt=""
          className="wishlist-component-item-image"
        />

        <div className="wishlist-component-item-details">
          <h1>{data.name}</h1>
          <h4 className="wishlist-component-item-price">
            US${totalPrice}
          </h4>
        </div>
        <div className="wishlist-component-item-add-to-cart-button">
          <BsCartPlus
            size={20}
            className="wishlist-component-item-add-to-cart"
            title="Add to cart"
            onClick={() => addToCartHandler(data)}
          />
        </div>
      </div>
    </div>
  );
};

export default Wishlist;

