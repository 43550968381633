import { categoriesData } from "../../static/data";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineSearch,
  AiOutlineClose,
  AiOutlineStar
} from "react-icons/ai";
import {
 MagnifyingGlassIcon
} from '@heroicons/react/24/outline';
import { IoIosArrowDown } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import Cart from "../cart/Cart";
import Wishlist from "../Wishlist/Wishlist";
import { RxCross1 } from "react-icons/rx";
import AvatarDropdown from "./AvatarDropdown";
import { useSelector, useDispatch } from "react-redux";
import { toggleCart, toggleWishlist, toggleAvatarDropdown, toggleTopLevelDropdown } from "../../state/uiSlice";
import TopLevelDropdown from "./TopLevelDropdown";
import SearchBar from "./SearchBar";
import ProfileSwitchButton from "../ProfileSwitchButton";
import MobileSearchBar from "./MobileSearchBar";
import SearchIcon from "./SearchIcon";

const Header = () => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { wishlist } = useSelector((state) => state.wishlist);
  const profileType = sessionStorage.getItem('currentProfile') || 'Guest'; 
  const cartState = useSelector(state => state.cart.carts); 
  const cart = cartState[profileType] || [];
  const [active, setActive] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const dropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const isCartOpen = useSelector((state) => state.ui.isCartOpen);
  const isWishlistOpen = useSelector((state) => state.ui.isWishlistOpen);
  const isAvatarDropdownOpen = useSelector((state) => state.ui.isAvatarDropdownOpen);
  const isTopLevelDropdownOpen = useSelector((state) => state.ui.isTopLevelDropdownOpen);
  const currentProfile = useSelector((state) => state.user.currentProfile);
  const siteLogoUrl = useSelector((state) => state.theme.theme.siteLogoUrl);
  const fallbackLogo = "https://res.cloudinary.com/dzlopmfj8/image/upload/v1706549678/Asset-1_yjnmot.png";
  const logoToUse = siteLogoUrl || fallbackLogo;
  
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize(); 
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleOpenWishlist = () => {
    dispatch(toggleWishlist());
  };

  const handleOpenCart = () => {
    dispatch(toggleCart()); 
  };

  const handleToggleDropdown = () => {
    dispatch(toggleTopLevelDropdown());
  };

  const handleToggleAvatarDropdown = () => {
    dispatch(toggleAvatarDropdown());
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  const totalItems = cart.reduce((acc, item) => acc + item.qty, 0);

  return (
    <>
      <div className="main-header-component-container">
        <div className="main-header-component-wrapper">
        <div>
            <Link to="/">
              <img
                src={logoToUse}
                className="main-header-component-site-logo"
                alt="Site Logo"
              />
            </Link>
          </div>
          
          <div onClick={handleToggleDropdown} ref={dropdownRef}>
            <div className="main-header-component-categories">
              <img 
                src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724854900/memes/evajdtncfvfdnrihmx6t.png" 
                alt="Menu Icon" 
                className="header-icon" 
              />
              <button className="main-header-component-categories-button">
                Discover
              </button>
              <IoIosArrowDown
                size={20}
                className="main-header-component-arrow-icon"
                onClick={() => setDropDown(!dropDown)}
              />
              {isTopLevelDropdownOpen && (
                <TopLevelDropdown
                  categoriesData={categoriesData}
                  setDropDown={setDropDown}
                />
              )}
            </div>
          </div>

          {/* Desktop SearchBar */}
          {!isMobile && <SearchBar />}

          <div className="main-header-component-actions">
            <div className="main-header-component-action">
              <div
                className="main-header-component-wishlist"
                onClick={handleOpenWishlist}
              >
                <AiOutlineStar size={30} className="main-header-component-wishlist-icon" />
                <span className="main-header-component-wishlist-count">
                  {wishlist && wishlist.length}
                </span>
              </div>
            </div>

            <div className="main-header-component-action">
              <div
                className="main-header-component-cart"
                onClick={handleOpenCart}
              >
                <AiOutlineShoppingCart size={30} className="main-header-component-wishlist-icon" />
                <span className="main-header-component-cart-count">
                  {totalItems}
                </span>
              </div>
            </div>

            <div className="main-header-component-action">
              <div className="main-header-component-avatar" onClick={handleToggleAvatarDropdown}>
                {isAuthenticated ? (
                  <>
                    <img
                      src={user.avatar?.url || 'default-avatar-url'}
                      alt={`${currentProfile} Avatar`}
                      className="main-header-component-avatar-img"
                    />
                    {isAvatarDropdownOpen && <AvatarDropdown />}
                  </>
                ) : (
                  <Link to="/login">
                    <CgProfile size={30} color="rgb(255 255 255 / 83%)" />
                  </Link>
                )}
              </div>
            </div>

            {isCartOpen && <Cart />}
            {isWishlistOpen && <Wishlist />}
          </div>
        </div>
      </div>

      <div
        className={`main-header-component-mobile-header ${active ? "active" : ""}`}
      >
        <div className="main-header-component-mobile-header-inner">
          <div className="main-header-component-mobile-discover">
            <div className="mobile-header-icon-container">
              <img
                src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724854900/memes/evajdtncfvfdnrihmx6t.png"
                alt="Discover Icon"
                className="mobile-header-icon"
                onClick={() => setOpen(true)}
              />
            </div>

            {/* Mobile Search Icon placed here */}
            {isMobile && (
              <div className="mobile-header-search-icon" onClick={() => setIsSearchModalOpen(true)}>
                <SearchIcon />
              </div>
            )}
          </div>

          <div className="main-header-component-mobile-logo-container">
            <Link to="/">
              <img
                src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724797193/BuzzVibe-TM-Gradient-outline_jfxvg6.png"
                alt="Logo"
                className="main-header-component-mobile-logo"
              />
            </Link>
          </div>
          
          <div className="main-header-component-mobile-actions">
            <div
              className="main-header-component-mobile-cart"
              onClick={handleOpenCart}
            >
              <AiOutlineShoppingCart size={30} />
              <span className="main-header-component-mobile-cart-count">
                {totalItems}
              </span>
            </div>
            <ProfileSwitchButton className="sidebar-profile-toolbar-avatar" />
          </div>
  
          {isCartOpen && <Cart />}
          {isWishlistOpen && <Wishlist />}
        </div>

        {open && (
          <div className="main-header-component-sidebar">
            <div className="main-header-component-sidebar-inner">
              <div className="main-header-component-sidebar-top">
              <img 
                src="https://res.cloudinary.com/dzlopmfj8/image/upload/v1724854900/memes/evajdtncfvfdnrihmx6t.png" 
                alt="Menu Icon" 
                className="mobile-header-icon" 
              />
                <h2 className="main-header-component-sidebar-title">Discover</h2>
                <div
                  className="main-header-component-wishlist-icon"
                  onClick={() => {
                    dispatch(toggleWishlist());
                    setOpen(false); 
                  }}
                >
                  <AiOutlineStar size={30} />
                  <span className="main-header-component-wishlist-count">
                    {wishlist && wishlist.length}
                  </span>
                </div>
                <RxCross1
                  size={30}
                  className="main-header-component-close-icon"
                  onClick={() => setOpen(false)}
                />
              </div>

              <div className="main-header-component-search-box">
                <SearchBar />
              </div>

              <TopLevelDropdown
                categoriesData={categoriesData}
                setDropDown={setDropDown}
              />
            </div>
          </div>
        )}
      </div>

      {isMobile && isSearchModalOpen && (
        <div className="mobile-search-modal">
          <div className="mobile-search-modal-content">
            <AiOutlineClose 
              size={24} 
              className="close-mobile-search-modal" 
              onClick={() => setIsSearchModalOpen(false)} 
            />
            <MobileSearchBar onClose={() => setIsSearchModalOpen(false)} />
          </div>
        </div>
      )}
    </>
  );
};

export default Header;

