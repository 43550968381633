import React, { useState } from "react";
import SavePaymentMethod from "../../components/Payment/SavePaymentMethod";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader";
import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar";

const ShopChangePaymentMethodPage = () => {
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const handlePaymentMethodRetrieved = (status) => {
    setHasPaymentMethod(status);
  };

  return (
    <div> 
        <DashboardHeader />
        <DashboardSideBar active={15} />

    <div className="payment-method-page">
      <SavePaymentMethod onPaymentMethodRetrieved={handlePaymentMethodRetrieved} />
    </div>
    </div>
  );
};

export default ShopChangePaymentMethodPage;