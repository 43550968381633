import React from "react";

const ExploreIcon = (props) => (
    <div className="explore-icon" >
    <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1080 1080"
    {...props}
  >
    <defs>
      <style>{"\n      .cls-1 {\n        ;\n      }\n    "}</style>
    </defs>
    <path
      className="cls-1"
      d="M449.66,15H67.65c-29.08,0-52.65,23.57-52.65,52.65v382.01c0,29.08,23.57,52.65,52.65,52.65h382.01c29.08,0,52.65-23.58,52.65-52.65V67.65c0-29.08-23.57-52.65-52.65-52.65ZM428.99,392.18c0,20.33-16.48,36.81-36.81,36.81H125.12c-20.33,0-36.81-16.48-36.81-36.81V125.12c0-20.33,16.48-36.8,36.81-36.8h267.06c20.33,0,36.81,16.48,36.81,36.8v267.06ZM1012.35,15h-382.01c-29.08,0-52.65,23.57-52.65,52.65v382.01c0,29.08,23.57,52.65,52.65,52.65h382.01c29.08,0,52.65-23.58,52.65-52.65V67.65c0-29.08-23.57-52.65-52.65-52.65ZM991.68,392.18c0,20.33-16.48,36.81-36.81,36.81h-267.06c-20.33,0-36.81-16.48-36.81-36.81V125.12c0-20.33,16.48-36.8,36.81-36.8h267.06c20.33,0,36.81,16.48,36.81,36.8v267.06ZM449.66,577.69H67.65c-29.08,0-52.65,23.57-52.65,52.65v382.01c0,29.08,23.57,52.65,52.65,52.65h382.01c29.08,0,52.65-23.58,52.65-52.65v-382.01c0-29.08-23.57-52.65-52.65-52.65ZM428.99,954.87c0,20.33-16.48,36.81-36.81,36.81H125.12c-20.33,0-36.81-16.48-36.81-36.81v-267.06c0-20.33,16.48-36.8,36.81-36.8h267.06c20.33,0,36.81,16.48,36.81,36.8v267.06ZM1012.35,577.69h-382.01c-29.08,0-52.65,23.57-52.65,52.65v382.01c0,29.08,23.57,52.65,52.65,52.65h382.01c29.08,0,52.65-23.58,52.65-52.65v-382.01c0-29.08-23.57-52.65-52.65-52.65ZM991.68,954.87c0,20.33-16.48,36.81-36.81,36.81h-267.06c-20.33,0-36.81-16.48-36.81-36.81v-267.06c0-20.33,16.48-36.8,36.81-36.8h267.06c20.33,0,36.81,16.48,36.81,36.8v267.06Z"
    />
  </svg>
  </div>
);
export default ExploreIcon;

