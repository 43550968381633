import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
  AiOutlineStar,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { ecommerceServer } from "../../server";
import styles from "../../styles/styles";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "./Ratings";
import axios from "axios";
import { TruckIcon, BuildingStorefrontIcon } from '@heroicons/react/24/outline';


const ProductDetails = ({ data }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [selectedVariant, setSelectedVariant] = useState(data?.variants ? data.variants[0] : null);
  const [selectedColor, setSelectedColor] = useState(data?.variants ? data.variants[0]?.color : null);
  const [selectedSize, setSelectedSize] = useState(data?.variants ? data.variants[0]?.size : null);
  const [selectedCustomValue, setSelectedCustomValue] = useState(data?.variants ? data.variants[0]?.customValue : null);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    console.log("Product data:", data);
    if (!data) return; // Ensure data exists

    // Fetch products for the shop when component mounts
    dispatch(getAllProductsShop(data?.shop?._id));

    // Handle wishlist status
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }

    // Set the default selected variant or the general product
    if (data?.variants?.length > 0) {
      const defaultVariant = data.variants[0];
      setSelectedVariant(defaultVariant);
      setSelectedColor(defaultVariant.color);  
      setSelectedSize(defaultVariant.size);
      setSelectedCustomValue(defaultVariant.customValue);
    } else {
      // If no variants exist, treat the general product as the selected "variant"
      setSelectedVariant(data);
    }

  }, [data, wishlist, dispatch]);
  

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = () => {
    // Check if the product has variants
    if (data.variants?.length > 0 && !selectedVariant) {
      // If it's a variable product but no variant is selected
      toast.error("Please select a variant before adding to cart!");
      return;
    }
  
    // Identify if the item is already in the cart
    const isItemExists = cart && cart.find((item) => 
      item._id === data._id && (!item.variant || (item.variant?._id === selectedVariant?._id))
    );
  
    if (isItemExists) {
      toast.error("This product is already in the cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product is out of stock!");
      } else {
        // Prepare the data to be sent to the cart
        const cartData = {
          _id: data._id,
          name: data.name,
          brand: data.brand,
          SKU: selectedVariant?.sku || data.SKU, // Use variant SKU if available
          category: data.category,
          brandTypeSelection: data.brandTypeSelection,
          description: selectedVariant?.description || data.description,
          price: currentPrice,
          stock: selectedVariant?.stock || data.stock, // Use variant stock if available
          weight: selectedVariant?.weight || data.weight, // Use variant weight if available
          weightUnitOfMeasurement: selectedVariant?.weightUnitOfMeasurement || data.weightUnitOfMeasurement, // Use variant weight unit if available
          dimensions: selectedVariant?.dimensions || data.dimensions, // Use variant dimensions if available
          dimensionUnitOfMeasurement: selectedVariant?.dimensionUnitOfMeasurement || data.dimensionUnitOfMeasurement, // Use variant dimension unit if available
          image: selectedVariant?.image?.url || data.images[0]?.url, 
          qty: count,
          variant: selectedVariant || null, 
          availableForDelivery: data.availableForDelivery,
          availableForPickup: data.availableForPickup,
          barcodeString: data.barcodeString,
          isEdible: data.isEdible,
          isOnSale: data.isOnSale,
          taxCode: data.taxCode,
          shopId: data.shopId,
          shop: data.shop,
        };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };
  

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg =  totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);


  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = data._id + user._id;
      const userId = user._id;
      const sellerId = data.shop._id;
      await axios
        .post(`${ecommerceServer}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        })
        .then((res) => {
          navigate(`/inbox?${res.data.conversation._id}`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("Please login to create a conversation");
    }
  };

  const handleVariantChange = (variant) => {
    console.log("Variant selected:", variant);
    setSelectedVariant(variant);
    setSelect(0); // Reset image selection for new variant
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    const matchingVariant = data.variants.find(
      (variant) => variant.color === color && variant.size === selectedSize
    );
    if (matchingVariant) {
      setSelectedVariant(matchingVariant);
    }
  };
  
  const handleSizeChange = (size) => {
    setSelectedSize(size);
    const matchingVariant = data.variants.find(
      (variant) => variant.size === size && variant.color === selectedColor
    );
    if (matchingVariant) {
      setSelectedVariant(matchingVariant);
    }
  };

  const handleCustomValueChange = (customValue) => {
    setSelectedCustomValue(customValue);
    const matchingVariant = data.variants.find(
      (variant) =>
        variant.customValue === customValue &&
        variant.color === selectedColor &&
        variant.size === selectedSize
    );
    if (matchingVariant) {
      setSelectedVariant(matchingVariant);
    }
  };
  

  const currentImages = selectedVariant?.image ? [selectedVariant.image] : data?.images || [];
  const currentVideos = selectedVariant?.videos?.length ? selectedVariant.videos : data?.videos || [];
  const currentPrice = selectedVariant?.price ?? data?.discountPrice ?? "N/A";
  const currentOriginalPrice = selectedVariant?.originalPrice ?? data?.originalPrice ?? null;  
  const currentDescription = selectedVariant?.description ?? data?.description ?? "No description available.";

  console.log("Selected Variant:", selectedVariant);
  console.log("Current Images:", currentImages);
  console.log("Current Price:", currentPrice);


  return (
    <div className="product-details-component__content-bg">
      {data ? (
        <div className={`${styles.section} product-details-component__section`}>
          <div className="product-details-component__py-5">
            <div className="product-details-component__flex-container">
              <div className="product-details-component__flex-left">
              {select < currentImages?.length ? (
                  <img
                    src={`${currentImages[select]?.url}`}
                    alt=""
                    className="product-details-component__main-image"
                  />
                ) : (
                  <video
                    src={`${currentVideos[select - currentImages.length]?.url}`}
                    className="product-details-component__main-image"
                    controls
                  />
                )}
                <div className="product-details-component__image-selection">
                  {currentImages?.map((image, index) => (
                    <img
                      key={index}
                      src={image.url}
                      alt={`Image ${index + 1}`}
                      className={`product-details-component__thumbnail ${select === index ? "product-details-component__thumbnail--selected" : ""}`}
                      onClick={() => setSelect(index)}
                    />
                  ))}
                  {currentVideos?.map((video, index) => (
                    <video
                      key={index}
                      src={video.url}
                      className={`product-details-component__thumbnail ${select === currentImages?.length + index ? "product-details-component__thumbnail--selected" : ""}`}
                      onClick={() => setSelect(currentImages.length + index)}
                    />
                  ))}
                </div>
              </div>
              <div className="product-details-component__flex-right">
              <div className="product-details-component__top-bar">
                {data.brand && (
                  <div className="product-details-component__brand-container">
                    <span className="product-details-component__brand-label">
                      Brand:
                    </span>
                    <span className="product-details-component__brand-value">{data.brand}</span>
                    {data.brandTypeSelection === 'branded' && (
                      <img
                        src='https://res.cloudinary.com/dzlopmfj8/image/upload/v1710252613/feat_morfbr.png'
                        alt=""
                        className="product-details-component__brand-image"
                      />
                    )}
                  </div>
                )}
                 <div className="product-details-component__availability-container">
                 {data.availableForDelivery && (
                      <TruckIcon
                        className="product-details-component__icon product-details-component__icon--delivery"
                        title="Available for Delivery"
                      />
                    )}
                    {data.availableForPickup && (
                      <BuildingStorefrontIcon
                        className="product-details-component__icon product-details-component__icon--pickup"
                        title="Available for Pickup"
                      />
                    )}
                </div>
                </div>
                <h1 className="product-details-component__product-title">{data.name}</h1>
                <p className="product-details-component__product-description">{currentDescription}</p>
                <div className="product-details-component__price-container">
                <h3 className="product-details-component__discount-price">
                  ${currentPrice}
                  </h3>
                  {data.isOnSale && (
                  <h3 className="product-details-component__original-price">
                    {currentOriginalPrice ? "$" + currentOriginalPrice : null}
                    </h3>
                  )}
                </div>
                
  
                {/* Color Swatches */}
                {data?.variants?.some(variant => variant.color) && (
                  <div className="color-swatches-container">
                    <h4>Color</h4>
                    <div className="color-swatches">
                    {[
                      ...new Map(
                        data.variants
                          .filter(variant => variant.color)
                          .map((variant) => [variant.color, variant]) // Use Map to filter out duplicates
                      ).values(),
                    ].map((variant, index) => (
                      <div
                        key={index}
                        className={`swatch ${selectedColor === variant.color ? "active" : ""}`}
                        style={{ backgroundColor: variant.colorSwatch || '#000'}} 
                        onClick={() => handleColorChange(variant.color)}
                      />
                    ))}
                    </div>
                  </div>
                )}


                {/* Size Selection */}
                {data?.variants?.some(variant => variant.size) && (
                  <div className="size-selection-container">
                    <h4>Size</h4>
                    <div className="size-selection">
                    {[
                      ...new Map(
                        data.variants
                          .filter(variant => variant.size)
                          .map((variant) => [variant.size, variant]) 
                      ).values(),
                    ].map((variant, index) => (
                      <button
                        key={index}
                        className={`size-btn ${selectedSize === variant.size ? "active" : ""}`}
                        onClick={() => handleSizeChange(variant.size)}
                      >
                        {variant.size}
                      </button>
                    ))}
                     </div>
                  </div>
                )}

                {/* Custom Value Selection */}
                {data?.variants?.some(variant => variant.customValue) && (
                  <div className="custom-value-selection-container">
                    <h4>{data.variants[0]?.customTitle}</h4>
                    <div className="custom-value-selection">
                      {[
                        ...new Map(
                          data.variants
                            .filter(variant => variant.customValue)
                            .map((variant) => [variant.customValue, variant])
                        ).values(),
                      ].map((variant, index) => (
                        <button
                          key={index}
                          className={`custom-value-btn ${selectedCustomValue === variant.customValue ? "active" : ""}`}
                          onClick={() => handleCustomValueChange(variant.customValue)}
                        >
                          {variant.customValue}
                        </button>
                      ))}
                    </div>
                  </div>
                )}




                <div className="product-details-component__quantity-wishlist-container">
                  <div className="product-details-component__quantity-container">
                    <button
                      className="product-details-component__quantity-button"
                      onClick={decrementCount}
                    >
                      -
                    </button>
                    <span className="product-details-component__quantity-value">
                      {count}
                    </span>
                    <button
                      className="product-details-component__quantity-button"
                      onClick={incrementCount}
                    >
                      +
                    </button>
                  </div>
                  <div>
                    {click ? (
                      <AiFillStar
                        size={30}
                        className="favorite-icon-active"
                        onClick={() => removeFromWishlistHandler(data)}
                        title="Remove from wishlist"
                      />
                    ) : (
                      <AiOutlineStar
                        size={30}
                        className="product-details-component__wishlist-icon"
                        onClick={() => addToWishlistHandler(data)}
                        color={click ? "red" : "#333"}
                        title="Add to Wishlist"
                      />
                    )}
                  </div>
                </div>
                <div
                  className="product-details-component__add-to-cart-button"
                  onClick={() => addToCartHandler(data._id)}
                >
                  <span className="product-details-component__add-to-cart-text">
                    Add to Cart <AiOutlineShoppingCart className="product-details-component__cart-icon" />
                  </span>
                </div>
               
                <div className="product-details-component__shop-info">
                  <Link to={`/shop/${data?.shop.handle}`}>
                    <img
                      src={`${data?.shop?.avatar?.url}`}
                      alt=""
                      className="product-details-component__shop-avatar"
                    />
                  </Link>
                  <div className="product-details-component__shop-details">
                    <Link to={`/shop/${data?.shop.handle}`}>
                      <h3 className="product-details-component__shop-name">
                        {data.shop.name}
                      </h3>
                    </Link>
                    <h5 className="product-details-component__shop-ratings">
                      ({averageRating}/5) Ratings
                    </h5>
                  </div>
                  <div
                    className="product-details-component__send-message-button"
                    onClick={handleMessageSubmit}
                  >
                    <span className="product-details-component__send-message-text">
                      Message <AiOutlineMessage className="product-details-component__message-icon" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProductDetailsInfo
            data={data}
            products={products}
            totalReviewsLength={totalReviewsLength}
            averageRating={averageRating}
          />
          <br />
          <br />
        </div>
      ) : null}
    </div>
  );
};

const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
}) => {
  const [active, setActive] = useState(1);

  return (
    <div className="product-details-component__info-container">
      <div className="product-details-component__info-tabs">
        <div className="product-details-component__info-tab" onClick={() => setActive(1)}>
          <h4 className="product-details-component__info-tab-title">
            Product Details
          </h4>
          {active === 1 && <div className="product-details-component__active-indicator" />}
        </div>
        <div className="product-details-component__info-tab" onClick={() => setActive(2)}>
          <h4 className="product-details-component__info-tab-title">
            Product Reviews
          </h4>
          {active === 2 && <div className="product-details-component__active-indicator" />}
        </div>
        <div className="product-details-component__info-tab" onClick={() => setActive(3)}>
          <h4 className="product-details-component__info-tab-title">
            Seller Information
          </h4>
          {active === 3 && <div className="product-details-component__active-indicator" />}
        </div>
      </div>
      {active === 1 && (
        <>
          <p className="product-details-component__product-description-text">
            {data.description}
          </p>
          <div className="product-details-component__metrics-container">

            {data.testResults && data.testResults.length > 0 && (
              <div className="product-details-component__test-results">
                <h3 className="product-details-component__metrics-title">Test Results:</h3>
                <img 
                  src={data.testResults[0].url} 
                  alt="Test Results" 
                  className="product-details-component__test-results-image"
                />
              </div>
            )}
          </div>

          <div className="product-details-component__barcode-container">
            {data.barcode && data.barcode.length > 0 && (
              <div className="product-details-component__barcode">
                <h3 className="product-details-component__metrics-title">Product Barcode:</h3>
                <img 
                  src={data.barcode[0].url} 
                  alt="Product Barcode" 
                  className="product-details-component__barcode-image"
                />
              </div>
            )}
            {data.barcodeString && (
              <div className="product-details-component__barcode-string">
                <h3 className="product-details-component__metrics-title">Barcode Number:</h3>
                <p className="product-details-component__barcode-value">{data.barcodeString}</p>
              </div>
            )}
          </div>

          {data.nutritionInfoImage && data.nutritionInfoImage.length > 0 && (
            <div className="product-details-component__nutrition-info">
              <h3 className="product-details-component__metrics-title">Nutrition Information:</h3>
              <img 
                src={data.nutritionInfoImage[0].url} 
                alt="Nutrition Info" 
                className="product-details-component__nutrition-info-image"
              />
            </div>
          )}
        </>
      )}

      {active === 2 && (
        <div className="product-details-component__reviews-container">
          {data &&
            data.reviews.map((item, index) => (
              <div className="product-details-component__review" key={index}>
                <img
                  src={`${item.user.avatar?.url}`}
                  alt=""
                  className="product-details-component__review-avatar"
                />
                <div className="product-details-component__review-content">
                  <div className="product-details-component__review-header">
                    <h1 className="product-details-component__reviewer-name">{item.user.name}</h1>
                    <Ratings rating={data?.ratings} />
                  </div>
                  <p className="product-details-component__review-comment">{item.comment}</p>
                </div>
              </div>
            ))}

          {data && data.reviews.length === 0 && (
            <h5 className="product-details-component__no-reviews">No Reviews for this product.</h5>
          )}
        </div>
      )}

      {active === 3 && (
        <div className="product-details-component__seller-info">
          <div className="product-details-component__seller-section">
            <Link to={`/shop/${data.shop.handle}`} className="product-details-component__shop-link">
              <div className="product-details-component__shop-container">
                <img
                  src={`${data?.shop?.avatar?.url}`}
                  className="product-details-component__shop-avatar-large"
                  alt={`${data.shop.name} avatar`}
                />
                <div className="product-details-component__shop-details-large">
                  <h3 className="product-details-component__shop-name-large">
                    {data.shop.name}
                  </h3>
                  <p className="product-details-component__shop-rating">
                    ({averageRating}/5) Ratings
                  </p>
                </div>
              </div>
            </Link>
            <p className="product-details-component__shop-description">{data.shop.description}</p>
          </div>

          <div className="product-details-component__shop-metrics">
            <div className="product-details-component__shop-metric">
              <h4 className="product-details-component__shop-metric-title">Joined on</h4>
              <p className="product-details-component__shop-metric-value">
                {data.shop?.createdAt?.slice(0, 10)}
              </p>
            </div>

            <div className="product-details-component__shop-metric">
              <h4 className="product-details-component__shop-metric-title">Total Products</h4>
              <p className="product-details-component__shop-metric-value">
                {products && products.length}
              </p>
            </div>

            <div className="product-details-component__shop-metric">
              <h4 className="product-details-component__shop-metric-title">Total Reviews</h4>
              <p className="product-details-component__shop-metric-value">
                {totalReviewsLength}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="product-details-component__visit-shop">
        <Link to={`/shop/${data?.shop.handle}`}>
          <div className="product-details-component__visit-shop-button">
            <h4 className="product-details-component__visit-shop-text">Visit Shop</h4>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductDetails;