import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { FaPencilAlt, FaSun, FaMoon } from "react-icons/fa";
import { ChromePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import ImageCropperModal from "../Image/ImageCropperModal";
import { loadTheme, updateTheme, uploadThemeLogo } from "../../redux/actions/theme";

// --------------------------------------------------------------
// MAP OF DATABASE KEYS --> FRIENDLY LABELS
// (All keys from your theme schema, no placeholders)
// --------------------------------------------------------------
const KEY_LABEL_MAP = {
  // Base fields
  siteLogoUrl: "Site Logo URL",

  // Primary
  primaryColor: "Primary Color (Base)",
  primaryLight: "Primary Color (Light)",
  primaryDark: "Primary Color (Dark)",

  //Primary Hover
  primaryColorHover: "Primary Color Hover (Base)",
  primaryLightHover: "Primary Color Hover (Light)",
  primaryDarkHover: "Primary Color Hover (Dark)",

  // Secondary
  secondaryColor: "Secondary Color (Base)",
  secondaryLight: "Secondary Color (Light)",
  secondaryDark: "Secondary Color (Dark)",

  // Background
  backgroundColor: "Background Color (Base)",
  backgroundLight: "Background Color (Light)",
  backgroundDark: "Background Color (Dark)",

  // Header Background
  headerBackground: "Header Background (Base)",
  headerBackgroundLight: "Header Background (Light)",
  headerBackgroundDark: "Header Background (Dark)",

  // Sidebar Background
  sidebarBackground: "Sidebar Background (Base)",
  sidebarBackgroundLight: "Sidebar Background (Light)",
  sidebarBackgroundDark: "Sidebar Background (Dark)",

  // Sidebar Icons
  sidebarIcons: "Sidebar Icons (Base)",
  sidebarIconsLight: "Sidebar Icons (Light)",
  sidebarIconsDark: "Sidebar Icons (Dark)",

  // Icon Colors
  iconColor: "Icon Color (Base)",
  iconColorLight: "Icon Color (Light)",
  iconColorDark: "Icon Color (Dark)",

  // Toggle Colors
  toggleColor: "Toggle Color (Base)",
  toggleColorLight: "Toggle Color (Light)",
  toggleColorDark: "Toggle Color (Dark)",

  // Discover Dropdown Background
  discoverBackground: "Discover Background (Base)",
  discoverBackgroundLight: "Discover Background (Light)",
  discoverBackgroundDark: "Discover Background (Dark)",

  // Text
  textColor: "Text Color (Base)",
  textLight: "Text Color (Light)",
  textDark: "Text Color (Dark)",

  // Borders
  borderColor: "Border Color (Base)",
  borderLight: "Border Color (Light)",
  borderDark: "Border Color (Dark)",

  // Strong Border
  strongBorderColor: "Strong Border (Base)",
  strongBorderLight: "Strong Border (Light)",
  strongBorderDark: "Strong Border (Dark)",

  // Border Styles
  borderDashed: "Dashed Border Style",
  borderDotted: "Dotted Border Style",

  // Border Radius
  borderRadiusSm: "Border Radius (Small)",
  borderRadiusMd: "Border Radius (Medium)",
  borderRadiusLg: "Border Radius (Large)",
  borderRadiusFull: "Border Radius (Full)",

  // Button
  buttonColor: "Button Color (Base)",
  buttonHoverColor: "Button Color (Hover)",
  buttonTextColor: "Button Text Color",

  // Alert
  successColor: "Success Color",
  warningColor: "Warning Color",
  dangerColor: "Danger Color",
  infoColor: "Info Color",

  // Shadows
  boxShadow: "Box Shadow (Base)",
  boxShadowHover: "Box Shadow (Hover)",

  // Spacing
  spacingXxs: "Spacing XXS",
  spacingXs: "Spacing XS",
  spacingSm: "Spacing SM",
  spacingMd: "Spacing MD",
  spacingLg: "Spacing LG",
  spacingXl: "Spacing XL",
  spacingXxl: "Spacing XXL",

  // Font Sizes
  fontSizeXxs: "Font Size XXS",
  fontSizeXs: "Font Size XS",
  fontSizeSm: "Font Size SM",
  fontSizeMd: "Font Size MD",
  fontSizeLg: "Font Size LG",
  fontSizeXl: "Font Size XL",
  fontSizeXxl: "Font Size XXL",

  // Modal Overlay
  modalOverlayBackground: "Modal Overlay (Background)",
  modalOverlayBackdropFilter: "Modal Overlay (Backdrop Filter)",
  modalOverlayZIndex: "Modal Overlay (Z-Index)",

  // Transitions
  transitionDefault: "Transition (Default)",
  transitionFast: "Transition (Fast)",
  transitionSlow: "Transition (Slow)",
  transitionFadeIn: "Transition (Fade In)",
  transitionFadeOut: "Transition (Fade Out)",

  // Animations
  animationFadeIn: "Animation (Fade In)",
  animationFadeOut: "Animation (Fade Out)",
  animationScaleUp: "Animation (Scale Up)",
  animationScaleDown: "Animation (Scale Down)"
};

// 1) Utility: Is this string recognized as a valid CSS color?
function isValidCssColor(str) {
  const s = new Option().style;
  s.color = str;
  return s.color !== "";
}

// 2) Utility: Only check KEY name for "color"/"background"/"light"/"dark" (excl. isDarkMode)
function isColorOrBackgroundKey(key) {
  const lowerKey = key.toLowerCase();
  if (lowerKey === "isdarkmode") {
    return false;
  }
  
  return (
    lowerKey.includes("color") ||
    lowerKey.includes("background") ||
    lowerKey.includes("light") ||
    lowerKey.includes("dark")
  );
}

// 3) Utility: Group fields into Light/Dark/General by KEY
function groupByThemeType(colorEntries) {
  const grouped = { Light: [], Dark: [], General: [] };

  colorEntries.forEach(([key, value]) => {
    const lowerKey = key.toLowerCase();
    if (lowerKey.includes("light")) {
      grouped.Light.push([key, value]);
    } else if (lowerKey.includes("dark")) {
      grouped.Dark.push([key, value]);
    } else {
      grouped.General.push([key, value]);
    }
  });

  grouped.Light.sort((a, b) => a[0].localeCompare(b[0]));
  grouped.Dark.sort((a, b) => a[0].localeCompare(b[0]));
  grouped.General.sort((a, b) => a[0].localeCompare(b[0]));
  
  return grouped;
}

// 4) Convert camelCase => kebab-case
function toKebabCase(str) {
  return str.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
}

const AdminThemeManagement = () => {
  const dispatch = useDispatch();

  // --- Redux Data ---
  const {
    theme: reduxTheme,
    loading,
    error,
    successMessage,
    logoUploadLoading,
  } = useSelector((state) => state.theme);

  // --- Local State ---
  const [theme, setTheme] = useState({});
  const [isDarkMode, setIsDarkMode] = useState(false);

  const [saving, setSaving] = useState(false);
  const [localError, setLocalError] = useState(error);
  const [localSuccessMessage, setLocalSuccessMessage] = useState(successMessage);

  // Color Picker Modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalColorKey, setModalColorKey] = useState(null);
  const [modalColorValue, setModalColorValue] = useState("#ffffff");

  // Logo Cropper
  const [siteLogoFile, setSiteLogoFile] = useState(null);
  const [siteLogoPreview, setSiteLogoPreview] = useState(null);
  const [showLogoCropper, setShowLogoCropper] = useState(false);

  // 1) Load Theme from Redux on mount
  useEffect(() => {
    dispatch(loadTheme());
  }, [dispatch]);

  // 2) Sync redux theme -> local state
  useEffect(() => {
    if (reduxTheme) {
      setTheme(reduxTheme);
      setIsDarkMode(!!reduxTheme.isDarkMode);
    }
  }, [reduxTheme]);

  // 3) Apply CSS vars & body classes
  useEffect(() => {
    if (!theme) return;

    Object.entries(theme).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${toKebabCase(key)}`, value);
    });

    document.body.classList.toggle("dark-mode", isDarkMode);
    document.body.classList.toggle("light-mode", !isDarkMode);

    // Optionally set background color for dark/light mode
    if (theme.backgroundDark && theme.backgroundLight) {
      const bg = isDarkMode ? theme.backgroundDark : theme.backgroundLight;
      document.documentElement.style.setProperty("--background-color", bg);
    }
  }, [theme, isDarkMode]);

  // Sync local error & success messages with Redux
  useEffect(() => {
    setLocalError(error);
    setLocalSuccessMessage(successMessage);
  }, [error, successMessage]);

  // Toggle Dark/Light Mode
  const handleThemeToggle = () => {
    setIsDarkMode((prev) => !prev);
  };

  // Handle input changes
  const handleTextChange = (e, key) => {
    setTheme((prev) => ({ ...prev, [key]: e.target.value }));
  };

  // Color Picker Logic
  const openColorModal = (key) => {
    setModalColorKey(key);
    setModalColorValue(theme[key] || "#ffffff");
    setModalOpen(true);
  };
  const handleColorPickerChange = (color) => {
    setModalColorValue(color.hex);
  };
  const handleModalConfirm = () => {
    if (modalColorKey) {
      setTheme((prev) => ({ ...prev, [modalColorKey]: modalColorValue }));
    }
    closeModal();
  };
  const closeModal = () => {
    setModalOpen(false);
    setModalColorKey(null);
  };

  // Logo Crop handling
  const handleLogoFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSiteLogoFile(file);
      setShowLogoCropper(true);
    }
  };
  const updateSiteLogo = (croppedFile) => {
    setSiteLogoFile(croppedFile);
    setSiteLogoPreview(URL.createObjectURL(croppedFile));
    setShowLogoCropper(false);
  };

  // Submit/Save
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setLocalError(null);

    try {
      // Filter color fields
      const colorEntries = Object.entries(theme).filter(([key]) =>
        isColorOrBackgroundKey(key)
      );
      const grouped = groupByThemeType(colorEntries);
      const allColorFields = [
        ...grouped.Light,
        ...grouped.Dark,
        ...grouped.General,
      ];

      // Validate color fields
      for (const [fieldKey, fieldVal] of allColorFields) {
        if (!fieldVal) continue;
        if (!isValidCssColor(fieldVal)) {
          setLocalError(`"${fieldKey}" is not a valid CSS color: "${fieldVal}"`);
          setSaving(false);
          return;
        }
      }

      // Upload new logo if changed
      let siteLogoUrl = theme.siteLogoUrl || "";
      if (siteLogoFile) {
        const logoUrl = await dispatch(uploadThemeLogo(siteLogoFile));
        siteLogoUrl = logoUrl;
      }

      // Final theme object
      const updatedTheme = {
        ...theme,
        siteLogoUrl,
        isDarkMode,
      };

      // Dispatch update
      await dispatch(updateTheme(updatedTheme));
      setTheme(updatedTheme);

      alert("Theme updated successfully!");
    } catch (err) {
      console.error("Error updating theme:", err);
      alert("Failed to update theme variables.");
    }

    setSaving(false);
  };

  // Dismiss error/success
  const handleCloseMessage = () => {
    setLocalError(null);
    setLocalSuccessMessage(null);
  };

  if (loading) {
    return <div className="admin-theme-loading">Loading theme variables...</div>;
  }

  // Gather color fields
  const colorEntries = Object.entries(theme).filter(([key]) =>
    isColorOrBackgroundKey(key)
  );
  const groupedColors = groupByThemeType(colorEntries);

  // Color Picker Modal
  const colorPickerPortal = modalOpen
    ? ReactDOM.createPortal(
        <div className="admin-theme-modal-overlay" onClick={closeModal}>
          <div className="admin-theme-modal" onClick={(e) => e.stopPropagation()}>
            <ChromePicker color={modalColorValue} onChange={handleColorPickerChange} />
            <div className="admin-theme-modal-buttons">
              <button
                type="button"
                className="admin-theme-modal-btn confirm"
                onClick={handleModalConfirm}
              >
                OK
              </button>
              <button
                type="button"
                className="admin-theme-modal-btn cancel"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>,
        document.body
      )
    : null;

  // Logo Cropper Modal
  const logoCropperModal = showLogoCropper && (
    <ImageCropperModal
      updateAvatar={updateSiteLogo}
      closeModal={() => setShowLogoCropper(false)}
      initialImage={siteLogoFile}
      aspectRatio={3 / 1}
      minWidth={200}
      isAvatar={false}
      isPost={false}
      isLogo={true}
    />
  );

  return (
    <>
      <div className="admin-theme-container">
        <h2 className="admin-theme-title">Admin Theme Manager</h2>

        {localError && (
          <div className="admin-theme-message admin-theme-message--error">
            <div className="admin-theme-message__icon">⚠️</div>
            <div className="admin-theme-message__text">{localError}</div>
            <button
              className="admin-theme-message__close"
              onClick={handleCloseMessage}
            >
              ✖
            </button>
          </div>
        )}

        {localSuccessMessage && (
          <div className="admin-theme-message admin-theme-message--success">
            <div className="admin-theme-message__icon">✅</div>
            <div className="admin-theme-message__text">{localSuccessMessage}</div>
            <button
              className="admin-theme-message__close"
              onClick={handleCloseMessage}
            >
              ✖
            </button>
          </div>
        )}

        <div className="theme-mode-toggle">
          <button
            className="theme-toggle-btn"
            type="button"
            onClick={handleThemeToggle}
          >
            <span className={!isDarkMode ? "active-mode" : "inactive-mode"}>
              <FaSun /> Light
            </span>
            <span className={isDarkMode ? "active-mode" : "inactive-mode"}>
              <FaMoon /> Dark
            </span>
          </button>
        </div>

        <form onSubmit={handleSubmit} className="admin-theme-form-groupings">
          <div className="admin-theme-section">
            <h3 className="admin-theme-section-title">Site Logo</h3>
            <div className="admin-theme-form single-column-layout">
              <div className="admin-logo-field">
                <label className="admin-theme-field-label" htmlFor="site-logo-input">
                  Upload Site Logo
                </label>
                <div className="admin-theme-field-input-wrapper">
                  {siteLogoPreview ? (
                    <img
                      src={siteLogoPreview}
                      alt="Site Logo Preview"
                      className="admin-logo-preview-img"
                    />
                  ) : theme.siteLogoUrl ? (
                    <img
                      src={theme.siteLogoUrl}
                      alt="Current Site Logo"
                      className="admin-logo-preview-img"
                    />
                  ) : (
                    <div className="admin-logo-placeholder">No Logo</div>
                  )}

                  <label htmlFor="site-logo-input" className="admin-theme-color-button">
                    <FaPencilAlt />
                    <input
                      type="file"
                      id="site-logo-input"
                      accept=".jpg,.jpeg,.png"
                      style={{ display: "none" }}
                      onChange={handleLogoFileChange}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>

          {groupedColors.Light.length > 0 && (
            <div className="admin-theme-section">
              <h3 className="admin-theme-section-title">Light Theme</h3>
              <div className="admin-theme-form two-column-grid">
                {groupedColors.Light.map(([key, value]) => (
                  <ColorField
                    key={key}
                    label={key}
                    value={value}
                    onTextChange={(e) => handleTextChange(e, key)}
                    onOpenPicker={() => openColorModal(key)}
                  />
                ))}
              </div>
            </div>
          )}

          {groupedColors.Dark.length > 0 && (
            <div className="admin-theme-section">
              <h3 className="admin-theme-section-title">Dark Theme</h3>
              <div className="admin-theme-form two-column-grid">
                {groupedColors.Dark.map(([key, value]) => (
                  <ColorField
                    key={key}
                    label={key}
                    value={value}
                    onTextChange={(e) => handleTextChange(e, key)}
                    onOpenPicker={() => openColorModal(key)}
                  />
                ))}
              </div>
            </div>
          )}

          {groupedColors.General.length > 0 && (
            <div className="admin-theme-section">
              <h3 className="admin-theme-section-title">General Colors</h3>
              <div className="admin-theme-form two-column-grid">
                {groupedColors.General.map(([key, value]) => (
                  <ColorField
                    key={key}
                    label={key}
                    value={value}
                    onTextChange={(e) => handleTextChange(e, key)}
                    onOpenPicker={() => openColorModal(key)}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="admin-theme-actions">
            <button
              type="submit"
              className="admin-theme-submit-btn"
              disabled={saving || logoUploadLoading}
            >
              {saving || logoUploadLoading ? "Saving..." : "Save Theme"}
            </button>
          </div>
        </form>
      </div>

      {colorPickerPortal}
      {logoCropperModal}
    </>
  );
};

export default AdminThemeManagement;

function ColorField({ label, value, onTextChange, onOpenPicker }) {
  // Convert the raw key to a more friendly label
  const friendlyLabel = KEY_LABEL_MAP[label] || label;

  return (
    <div className="admin-theme-field">
      <label className="admin-theme-field-label" htmlFor={label}>
        {friendlyLabel}
      </label>
      <div className="admin-theme-field-input-wrapper">
        <div
          className="admin-theme-color-preview"
          style={{ backgroundColor: value }}
          title={value}
        />
        <input
          id={label}
          type="text"
          className="admin-theme-text-input"
          value={value}
          onChange={(e) => onTextChange(e, label)}
        />
        <button
          type="button"
          className="admin-theme-color-button"
          onClick={onOpenPicker}
        >
          <FaPencilAlt />
        </button>
      </div>
    </div>
  );
}











