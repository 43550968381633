import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { server } from "../../server";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

import useWindowSize from "../../hooks/useWindowSize";
import Loader from "../Layout/Loader";
import { toast } from "react-toastify";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const SponsoredPostsAdmin = () => {
  const [sponsoredAds, setSponsoredAds] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("both"); 
  const [timeResolution, setTimeResolution] = useState("daily");
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Clicks",
        data: [],
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
      {
        label: "Ad Loads",
        data: [],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Viewed Impressions",
        data: [],
        borderColor: "rgb(255, 159, 64)",
        backgroundColor: "rgba(255, 159, 64, 0.5)",
      },
    ],
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  const chartRef = useRef(null);

  useEffect(() => {
    fetchSponsoredAds();
  }, [selectedFilter, timeResolution]);

  // 1) Fetch base sponsored ads
  const fetchSponsoredAds = async () => {
    setIsLoading(true);
    setErrorMessage("");

    try {
      // Fetch base ads from your admin route
      const response = await axios.get(`${server}/sponsored/sponsored-ads-all`, {
        headers: { "Content-Type": "application/json" },
        params: { filter: selectedFilter },
        withCredentials: true, 
      });
      console.log("Fetched Sponsored Ads Response:", response.data);
      
      // Extract IDs
      const adList = response.data || [];
      const adIds = adList.map((ad) => ad._id);
      
      // 2) Fetch metrics from the admin route
      const metricsResponse = await axios.post(
        `${server}/sponsored/metrics/batch-admin`,
        { adIds },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true, 
        }
      );
      console.log("Fetched Admin Metrics:", metricsResponse.data);

      const metricsMap = metricsResponse.data || {};

      // 3) Merge metrics into each ad
      const combinedAds = adList.map((ad) => {
        const adMetrics = metricsMap[ad._id] || {
          clicks: [],
          viewedImpressions: [],
          adLoads: [],
        };
        return {
          ...ad,
          metrics: adMetrics, // attach { clicks, viewedImpressions, adLoads }
        };
      });

      // Save to state & build chart data
      setSponsoredAds(combinedAds);
      processChartData(combinedAds, selectedFilter);
    } catch (error) {
      console.error("Error fetching sponsored ads:", error);
      setErrorMessage("Failed to load sponsored ads. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // 4) Build the multi-metric chart from combined ads
  const processChartData = (ads, filter) => {
    const filteredAds = ads.filter((ad) => {
      if (filter === "both") return true;
      if (filter === "product") return !!ad.product;
      if (filter === "shop") return ad.shop && !ad.product && !ad.brand && !ad.customAdText;
      if (filter === "brand") return !!ad.brand;
      if (filter === "custom") return !!ad.customAdText;
      return false;
    });

    const aggregationMap = {};

    filteredAds.forEach((ad) => {
      const clicksArr = ad.metrics?.clicks || [];
      const adLoadsArr = ad.metrics?.adLoads || [];
      const viewedArr = ad.metrics?.viewedImpressions || [];

      clicksArr.forEach((clickObj) => {
        const date = new Date(clickObj.timestamp);
        const key = generateKey(date, timeResolution);
        if (!aggregationMap[key]) {
          aggregationMap[key] = { clicks: 0, adLoads: 0, viewedImpressions: 0 };
        }
        aggregationMap[key].clicks += 1;
      });

      adLoadsArr.forEach((loadObj) => {
        const date = new Date(loadObj.timestamp);
        const key = generateKey(date, timeResolution);
        if (!aggregationMap[key]) {
          aggregationMap[key] = { clicks: 0, adLoads: 0, viewedImpressions: 0 };
        }
        aggregationMap[key].adLoads += 1;
      });

      viewedArr.forEach((impressObj) => {
        const date = new Date(impressObj.timestamp);
        const key = generateKey(date, timeResolution);
        if (!aggregationMap[key]) {
          aggregationMap[key] = { clicks: 0, adLoads: 0, viewedImpressions: 0 };
        }
        aggregationMap[key].viewedImpressions += 1;
      });
    });

    const labels = Object.keys(aggregationMap).sort((a, b) => new Date(a) - new Date(b));
    const clicksData = labels.map((label) => aggregationMap[label].clicks);
    const adLoadsData = labels.map((label) => aggregationMap[label].adLoads);
    const viewedData = labels.map((label) => aggregationMap[label].viewedImpressions);

    setChartData({
      labels,
      datasets: [
        {
          label: "Clicks",
          data: clicksData,
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
        {
          label: "Ad Loads",
          data: adLoadsData,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "Viewed Impressions",
          data: viewedData,
          borderColor: "rgb(255, 159, 64)",
          backgroundColor: "rgba(255, 159, 64, 0.5)",
        },
      ],
    });
  };

  // Helper: daily vs. hourly key
  const generateKey = (date, resolution) => {
    if (resolution === "daily") {
      return date.toISOString().split("T")[0]; // 'YYYY-MM-DD'
    }
    return `${date.toISOString().split("T")[0]} ${date.getHours()}:00`;
  };

  // Chart Options
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: "Sponsored Ads Performance",
        font: {
          size: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.parsed.y}`,
        },
      },
      legend: {
        position: "top",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: timeResolution === "daily" ? "Date" : "Date & Hour",
        },
      },
      y: {
        title: {
          display: true,
          text: "Count",
        },
        beginAtZero: true,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        hoverRadius: 7,
        radius: 5,
      },
    },
  };

  // Filter button clicks
  const handleFilterChange = (newFilter) => {
    setSelectedFilter(newFilter);
  };

  // Toggle ad active/inactive
  const toggleAdStatus = async (adId, isActive) => {
    try {
      await axios.post(
        `${server}/sponsored/toggle-status`,
        { adId, isActive: !isActive },
        { withCredentials: true }
      );
      setSponsoredAds((prevAds) =>
        prevAds.map((ad) =>
          ad._id === adId ? { ...ad, isActive: !ad.isActive } : ad
        )
      );
    } catch (error) {
      console.error("Failed to toggle ad status:", error);
      setErrorMessage("Error toggling ad status. Please try again.");
      toast.error("Error toggling ad status.");
    }
  };

  return (
    <div className="sponsored-posts-admin">
      

      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* Filter & Time Row */}
          <div className="sponsored-dashboard-container">
            <div className="sponsored-dashboard-filter-time-row">
              {/* Filter Buttons */}
              <div className="sponsored-dashboard-filter-row">
                <div
                  className={`sponsored-dashboard-filter-item ${
                    selectedFilter === "both" ? "active" : ""
                  }`}
                  onClick={() => handleFilterChange("both")}
                >
                  All
                </div>
                <div
                  className={`sponsored-dashboard-filter-item ${
                    selectedFilter === "product" ? "active" : ""
                  }`}
                  onClick={() => handleFilterChange("product")}
                >
                  Products
                </div>
                <div
                  className={`sponsored-dashboard-filter-item ${
                    selectedFilter === "shop" ? "active" : ""
                  }`}
                  onClick={() => handleFilterChange("shop")}
                >
                  Shops
                </div>
                <div
                  className={`sponsored-dashboard-filter-item ${
                    selectedFilter === "brand" ? "active" : ""
                  }`}
                  onClick={() => handleFilterChange("brand")}
                >
                  Brands
                </div>
                <div
                  className={`sponsored-dashboard-filter-item ${
                    selectedFilter === "custom" ? "active" : ""
                  }`}
                  onClick={() => handleFilterChange("custom")}
                >
                  Custom
                </div>
              </div>

              {/* Time Buttons */}
              <div className="sponsored-dashboard-time-buttons-row">
                <div
                  className={`sponsored-dashboard-time-filter-item ${
                    timeResolution === "daily" ? "active" : ""
                  }`}
                  onClick={() => setTimeResolution("daily")}
                >
                  Daily View
                </div>
                <div
                  className={`sponsored-dashboard-time-filter-item ${
                    timeResolution === "hourly" ? "active" : ""
                  }`}
                  onClick={() => setTimeResolution("hourly")}
                >
                  Hourly View
                </div>
              </div>
            </div>
          </div>

          {/* Chart */}
          <div className="chart-container" style={{ height: "500px" }}>
            <Line ref={chartRef} data={chartData} options={chartOptions} />
          </div>

          {/* Table */}
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <table className="sponsored-table" style={{ width: "80%", margin: "0 auto" }}>
              <thead>
                <tr>
                  <th>Shop</th>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Ad Spend</th>
                  <th>Ad Duration</th>
                  <th>Spent So Far</th>
                  <th>Status</th>
                  <th>Clicks</th>
                  <th>Impressions</th>
                  <th>CPC</th>
                  <th>CPI</th>
                  <th>Toggle</th>
                </tr>
              </thead>
              <tbody>
                {sponsoredAds.map((ad) => {
                  const type = ad.product
                    ? "Product"
                    : ad.brand
                    ? "Brand"
                    : ad.shop && !ad.product && !ad.brand && !ad.customAdText
                    ? "Shop"
                    : ad.customAdText
                    ? "Custom"
                    : "Unknown";

                  const name = ad.product
                    ? ad.product.name
                    : ad.brand
                    ? ad.brand.name
                    : ad.shop
                    ? ad.shop.name
                    : ad.customAdText
                    ? ad.customAdText
                    : "N/A";

                  // For images
                  const imageUrl =
                    ad.product?.images?.[0]?.url ||
                    ad.shop?.avatar?.url ||
                    ad.brand?.avatarImage?.url ||
                    ad.customAdImage?.url ||
                    "";

                  // metrics
                  const clicks = ad.metrics?.clicks?.length || 0;
                  const impressions = ad.metrics?.viewedImpressions?.length || 0;
                  // if adLoads needed => const adLoadsCount = ad.metrics?.adLoads?.length || 0;

                  const spentSoFar = ad.spentSoFar || 0;
                  const cpc = clicks > 0 ? (spentSoFar / clicks).toFixed(2) : "0.00";
                  const cpi = impressions > 0 ? (spentSoFar / impressions).toFixed(2) : "0.00";

                  return (
                    <tr key={ad._id}>
                      <td>{ad.shop?.name || "N/A"}</td>
                      <td>{type}</td>
                      <td>{name}</td>
                      <td>
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt={type}
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                          />
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>${ad.adSpend?.toFixed(2) || "0.00"}</td>
                      <td>{ad.adDuration || "N/A"}</td>
                      <td>${spentSoFar.toFixed(2)}</td>
                      <td>{ad.status}</td>
                      <td>{clicks}</td>
                      <td>{impressions}</td>
                      <td>${cpc}</td>
                      <td>${cpi}</td>
                      <td>
                        <button
                          onClick={() => toggleAdStatus(ad._id, ad.isActive)}
                          style={{
                            cursor: "pointer",
                            padding: "6px 10px",
                            border: "1px solid #ccc",
                            backgroundColor: ad.isActive ? "#f44336" : "#4caf50",
                            color: "#fff",
                            borderRadius: "4px",
                          }}
                          aria-label={`Toggle ad status for ${name}`}
                        >
                          {ad.isActive ? "Deactivate" : "Activate"}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default SponsoredPostsAdmin;
