import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { XMarkIcon } from '@heroicons/react/20/solid';
import AdFormatIconRow from './AdFormatIconRow';
import ShopImagesModal from '../Shop/ShopImagesModal';
import ImageCropperModal from '../Image/ImageCropperModal';
import { FaImages } from 'react-icons/fa';

const AdSetModal = ({ isOpen, onClose, onSave, adFormats, productImage, productName }) => {
    const [adImages, setAdImages] = useState({});
    const [adFiles, setAdFiles] = useState({});
    const [adPositions, setAdPositions] = useState({});
    const [adScales, setAdScales] = useState({});
    const [editMode, setEditMode] = useState({});
    const [selectedFormat, setSelectedFormat] = useState(adFormats[0]);
    const [adImageNames, setAdImageNames] = useState({});
    const [isUploaded, setIsUploaded] = useState({});
    const [isShopImagesModalOpen, setIsShopImagesModalOpen] = useState(false);
    const [isImageCropperOpen, setIsImageCropperOpen] = useState(false);
    const [originalImages, setOriginalImages] = useState({});
    const [previousCrops, setPreviousCrops] = useState({});


    useEffect(() => {
        if (isOpen) {
            const initialAdImages = {};
            const initialAdFiles = {};
            const initialAdPositions = {};
            const initialAdScales = {};
            const initialAdImageNames = {};
            const initialIsUploaded = {};
            const initialOriginalImages = {}; 
            adFormats.forEach((format) => {
                initialAdImages[format] = adImages[format] || productImage; 
                initialAdPositions[format] = adPositions[format] || { x: 0, y: 0 };
                initialAdImageNames[format] = adImageNames[format] || '';
                initialAdScales[format] = adScales[format] || 1;
                initialIsUploaded[format] = isUploaded[format] || false;
                initialOriginalImages[format] = originalImages[format] || productImage;
            });
            setAdImages(initialAdImages);
            setAdFiles(initialAdFiles);
            setAdPositions(initialAdPositions);
            setAdScales(initialAdScales);
            setAdImageNames(initialAdImageNames);
            setIsUploaded(initialIsUploaded);
            setOriginalImages(initialOriginalImages);
            setEditMode(adFormats.reduce((acc, format) => {
                acc[format] = false;
                return acc;
            }, {}));
            setSelectedFormat(adFormats[0]); 
        }
    }, [isOpen, adFormats, productImage]);



const handleImageSelect = (selectedImage) => {
    if (selectedImage) {
        setAdImages((prev) => ({
            ...prev,
            [selectedFormat]: selectedImage.url,  
        }));
        setAdFiles((prev) => ({
            ...prev,
            [selectedFormat]: selectedImage.file,  
        }));
        setOriginalImages((prev) => ({
            ...prev,
            [selectedFormat]: selectedImage.file,  
        }));
        setIsShopImagesModalOpen(false); 
        setIsImageCropperOpen(true);
    }
};

useEffect(() => {
    if (originalImages[selectedFormat]) {
        console.log('Updated Original Images:', originalImages[selectedFormat]);
    }
}, [originalImages, selectedFormat]);



const handleSave = () => {
    const adSetImages = { ...adImages };  
    Object.keys(adFiles).forEach((format) => {
        if (adFiles[format]) {
            adSetImages[format] = adFiles[format];  
        }
    });

    onSave({
        images: adSetImages,  
        files: adFiles,  
        positions: adPositions, 
        scales: adScales,
    });

    onClose();
};

const handleCroppedImage = (croppedImage, crop) => {

    if (croppedImage instanceof File) {
      setAdImages((prev) => ({
        ...prev,
        [selectedFormat]: URL.createObjectURL(croppedImage), // For preview
      }));
      setAdFiles((prev) => ({
        ...prev,
        [selectedFormat]: croppedImage, // Save the actual file for upload
      }));
      setOriginalImages((prev) => ({
        ...prev,
        [selectedFormat]: originalImages[selectedFormat], // Keep the full original image
    }));
    setPreviousCrops((prev) => ({
        ...prev,
        [selectedFormat]: crop, // Save the previous crop
    }));
    } else {
      setAdImages((prev) => ({
        ...prev,
        [selectedFormat]:  URL.createObjectURL(croppedImage), // Use the cropped URL directly
      }));
      setAdFiles((prev) => ({
        ...prev,
        [selectedFormat]: null, // No file in this case
      }));
      setOriginalImages((prev) => ({
        ...prev,
        [selectedFormat]: originalImages[selectedFormat], // Keep the full original image
    }));
    setPreviousCrops((prev) => ({
        ...prev,
        [selectedFormat]: crop, // Save the previous crop
    }));
    }
    setIsImageCropperOpen(false); // Close the cropper modal
  };
  

    
    

    if (!isOpen) return null;

    const formatDetails = {
        'Top Banner Ad': {
            label: 'Top Banner',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727087610/top_banner_ad-2_hll7nm.svg',
            viewType: 'desktop',
            aspectRatio: 16 / 1,
            minWidth: 1080,
        },
        'Top Banner Mobile Ad': {
            label: 'Top Banner (Mobile)',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727087610/top_banner_ad-2_hll7nm.svg',
            viewType: 'mobile',
            aspectRatio: 5 / 1,
            minWidth: 500,
        },
        'Banner Ad': {
            label: 'Bottom Banner',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727087984/bottom_banner_ad-2_srdajl.svg',
            viewType: 'desktop',
            aspectRatio: 12 / 1,
            minWidth: 1080,
        },
        'Banner Mobile Ad': {
            label: 'Bottom Banner (Mobile)',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727087984/bottom_banner_ad-2_srdajl.svg',
            viewType: 'mobile',
            aspectRatio: 4 / 1,
            minWidth: 500,
        },
        'Sponsored Products': {
            label: 'Sidebar Ad',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727089158/sidebar_ad-2_flagic.svg',
            viewType: 'both',
            aspectRatio: 1 / 1,
            minWidth: 300,
        },
        'Dual Carousel': {
            label: 'Dual Carousel',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727089990/dual_carousel_ad-2_qusoev.svg',
            viewType: 'both',
            aspectRatio: 1 / 1,
            minWidth: 500,
        },
        'Quad Carousel': {
            label: 'Quad Carousel',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727090138/quad_carousel_ad-2_vmsayt.svg',
            viewType: 'both',
            aspectRatio: 16 / 9,
            minWidth: 500,
        },
        'Sponsored Product Carousel': {
            label: 'Single Carousel',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727090221/single_carousel_ad-2_hxmkw6.svg',
            viewType: 'desktop',
            aspectRatio: 3 / 1,
            minWidth: 1080,
        },
        'Sponsored Product Carousel Mobile': {
            label: 'Single Carousel (Mobile)',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727090221/single_carousel_ad-2_hxmkw6.svg',
            viewType: 'mobile',
            aspectRatio: 16 / 9,
            minWidth: 720,
        },
        'Map Ad Slider': {
            label: 'Map Slider',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727090365/map_carousel_ad_r2d0ve.svg',
            viewType: 'both',
            aspectRatio: 4 / 5,
            minWidth: 400,
        },
        'Sponsored Feed Ads': {
        label: 'Feed Ad',
        icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1727090568/feed_ad_efwtaf.svg',
        viewType: 'both',
        aspectRatio: 1 / 1,
        minWidth: 500,
        },
        'Mobile Bar Banner Ad': {
            label: 'Mobile Bar Banner',
            icon: 'https://res.cloudinary.com/dzlopmfj8/image/upload/v1728515141/bottom_ticker_ad_l1qs0j.svg', 
            viewType: 'mobile',
            aspectRatio: 6 / 1, 
            minWidth: 300, 
        },
    };

    return (
        <div className="ad-set-modal-overlay">
            <div className="ad-set-modal">
                <button onClick={onClose} className="close-modal-button">
                    <AiOutlineClose />
                </button>
                <h2>Customize Ad Set</h2>

                {/* Ad Format Icon Row */}
                <AdFormatIconRow
                    formats={adFormats.map((format) => ({
                        name: format,
                        label: formatDetails[format]?.label || format,
                        icon: formatDetails[format]?.icon || 'default_icon_url',
                        viewType: formatDetails[format]?.viewType || 'desktop',
                    }))}
                    selectedFormat={selectedFormat}
                    onFormatSelect={setSelectedFormat}   
                />

                {/* Content based on selected format */}
                <div className="ad-format-section">
                    <h3>{selectedFormat}</h3>
                    <div className="ad-format-preview">
                        <div className="ad-format-content">
                        <button onClick={() => setIsShopImagesModalOpen(true)} className="open-shop-images-modal-button">
                            <FaImages size={18} style={{ marginRight: "8px" }} /> Select An Image
                        </button>
                <div className="ad-format-controls">
                        
                                        <button 
                                            onClick={() => setIsImageCropperOpen(true)} 
                                            
                                            className="ad-set-modal-edit-button"
                                        >
                                            Crop & Position Image
                                        </button>
                                        </div>

                                        {isImageCropperOpen && (
                                            <ImageCropperModal
                                                closeModal={() => setIsImageCropperOpen(false)}
                                                updateAvatar={(croppedImage, crop) => handleCroppedImage(croppedImage, crop)}
                                                initialImage={originalImages[selectedFormat] || adFiles[selectedFormat] || adImages[selectedFormat]}
                                                aspectRatio={formatDetails[selectedFormat]?.aspectRatio} 
                                                minWidth={formatDetails[selectedFormat]?.minWidth}
                                                previousCrop={previousCrops[selectedFormat]}
                                                isAvatar={false}
                                                isLogo={false}
                                            />
                                        )}

                {/* Shop Images Modal */}
                <ShopImagesModal
                    isOpen={isShopImagesModalOpen}
                    onClose={() => setIsShopImagesModalOpen(false)}
                    onImageSelect={handleImageSelect}
                />
                        

                            {/* Display content based on the selected format */}
                            {selectedFormat === 'Top Banner Ad' && (
                            <div className="ad-preview-top-banner-container">
                                <div className="ad-preview-top-banner-wrapper">
                                <div className="ad-preview-top-banner-content">
                                    <div className="ad-preview-top-banner-image-container">
                                    <img
                                        src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                        alt="Product"
                                        className="ad-preview-top-banner-image"
                                    />
                                    </div>
                                    <div className="ad-preview-top-banner-overlay">
                                    <div className="ad-preview-top-banner-text">
                                        <strong className="ad-preview-top-banner-ad-link">{productName}</strong>
                                        <span className="ad-preview-top-banner-ad-link"> – Explore Now &rarr;</span>
                                    </div>
                                    <button className="ad-preview-top-banner-ad-dismiss">
                                        <XMarkIcon className="ad-preview-top-banner-ad-icon" aria-hidden="true" />
                                        <span className="sr-only">Dismiss</span>
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            )}


                        {selectedFormat === 'Top Banner Mobile Ad' && (
                            <div className="ad-preview-mobile-container">
                        <div className="ad-preview-top-banner-mobile-container">
                            <div className="ad-preview-top-banner-content-mobile">
                                <div className="ad-preview-top-banner-image-container">
                                        <img
                                            src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                            alt="Product"
                                            className="ad-preview-top-banner-image"
                                        />
                                </div>
                                <div className="ad-preview-top-banner-text">
                                    <strong className="ad-preview-top-banner-ad-link">{productName}</strong>
                                    <span className="ad-preview-top-banner-ad-link"> – Explore Now &rarr;</span>
                                </div>
                                <button className="ad-preview-top-banner-ad-dismiss">
                                    <XMarkIcon className="ad-preview-top-banner-ad-icon" aria-hidden="true" />
                                    <span className="sr-only">Dismiss</span>
                                </button>
                            </div>
                        </div>
                        </div>
                    )}

                            {selectedFormat === 'Banner Ad' && (
                                <div className="ad-preview-banner-ad-container">
                                    <div className="ad-preview-banner-ad-image-container">
                                            <img
                                            src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                alt="Product"
                                                className="ad-preview-banner-ad-image"
                                            />
                                    </div>
                                    <div className="ad-preview-banner-ad-content">
                                        <strong className="ad-preview-banner-ad-link">{productName}</strong>
                                        <span className="ad-preview-banner-ad-link"> – Explore Now &rarr;</span>
                                        <button className="ad-preview-banner-ad-dismiss">
                                            <XMarkIcon className="ad-preview-banner-ad-icon" aria-hidden="true" />
                                            <span className="sr-only">Dismiss</span>
                                        </button>
                                    </div>
                                </div>
                            )}

                                {selectedFormat === 'Banner Mobile Ad' && (
                                     <div className="ad-preview-mobile-container">
                                    <div className="ad-preview-banner-mobile-ad-container">
                                        <div className="ad-preview-banner-mobile-ad-image-container">
                                                <img
                                                    src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                    alt="Product"
                                                    className="ad-preview-banner-mobile-ad-image"
                                                />
                                        </div>
                                        <div className="ad-preview-banner-mobile-ad-content">
                                            <strong className="ad-preview-banner-mobile-ad-link">{productName}</strong>
                                            <span className="ad-preview-banner-mobile-ad-link"> – Explore Now &rarr;</span>
                                            <button className="ad-preview-banner-mobile-ad-dismiss">
                                                <XMarkIcon className="ad-preview-banner-mobile-ad-icon" aria-hidden="true" />
                                                <span className="sr-only">Dismiss</span>
                                            </button>
                                        </div>
                                    </div>
                                    </div>
                                )}


                            {selectedFormat === 'Sponsored Products' && (
                                <div className="ad-preview-sponsored-products-container">
                                    <div className="ad-preview-sponsored-products-list">
                                        <div className="ad-preview-sponsored-products-item">
                                            <div className="ad-preview-sponsored-products-image-container">
                                                    <img
                                                    src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                        alt="Product"
                                                        className="ad-preview-sponsored-products-image"
                                                    />
                                            </div>
                                            <div className="ad-preview-sponsored-products-info">
                                                <div>
                                                    <h3 className="ad-preview-sponsored-products-product-name">{productName}</h3>
                                                    <p className="ad-preview-sponsored-products-product-price">$100</p>
                                                </div>
                                                <span className="ad-preview-sponsored-products-visit-store">Visit Store</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            

                            {selectedFormat === 'Dual Carousel' && (
                                <div className="ad-preview-dual-product-carousel-container">
                                    <div className="ad-preview-dual-product-carousel-wrapper">
                                    <div className="ad-preview-dual-product-carousel-item empty-box"></div>
                                        <div className="ad-preview-dual-product-carousel-item">
                                            <div className="ad-preview-dual-product-carousel-image-container">
                                                    <img
                                                    src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                        alt="Product"
                                                        className="ad-preview-dual-product-carousel-image"
                                                    />
                                            </div>
                                            <div className="ad-preview-dual-product-carousel-content">
                                                <h2 className="ad-preview-dual-product-carousel-title">{productName}</h2>
                                                <p className="ad-preview-dual-product-carousel-price">$100</p>
                                                <span className="ad-preview-dual-product-carousel-button">Buy now</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {selectedFormat === 'Quad Carousel' && (
                                <div className="ad-preview-quad-product-carousel-container">
                                    <div className="ad-preview-quad-product-carousel-wrapper">
                                    <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                            <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                            <div className="ad-preview-quad-product-carousel-item empty-box"></div>
                                        <div className="ad-preview-quad-product-carousel-item">
                                            <div className="ad-preview-quad-product-carousel-image-container">
                                                    <img
                                                    src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                        alt="Product"
                                                        className="ad-preview-quad-product-carousel-image"
                                                    />
                                            </div>
                                            <div className="ad-preview-quad-product-carousel-content">
                                                <h2 className="ad-preview-quad-product-carousel-title">{productName}</h2>
                                                <p className="ad-preview-quad-product-carousel-price">$100</p>
                                                <span className="ad-preview-quad-product-carousel-button">Buy now</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                                {selectedFormat === 'Sponsored Product Carousel' && (
                                <div className="ad-preview-sponsored-product-carousel-container">
                                    <div className="ad-preview-sponsored-product-carousel-wrapper">
                                    <div className="ad-preview-sponsored-product-carousel-item">
                                        <div className="ad-preview-sponsored-product-carousel-image-container">
                                            <img
                                            src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                            alt="Product"
                                            className="ad-preview-sponsored-product-carousel-image"
                                            />
                                        </div>
                                        <div className="ad-preview-sponsored-product-carousel-content">
                                        <h2 className="ad-preview-sponsored-product-carousel-title">{productName}</h2>
                                        <p className="ad-preview-sponsored-product-carousel-price">$100</p>
                                        <span className="ad-preview-sponsored-product-carousel-button">Buy now</span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                )}


                            {selectedFormat === 'Sponsored Product Carousel Mobile' && (
                                <div className="ad-preview-mobile-container">
                                    <div className="ad-preview-sponsored-product-mobile-carousel-wrapper">
                                        <div className="ad-preview-sponsored-product-carousel-mobile-item">
                                            <div className="ad-preview-sponsored-product-carousel-mobile-image-container">
                                                    <img
                                                        src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                        alt="Product"
                                                        className="ad-preview-sponsored-product-carousel-mobile-image"
                                                    />
                                            </div>
                                            <div className="ad-preview-sponsored-product-carousel-mobile-content">
                                                <h2 className="ad-preview-sponsored-product-carousel-mobile-title">{productName}</h2>
                                                <p className="ad-preview-sponsored-product-carousel-mobile-price">$100</p>
                                                <span className="ad-preview-sponsored-product-carousel-mobile-button">Buy now</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}


                            {selectedFormat === 'Map Ad Slider' && (
                                <div className="ad-preview-map-product-slider-container">
                                    <div className="ad-preview-map-product-slider-wrapper">
                                        <div className="ad-preview-map-product-slider-item">
                                            <div className="ad-preview-map-product-slider-image-container">
                                                    <img
                                                    src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                        alt="Product"
                                                        className="ad-preview-map-product-slider-image"
                                                    />
                                            </div>
                                            <div className="ad-preview-map-product-slider-content">
                                                <h2 className="ad-preview-map-product-slider-title">{productName}</h2>
                                                <p className="ad-preview-map-product-slider-price">$100</p>
                                                <span className="ad-preview-map-product-slider-button">Buy now</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {selectedFormat === 'Sponsored Feed Ads' && (
                                <div className="ad-preview-sponsored-feed-products-container">
                                    <div className="ad-preview-sponsored-feed-products-wrapper">
                                        <div className="ad-preview-sponsored-feed-products-item">
                                            <div className="ad-preview-sponsored-feed-products-image-container">
                                                    <img
                                                    src={adFiles[selectedFormat] ? URL.createObjectURL(adFiles[selectedFormat]) : adImages[selectedFormat]}
                                                        alt="Product"
                                                        className="ad-preview-sponsored-feed-products-image"
                                                    />
                                            </div>
                                            <div className="ad-preview-sponsored-feed-products-content">
                                            <div className="ad-preview-sponsored-feed-products-content-info">
                                                <h2 className="ad-preview-sponsored-feed-products-title">{productName}</h2>
                                                <p className="ad-preview-sponsored-feed-products-price">$100</p>
                                            </div>
                                            <div className="ad-preview-sponsored-feed-products-content-button">
                                                <span className="ad-preview-sponsored-feed-products-store-link">Buy Now</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {selectedFormat === 'Mobile Bar Banner Ad' && (
                                <div className="ad-preview-mobile-container">
                                    <div className="ad-preview-banner-mobile-container">
                                        <div className="ad-preview-banner-image-container">
                                            <img
                                                src={adImages[selectedFormat]} 
                                                alt="Product"
                                                className="ad-preview-mobile-image"
                                            />
                                        </div>
                                        <div className="ad-preview-banner-content">
                                            <strong className="ad-preview-banner-link">{productName}</strong>
                                            <span className="ad-preview-banner-link"> – Explore Now</span>
                                            <button className="ad-preview-banner-dismiss">
                                                <XMarkIcon className="ad-preview-banner-icon" aria-hidden="true" />
                                                <span className="sr-only">Dismiss</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            
                            
                        </div>
                    </div>
                </div>
                <button onClick={handleSave} className="save-ad-set-button">
                Save Ad Set
                </button>
            </div>
        </div>
    );
};

export default AdSetModal;





