import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrdersOfShop } from '../../redux/actions/order'; // <-- your existing action
import axios from 'axios';
import Loader from '../Layout/Loader'; // or wherever your Loader is
import { AiOutlineArrowRight } from 'react-icons/ai';
import { ecommerceServer } from '../../server';

const SellerOrdersTracking = () => {
  const dispatch = useDispatch();

  // Redux state from 'order' slice
  const { orders, isLoading } = useSelector((state) => state.order);
  // Redux state for 'seller'
  const { seller } = useSelector((state) => state.seller);

  // Local state for tracking data & errors
  const [trackingData, setTrackingData] = useState({});
  const [error, setError] = useState(null);

  // If you're using sessionStorage for profile name
  const currentProfileName = sessionStorage.getItem('currentProfile');

  // -----------------------------------------------------
  // 1. Fetch all orders of this shop (like in AllOrders)
  // -----------------------------------------------------
  useEffect(() => {
    if (seller && seller._id) {
      dispatch(getAllOrdersOfShop(seller._id));
    }
  }, [dispatch, seller]);

  // -----------------------------------------------------
  // 2. Handle tracking fetch for a specific order
  // -----------------------------------------------------
  const handleTrackOrder = async (orderId) => {
    try {
      setError(null);
      const res = await axios.get(`${ecommerceServer}/shipping/${orderId}/tracking`, {
        headers: {
          'current-profile': currentProfileName || 'defaultProfile',
        },
        withCredentials: true,
      });

      // res.data.trackingInfo is the ShipEngine-based object from your backend
      setTrackingData((prev) => ({
        ...prev,
        [orderId]: res.data.trackingInfo,
      }));
    } catch (err) {
      setError(
        err.response?.data?.message ||
          err.message ||
          'Failed to fetch tracking info.'
      );
    }
  };

  // -----------------------------------------------------
  // 3. Transform orders into rows, just like AllOrders
  //    (But you can customize columns as needed)
  // -----------------------------------------------------
  const rows = orders
    ? orders.map((item) => {
        // Example: compute total from cart
        const total = item.cart.reduce(
          (acc, curr) => acc + (curr.unitAmount / 100) * curr.quantity,
          0
        );

        // Return row data
        return {
          id: item._id,
          customerName: item?.user?.name || 'N/A',
          itemsQty: item.cart.length,
          total: `US$ ${total.toFixed(2)}`,
          status: item.status,
          paymentStatus: item.paymentInfo.status,
          date: new Date(item.createdAt).toLocaleDateString('en-US'),
        };
      })
    : [];

  // Optional: highlight row if Payment is pending
  const getRowClassName = (paymentStatus) =>
    paymentStatus === 'Pending' ? 'shop-all-orders-pending-row' : '';

  // -----------------------------------------------------
  // 4. Render the table (desktop) & list (mobile),
  //    plus the "Track" button to get tracking data
  // -----------------------------------------------------
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="shop-all-orders-container">
          {/* Error display */}
          {error && <p style={{ color: 'red' }}>{error}</p>}

          <div className="shop-all-orders-header">
            <h2 className="shop-all-orders-title-shipping">All Orders with Tracking</h2>
          </div>

          {/* ------------------- Desktop Table ------------------- */}
          <div className="shop-all-orders-table desktop-only">
            <table className="table">
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Customer</th>
                  <th>Status</th>
                  <th>Items Qty</th>
                  <th>Total</th>
                  <th>Payment Status</th>
                  <th>Date</th>
                  <th>Track</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => {
                  const trackInfo = trackingData[row.id];
                  return (
                    <React.Fragment key={row.id}>
                      <tr className={getRowClassName(row.paymentStatus)}>
                        <td>{row.id}</td>
                        <td>{row.customerName}</td>
                        <td
                          className={
                            row.status === 'Delivered'
                              ? 'greenColor'
                              : 'redColor'
                          }
                        >
                          {row.status}
                        </td>
                        <td>{row.itemsQty}</td>
                        <td>{row.total}</td>
                        <td
                          className={
                            row.paymentStatus === 'Pending'
                              ? 'yellowColor'
                              : 'greenColor'
                          }
                        >
                          {row.paymentStatus}
                        </td>
                        <td>{row.date}</td>
                        {/* Track button */}
                        <td>
                          <button
                            onClick={() => handleTrackOrder(row.id)}
                            className="shop-all-orders-track-btn"
                          >
                            Track
                          </button>
                        </td>
                        {/* View order (like in AllOrders) */}
                        <td className="shop-all-orders-actions">
                          <a href={`/order/${row.id}`}>
                            <AiOutlineArrowRight size={20} />
                          </a>
                        </td>
                      </tr>

                      {/* If we have tracking data, display an extra row or section */}
                      {trackInfo && (
                        <tr>
                          <td colSpan={9}>
                            <div style={{ padding: '1rem' }}>
                              <h3>Tracking Info:</h3>
                              <p>
                                <strong>Carrier Code:</strong>{' '}
                                {trackInfo.carrier_code}
                              </p>
                              <p>
                                <strong>Tracking Number:</strong>{' '}
                                {trackInfo.tracking_number}
                              </p>
                              <p>
                                <strong>Status:</strong> {trackInfo.status}
                              </p>

                              <h4>Events:</h4>
                              {trackInfo.tracking_events &&
                              trackInfo.tracking_events.length > 0 ? (
                                <ul>
                                  {trackInfo.tracking_events.map(
                                    (event, index) => (
                                      <li key={index}>
                                        <strong>{event.timestamp}:</strong>{' '}
                                        {event.status} - {event.description}
                                      </li>
                                    )
                                  )}
                                </ul>
                              ) : (
                                <p>No tracking events found.</p>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* ------------------- Mobile List ------------------- */}
          <ul className="shop-all-orders-mobile-list mobile-only">
            {rows.map((row) => {
              const trackInfo = trackingData[row.id];
              return (
                <li key={row.id} className="shop-all-orders-mobile-list-item">
                  <div className="shop-all-orders-mobile-content">
                    <div className="shop-all-orders-mobile-header">
                      <h3>Order #{row.id}</h3>
                      <p>{row.date}</p>
                    </div>
                    <p>Customer: {row.customerName}</p>
                    <p>
                      Status:{' '}
                      <span
                        className={
                          row.status === 'Delivered' ? 'greenColor' : 'redColor'
                        }
                      >
                        {row.status}
                      </span>
                    </p>
                    <p>Items: {row.itemsQty}</p>
                    <p>Total: {row.total}</p>
                    <p>
                      Payment Status:{' '}
                      <span
                        className={
                          row.paymentStatus === 'Pending'
                            ? 'yellowColor'
                            : 'greenColor'
                        }
                      >
                        {row.paymentStatus}
                      </span>
                    </p>
                    {/* Track button */}
                    <button
                      onClick={() => handleTrackOrder(row.id)}
                      className="shop-all-orders-track-btn"
                    >
                      Track
                    </button>

                    {/* Show tracking info if available */}
                    {trackInfo && (
                      <div style={{ marginTop: '10px' }}>
                        <h4>Tracking Info:</h4>
                        <p>
                          <strong>Carrier Code:</strong>{' '}
                          {trackInfo.carrier_code}
                        </p>
                        <p>
                          <strong>Tracking Number:</strong>{' '}
                          {trackInfo.tracking_number}
                        </p>
                        <p>
                          <strong>Status:</strong> {trackInfo.status}
                        </p>
                        <h4>Events:</h4>
                        {trackInfo.tracking_events &&
                        trackInfo.tracking_events.length > 0 ? (
                          <ul>
                            {trackInfo.tracking_events.map((event, index) => (
                              <li key={index}>
                                <strong>{event.timestamp}:</strong> {event.status} - {event.description}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p>No tracking events found.</p>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="shop-all-orders-mobile-action">
                    <a href={`/order/${row.id}`}>
                      <AiOutlineArrowRight size={20} />
                    </a>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default SellerOrdersTracking;

