import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReportedPosts } from '../../redux/actions/post';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from 'axios';
import { server } from '../../server';
import AdminHeader from '../Layout/AdminHeader';
import AdminSideBar from '../Admin/Layout/AdminSideBar';

const getSeverityColor = (severity) => {
  switch (severity) {
    case 'high':
      return '#ff4d4f';  
    case 'medium':
      return '#ffcc00';  
    case 'low':
      return '#28a745';  
    default:
      return '#ffffff'; 
  }
};

const ReportedPosts = () => {
  const dispatch = useDispatch();
  const { reportedPosts, loading, error } = useSelector((state) => state.posts);

  const [selectedPost, setSelectedPost] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    dispatch(fetchReportedPosts());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handlePostClick = (postId) => {
    const post = reportedPosts.find((report) => report.postId === postId);
    setSelectedPost(post);
  };

  const handleClosePostModal = () => {
    setSelectedPost(null);
  };

  const handleOpenConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleRemovePost = async () => {
    try {
      const response = await axios.post(
        `${server}/report/remove-post`,
        { postId: selectedPost.postId },
        { withCredentials: true }
      );
      if (response.data.success) {
        toast.success(response.data.message);
        setSelectedPost(null);
        dispatch(fetchReportedPosts());
      } else {
        toast.error('Failed to remove the post');
      }
    } catch (error) {
      toast.error('An error occurred while removing the post');
    } finally {
      setShowConfirmModal(false);
    }
  };

  return (
    <div>
      <AdminHeader />
      <div className="reported-posts-admin-container">
        <aside className="reported-posts-admin-sidebar">
          <AdminSideBar active={2} />
        </aside>
        <main className="reported-posts-admin-content">
          <ToastContainer />
          <h1 className="reported-posts-admin-title">Reported Posts</h1>

          {loading ? (
            <div className="reported-posts-admin-loader">Loading...</div>
          ) : (
            <div className="reported-posts-admin-table-container">
              <table className="reported-posts-admin-table">
                <thead className="reported-posts-admin-table-head">
                  <tr className="reported-posts-admin-table-row">
                    <th>Post ID</th>
                    <th>Post Owner</th>
                    <th>Description</th>
                    <th>Reason</th>
                    <th>Report Description</th>
                    <th>Reported By</th>
                    <th>Reported At</th>
                    <th>Severity</th>
                  </tr>
                </thead>
                <tbody className="reported-posts-admin-table-body">
                  {reportedPosts.map((report) => (
                    <tr
                      key={report.reportId}
                      onClick={() => handlePostClick(report.postId)}
                      className={`reported-posts-admin-table-body-row ${
                        report.isRemoved ? 'reported-posts-admin-removed' : ''
                      }`}
                      style={{
                        backgroundColor: getSeverityColor(report.severity),
                      }}
                    >
                      <td>{report.postId}</td>
                      <td>{report.postOwner}</td>
                      <td>{report.description}</td>
                      <td>{report.reason}</td>
                      <td>{report.reportDescription}</td>
                      <td>
                        {report.reportedBy.name} ({report.reportedBy.email})
                      </td>
                      <td>{new Date(report.createdAt).toLocaleString()}</td>
                      <td>{report.severity || "N/A"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Post Details Modal */}
          {selectedPost && (
            <div
              className="reported-posts-admin-modal-overlay"
              onClick={handleClosePostModal}
            >
              <div
                className="reported-posts-admin-modal-box"
                onClick={(e) => e.stopPropagation()}
              >
                <button
                  className="reported-posts-admin-modal-close"
                  onClick={handleClosePostModal}
                  aria-label="Close modal"
                >
                  &times;
                </button>
                <div className="reported-posts-admin-modal-content">
                  <h2 className="reported-posts-admin-modal-title">
                    Post by {selectedPost.postOwner}
                  </h2>
                  <p className="reported-posts-admin-modal-date">
                    {format(new Date(selectedPost.createdAt), 'PPP')}
                  </p>
                  <div className="reported-posts-admin-modal-description">
                    <p>{selectedPost.description}</p>
                    {selectedPost.images && selectedPost.images.length > 0 && (
                      <div className="reported-posts-admin-modal-media">
                        {selectedPost.images.map((image, index) => (
                          <LazyLoadImage
                            key={index}
                            alt={`post image ${index + 1}`}
                            src={image.url}
                            className="reported-posts-admin-modal-image"
                            placeholderSrc="path/to/placeholder/image.jpg"
                          />
                        ))}
                      </div>
                    )}
                    {selectedPost.videos && selectedPost.videos.length > 0 && (
                      <div className="reported-posts-admin-modal-media">
                        {selectedPost.videos.map((video, index) => (
                          <video
                            key={index}
                            className="reported-posts-admin-modal-video"
                            controls
                          >
                            <source src={video.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="reported-posts-admin-modal-comments">
                    <p>{selectedPost.comments} comments</p>
                  </div>
                  <div className="reported-posts-admin-modal-actions">
                    <button
                      className="reported-posts-admin-modal-remove-button"
                      onClick={handleOpenConfirmModal}
                    >
                      Remove Post
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Confirm Removal Modal */}
          {showConfirmModal && (
            <div className="reported-posts-admin-confirm-modal-overlay">
              <div className="reported-posts-admin-confirm-modal">
                <p className="reported-posts-admin-confirm-text">
                  Are you sure you want to remove this post?
                </p>
                <div className="reported-posts-admin-confirm-actions">
                  <button
                    className="reported-posts-admin-confirm-button reported-posts-admin-confirm-yes"
                    onClick={handleRemovePost}
                  >
                    Yes
                  </button>
                  <button
                    className="reported-posts-admin-confirm-button reported-posts-admin-confirm-no"
                    onClick={handleCloseConfirmModal}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default ReportedPosts;