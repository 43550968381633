import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { format, parseISO } from "date-fns";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const AllProducts = () => {
  const [data, setData] = useState([]);
  const [timeframe, setTimeframe] = useState("daily"); // "daily" | "monthly"
  const [searchTerm, setSearchTerm] = useState("");    // For search filtering

  // Chart states
  // 1) Bar Chart: "Products by Category"
  const [categoryData, setCategoryData] = useState({
    labels: [],
    datasets: [
      {
        label: "Number of Products",
        data: [],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  });

  // 2) Line Chart: "Products Added Over Time"
  const [productsAddedData, setProductsAddedData] = useState({
    labels: [],
    datasets: [
      {
        label: "Products Added",
        data: [],
        borderColor: "#4BC0C0",
        backgroundColor: "rgba(75,192,192,0.2)",
        tension: 0.4,
        fill: true,
      },
    ],
  });

  // ---------------------------
  //  Fetch Products on Mount
  // ---------------------------
  useEffect(() => {
    axios
      .get(`${server}/product/admin-all-products`, { withCredentials: true })
      .then((res) => {
        const products = res.data.products;
        setData(products);
        processCategoryData(products); // For Bar Chart
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        toast.error("Failed to fetch products.");
      });
  }, []);

  // Re-run the time-based chart function whenever `data` or `timeframe` changes
  useEffect(() => {
    if (data && data.length > 0) {
      processProductsAddedData(data, timeframe);
    }
  }, [data, timeframe]);

  // ---------------------------
  //  Process Category Data (Bar)
  // ---------------------------
  const processCategoryData = (products) => {
    if (!products || products.length === 0) return;

    const categoryCounts = products.reduce((acc, product) => {
      const cat = product.category || "Unknown";
      acc[cat] = (acc[cat] || 0) + 1;
      return acc;
    }, {});

    setCategoryData({
      labels: Object.keys(categoryCounts),
      datasets: [
        {
          label: "Number of Products",
          data: Object.values(categoryCounts),
          backgroundColor: Object.keys(categoryCounts).map(
            () => "rgba(53, 162, 235, 0.5)"
          ),
        },
      ],
    });
  };

  // ---------------------------
  //  Process Time Chart (Line)
  // ---------------------------
  const processProductsAddedData = (products, timeframe) => {
    if (!products || products.length === 0) return;

    // Sort by creation date ascending
    const sortedProducts = [...products].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );

    const productsAddedCounts = {};

    sortedProducts.forEach((product) => {
      if (!product.createdAt) return;

      const dateObj = new Date(product.createdAt);
      const dateISO = parseISO(dateObj.toISOString());

      // Daily or Monthly
      let label = "";
      if (timeframe === "daily") {
        // e.g. "01/23/2025"
        label = format(dateISO, "MM/dd/yyyy");
      } else if (timeframe === "monthly") {
        // e.g. "01/2025"
        label = format(dateISO, "MM/yyyy");
      }

      if (!productsAddedCounts[label]) {
        productsAddedCounts[label] = 0;
      }
      productsAddedCounts[label]++;
    });

    // Sort labels chronologically
    const labels = Object.keys(productsAddedCounts).sort(
      (a, b) => new Date(a) - new Date(b)
    );

    setProductsAddedData({
      labels,
      datasets: [
        {
          label: "Products Added",
          data: labels.map((lbl) => productsAddedCounts[lbl]),
          borderColor: "#4BC0C0",
          backgroundColor: "rgba(75,192,192,0.2)",
          tension: 0.4,
          fill: true,
        },
      ],
    });
  };

  // ---------------------------
  //  Delete Handler
  // ---------------------------
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await axios.delete(`${server}/product/admin-delete/${id}`, {
          withCredentials: true,
        });
        toast.success("Product deleted successfully");
        setData((prev) => prev.filter((p) => p._id !== id));
      } catch (error) {
        toast.error("Failed to delete product");
        console.error("Error deleting product:", error);
      }
    }
  };

  // ---------------------------
  //  Filtering for Search
  // ---------------------------
  const filteredData = data.filter((item) => {
    // Convert to lower case for case-insensitive match
    const term = searchTerm.toLowerCase();
    return (
      item.name.toLowerCase().includes(term) ||
      item.SKU?.toLowerCase().includes(term) ||
      item.category?.toLowerCase().includes(term) ||
      item.handle?.toLowerCase().includes(term)
    );
  });

  // ---------------------------
  //  Render
  // ---------------------------
  return (
    <div className="admin-all-products-component">

      {/* Timeframe Buttons */}
      <div className="admin-all-products-component-timeframe-container">
        <button
          className={`admin-all-products-component-timeframe-button ${
            timeframe === "daily" ? "active" : ""
          }`}
          onClick={() => setTimeframe("daily")}
        >
          Daily
        </button>
        <button
          className={`admin-all-products-component-timeframe-button ${
            timeframe === "monthly" ? "active" : ""
          }`}
          onClick={() => setTimeframe("monthly")}
        >
          Monthly
        </button>
      </div>

      {/* Charts Section */}
      <div
        className="admin-all-products-charts-container"
        style={{ display: "flex", gap: "20px" }}
      >
        {/* Bar Chart - Products by Category */}
        <div
          className="admin-all-products-chart"
          style={{ flex: 1, height: "400px" }}
        >
          <Bar
            data={categoryData}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: { position: "top" },
                title: {
                  display: true,
                  text: "Number of Products by Category",
                },
              },
              scales: {
                y: { beginAtZero: true },
              },
            }}
          />
        </div>

        {/* Line Chart - Products Added Over Time */}
        <div
          className="admin-all-products-chart"
          style={{ flex: 1, height: "400px" }}
        >
          <Line
            data={productsAddedData}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: { position: "top" },
                title: {
                  display: true,
                  text: `Products Added Over Time (${timeframe})`,
                },
              },
              scales: {
                x: {
                  type: "time",
                  time: {
                    parser:
                      timeframe === "daily" ? "MM/dd/yyyy" : "MM/yyyy",
                    unit: timeframe === "daily" ? "day" : "month",
                  },
                },
                y: { beginAtZero: true },
              },
            }}
          />
        </div>
      </div>

      {/* Search Bar */}
      <div className="admin-all-products-search-bar-container">
        <input
          className="admin-all-products-search-input"
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Data Table Section */}
      <div className="admin-all-products-data-grid">
        <table className="admin-all-products-data-table">
          <thead>
            <tr>
              <th>Image</th>
              <th>Product Id</th>
              <th>Name</th>
              <th>Product Type</th>
              <th>Shop Name</th>
              <th>Price</th>
              <th>Stock</th>
              <th>SKU</th>
              <th>Category</th>
              <th>Sold Out</th>
              <th>Preview</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr key={item._id}>
                <td>
                  <img
                    src={item?.images?.[0]?.url || "defaultProductImageUrl"}
                    alt={item.name}
                    style={{
                      width: "125px",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                </td>
                <td>{item._id}</td>
                <td>{item.name}</td>
                <td>
                  {Array.isArray(item.productType)
                    ? item.productType.join(", ")
                    : item.productType}
                </td>
                <td>{item.handle}</td>
                <td>
                  {"US$ " + (item.discountPrice ?? item.originalPrice ?? 0)}
                </td>
                <td>{item.stock}</td>
                <td>{item.SKU}</td>
                <td>{item.category}</td>
                <td>{item.sold_out}</td>
                <td>
                  <Link to={`/product/${item._id}`}>
                    <button className="admin-all-products-icon-button">
                      <AiOutlineEye size={20} />
                    </button>
                  </Link>
                </td>
                <td>
                  <button
                    className="admin-all-products-icon-button"
                    onClick={() => handleDelete(item._id)}
                  >
                    <AiOutlineDelete size={20} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllProducts;



