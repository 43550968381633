import React, { useEffect, useState } from "react";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { ecommerceServer } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";

const OrderDetails = () => {
  const { orders } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const navigate = useNavigate();
  const currentProfileName = sessionStorage.getItem('currentProfile');
  const { id } = useParams();

  useEffect(() => {
    if (seller && seller._id) {
      dispatch(getAllOrdersOfShop(seller._id));
    }
  }, [dispatch, seller._id]);

  useEffect(() => {
    const currentOrder = orders?.find((item) => item._id === id);
    if (currentOrder) {
      setStatus(currentOrder.status);
    }
  }, [orders, id]);

  const data = orders && orders.find((item) => item._id === id);
  const sellerCartItems = data?.cart.filter(item => item.shopId === seller._id) || [];
  const sellerTotalPrice = sellerCartItems.reduce((acc, item) => acc + (item.unitAmount * item.quantity) / 100, 0);
  console.log("Data:", data);

  const orderUpdateHandler = async () => {
    setUpdatingStatus(true);
    try {
      await axios.put(
        `${ecommerceServer}/order/update-order-status/${id}`,
        { status },
        {
          withCredentials: true,
          headers: {
            'current-profile': currentProfileName || 'defaultProfile',
          }
        }
      );
      toast.success("Order updated!");
      dispatch(getAllOrdersOfShop(seller._id)); // Refresh orders list
      navigate("/dashboard-orders");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update order.");
    } finally {
      setUpdatingStatus(false);
    }
  };

  const refundOrderUpdateHandler = async () => {
    setUpdatingStatus(true);
    try {
      await axios.put(
        `${ecommerceServer}/order/order-refund-success/${id}`,
        { status },
        { withCredentials: true }
      );
      toast.success("Order updated!");
      dispatch(getAllOrdersOfShop(seller._id)); // Refresh orders list
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update order.");
    } finally {
      setUpdatingStatus(false);
    }
  };

  return (
    <div className="shop-order-details-container">
      <div className="shop-order-details-header">
        <div className="shop-order-details-header-left">
          <BsFillBagFill size={30} />
          <h1 className="shop-order-details-title">Order Details</h1>
        </div>
        <Link to="/dashboard-orders">
          <div className="shop-order-details-button">Order List</div>
        </Link>
      </div>

      <div className="shop-order-details-info">
        <h5 className="shop-order-details-id">Order ID: <span>#{data?._id?.slice(0, 8)}</span></h5>
        <h5 className="shop-order-details-date">Placed on: <span>{data?.createdAt?.slice(0, 10)}</span></h5>
      </div>

      <div className="shop-order-details-customer">
        <h4 className="shop-order-details-subtitle">Customer Details:</h4>
        <p className="shop-order-details-customer-name">{data?.user?.name}</p>
        <p className="shop-order-details-customer-email">{data?.user?.email}</p>
      </div>

      {sellerCartItems.map((item, index) => (
        <div key={index} className="shop-order-details-item">
          <img
            alt={item.productName}
            src={item.images}
            className="shop-order-details-item-image"
          />
          <div className="shop-order-details-item-info">
            <h4 className="shop-order-details-item-name">{item.productName}</h4>
            {item.variant && (
        <div className="shop-order-details-item-variants">
          {item.variant.size && (
            <p className="shop-order-details-item-variant">
              Size: {item.variant.size}
            </p>
          )}
          {item.variant.color && (
            <p className="shop-order-details-item-variant">
              Color: {item.variant.color}
            </p>
          )}
          {item.variant.customTitle && item.variant.customValue && (
            <p className="shop-order-details-item-variant">
              {item.variant.customTitle}: {item.variant.customValue}
            </p>
          )}
        </div>
      )}

      <p className="shop-order-details-item-sku">
      SKU: {item.variant?.sku || item.sku}
      </p>
            <p className="shop-order-details-item-price">
              US${(item.unitAmount / 100).toFixed(2)} x {item.quantity}
            </p>
          </div>
        </div>
      ))}

      <div className="shop-order-details-total">
        <h5 className="shop-order-details-total-price">Total Price: <strong>US${sellerTotalPrice.toFixed(2)}</strong></h5>
      </div>

      <div className="shop-order-details-shipping">
        <div className="shop-order-details-shipping-address">
          <h4 className="shop-order-details-subtitle">Shipping Address:</h4>
          <h4>{data?.shippingAddress.address1 + " " + data?.shippingAddress.address2}</h4>
          <h4>{data?.shippingAddress.country}</h4>
          <h4>{data?.shippingAddress.city}</h4>
          <h4>{data?.shippingAddress.state}</h4>
        </div>
        <div className="shop-order-details-payment">
          <h4 className="shop-order-details-subtitle">Payment Info:</h4>
          <h4>
            Status: {data?.paymentInfo?.status ? data?.paymentInfo?.status : "Not Paid"}
          </h4>
        </div>
      </div>

      {data?.shippingLabel?.url && (
        <div className="shop-order-details-label-download">
          
          <a 
            href={data.shippingLabel.url} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="shop-order-details-download-link"
          >
            Download Shipping Label
          </a>
        </div>
      )}


      <h4 className="shop-order-details-subtitle">Order Status:</h4>
      <select
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        className="shop-order-details-select"
      >
        {data?.status !== "Processing refund" && data?.status !== "Refund Success"
          ? ["Processing", "Transferred to delivery partner", "Shipping", "Received", "On the way", "Delivered"]
              .slice(
                ["Processing", "Transferred to delivery partner", "Shipping", "Received", "On the way", "Delivered"]
                  .indexOf(data?.status)
              )
              .map((option, index) => (
                <option value={option} key={index}>
                  {option}
                </option>
              ))
          : ["Processing refund", "Refund Success"]
              .slice(["Processing refund", "Refund Success"].indexOf(data?.status))
              .map((option, index) => (
                <option value={option} key={index}>
                  {option}
                </option>
              ))}
      </select>

      <div
        className={`shop-order-details-update-button ${updatingStatus ? 'disabled' : ''}`}
        onClick={
          data?.status !== "Processing refund" && !updatingStatus
            ? orderUpdateHandler
            : !updatingStatus
            ? refundOrderUpdateHandler
            : undefined
        }
        disabled={updatingStatus}
      >
        {updatingStatus ? "Updating..." : "Update Status"}
      </div>
    </div>
  );
};

export default OrderDetails;

