import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider, useDispatch, useSelector } from "react-redux";
import Store from "./redux/store";
import { loadTheme } from "./redux/actions/theme";
import "font-awesome/css/font-awesome.min.css";

const ThemeLoader = ({ children }) => {
  const dispatch = useDispatch();
  const { theme } = useSelector((state) => state.theme);

  useEffect(() => {
    // 1) Load theme from DB on initial mount
    dispatch(loadTheme());
  }, [dispatch]);

  useEffect(() => {
    // 2) If theme is loaded, set all CSS variables
    if (theme && Object.keys(theme).length > 0) {
      // Apply each field as a CSS variable
      Object.entries(theme).forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--${key}`, value);
      });

      // If there's a siteLogoUrl, set that
      if (theme.siteLogoUrl) {
        document.documentElement.style.setProperty(
          "--site-logo-url",
          `url(${theme.siteLogoUrl})`
        );
      }

      // 3) Toggle dark-mode or light-mode based on isDarkMode
      // (If isDarkMode is not defined, fallback to false => light mode)
      const useDark = theme.isDarkMode === true;

      document.body.classList.toggle("dark-mode", useDark);
      document.body.classList.toggle("light-mode", !useDark);
    }
  }, [theme]);

  return children;
};

ReactDOM.render(
  <Provider store={Store}>
    <ThemeLoader>
      <App />
    </ThemeLoader>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();

