import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import { Button } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { getAllSellers } from "../../redux/actions/sellers";
import { Switch } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AllSellers = () => {
  const dispatch = useDispatch();
  const { sellers } = useSelector((state) => state.seller);

  const [editOpen, setEditOpen] = useState(false);
  const [currentSeller, setCurrentSeller] = useState({});
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");

  const [avatarFile, setAvatarFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);

  const [timeFrame, setTimeFrame] = useState("daily");
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Seller Sign-ups",
        data: [],
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
    ],
  });

  useEffect(() => {
    dispatch(getAllSellers());
  }, [dispatch]);

  useEffect(() => {
    if (sellers && sellers.length > 0) {
      processChartData(sellers, timeFrame);
    }
  }, [sellers, timeFrame]);

  // --------------------------------
  //   PROCESS CHART DATA
  // --------------------------------
  const processChartData = (sellers, timeFrame) => {
    const sortedSellers = [...sellers].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    const aggregationMap = {};

    sortedSellers.forEach((seller) => {
      const date = new Date(seller.createdAt);
      let key;
      if (timeFrame === "daily") {
        key = date.toLocaleDateString(); // e.g. "1/26/2025"
      } else {
        // monthly: e.g. "1-2025"
        key = `${date.getMonth() + 1}-${date.getFullYear()}`;
      }

      if (!aggregationMap[key]) {
        aggregationMap[key] = 0;
      }
      aggregationMap[key]++;
    });

    const labels = Object.keys(aggregationMap);
    const data = Object.values(aggregationMap);

    setChartData({
      labels,
      datasets: [
        {
          label: "Seller Sign-ups",
          data,
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
      ],
    });
  };

  // --------------------------------
  //   EDIT LOGIC
  // --------------------------------
  const handleEditClick = (seller) => {
    setCurrentSeller(seller);
    setEditOpen(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    // Append text fields
    Object.keys(currentSeller).forEach((key) => {
      if (key !== "avatar" && key !== "banner") {
        formData.append(key, currentSeller[key]);
      }
    });

    // Append files
    if (avatarFile) formData.append("avatar", avatarFile);
    if (bannerFile) formData.append("banner", bannerFile);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      };
      const response = await axios.patch(`${server}/shop/edit-seller/${currentSeller.id}`, formData, config);
      toast.success(response.data.message);
      setEditOpen(false);
      dispatch(getAllSellers());
    } catch (error) {
      console.error("Error updating seller:", error);
      toast.error("Failed to update seller.");
    }
  };

  // --------------------------------
  //   CHART OPTIONS
  // --------------------------------
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Seller Sign-ups Over Time",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Count",
        },
        beginAtZero: true,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        hoverRadius: 7,
        radius: 5,
      },
    },
  };

  // --------------------------------
  //   DELETE LOGIC
  // --------------------------------
  const handleDelete = async (id) => {
    await axios.delete(`${server}/shop/delete-seller/${id}`, { withCredentials: true }).then((res) => {
      toast.success(res.data.message);
    });
    dispatch(getAllSellers());
  };

  // --------------------------------
  //   MUI DataGrid COLUMNS & ROWS
  // --------------------------------
  const columns = [
    { field: "id", headerName: "Seller ID", minWidth: 150, flex: 0.7 },
    {
      field: "avatar",
      headerName: "Avatar",
      renderCell: (params) => (
        <img
          src={params.value}
          alt="avatar"
          style={{ width: 30, height: 30, borderRadius: "50%" }}
        />
      ),
      minWidth: 70,
      flex: 0.5,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "email",
      headerName: "Email",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "address",
      headerName: "Seller Address",
      type: "text",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "joinedAt",
      headerName: "Joined At",
      type: "text",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "  ",
      flex: 1,
      minWidth: 150,
      headerName: "Preview Shop",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/shop/${params.id}`}>
            <Button>
              <AiOutlineEye size={20} />
            </Button>
          </Link>
        );
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => handleEditClick(params.row)}>
          <AiOutlineEdit size={20} />
        </Button>
      ),
      flex: 1,
      minWidth: 150,
    },
    {
      field: " ",
      flex: 1,
      minWidth: 150,
      headerName: "Delete Seller",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Button onClick={() => setUserId(params.id) || setOpen(true)}>
            <AiOutlineDelete size={20} />
          </Button>
        );
      },
    },
  ];

  const row = [];
  sellers &&
    sellers.forEach((item) => {
      row.push({
        id: item._id,
        name: item?.name,
        email: item?.email,
        joinedAt: item.createdAt.slice(0, 10),
        address: item.address,
        avatar: item.avatar.url,
      });
    });

  return (
    <div className="admin-all-sellers-container">
      {/* Graph Section */}
      <div className="admin-all-sellers-chart-section">
        <h3 className="admin-all-sellers-chart-heading">Seller Sign-ups Chart</h3>

        {/* Timeframe Buttons */}
        <div className="admin-all-products-component-timeframe-container">
          <button
            className={`admin-all-products-component-timeframe-button ${
              timeFrame === "daily" ? "active" : ""
            }`}
            onClick={() => setTimeFrame("daily")}
          >
            Daily
          </button>
          <button
            className={`admin-all-products-component-timeframe-button ${
              timeFrame === "monthly" ? "active" : ""
            }`}
            onClick={() => setTimeFrame("monthly")}
          >
            Monthly
          </button>
        </div>


        <div className="admin-all-sellers-chart-wrapper">
          <Line data={chartData} options={options} />
        </div>
      </div>

      <div className="admin-all-sellers-datagrid-section">
        <h3 className="admin-all-sellers-datagrid-heading">All Sellers</h3>
        <div className="admin-all-sellers-datagrid-wrapper">
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            rowHeight={120}
            sx={{
              boxShadow: "none",
              border: "1px solid #2D3748",
              ".MuiDataGrid-columnHeaders": {
                backgroundColor: "#146e10",
                color: "#F7FAFC",
                justifyContent: "center",
              },
              ".MuiDataGrid-cell": {
                borderBottom: "1px solid #2D3748",
                padding: "4px",
                color: "#F7FAFC",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              },
              ".MuiDataGrid-cell:focus": {
                outline: "none",
              },
            }}
          />
        </div>
      </div>

      {/* Confirm Delete Modal */}
      {open && (
        <div className="admin-all-sellers-modal-overlay">
          <div className="admin-all-sellers-modal">
            <div className="admin-all-sellers-modal-close" onClick={() => setOpen(false)}>
              <RxCross1 size={25} />
            </div>
            <h3 className="admin-all-sellers-modal-title">
              Are you sure you want to delete this user?
            </h3>
            <div className="admin-all-sellers-modal-buttons">
              <button
                className="admin-all-sellers-modal-button"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button
                className="admin-all-sellers-modal-button"
                onClick={() => setOpen(false) || handleDelete(userId)}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Seller Modal */}
      {editOpen && (
        <div className="admin-all-sellers-modal-overlay">
          <div className="admin-all-sellers-modal admin-all-sellers-modal--scrollable">
            <div
              className="admin-all-sellers-modal-close"
              onClick={() => setEditOpen(false)}
            >
              <RxCross1 size={25} />
            </div>
            <form className="admin-all-sellers-edit-form" onSubmit={handleEditSubmit}>
              {/* Name */}
              <div className="admin-all-sellers-form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  value={currentSeller.name || ""}
                  onChange={(e) =>
                    setCurrentSeller({ ...currentSeller, name: e.target.value })
                  }
                />
              </div>
              {/* Handle */}
              <div className="admin-all-sellers-form-group">
                <label htmlFor="handle">Handle:</label>
                <input
                  type="text"
                  id="handle"
                  value={currentSeller.handle || ""}
                  onChange={(e) =>
                    setCurrentSeller({ ...currentSeller, handle: e.target.value })
                  }
                />
              </div>

              {/* isActive */}
              <div className="admin-all-sellers-form-group-switch">
                <label htmlFor="isActive">Is Active:</label>
                <Switch
                  checked={currentSeller.isActive || false}
                  onChange={(newValue) =>
                    setCurrentSeller({ ...currentSeller, isActive: newValue })
                  }
                  className={classNames(
                    currentSeller.isActive ? "bg-indigo-600" : "bg-gray-200",
                    "admin-all-sellers-switch"
                  )}
                >
                  <span className="sr-only">Activate seller</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      currentSeller.isActive
                        ? "admin-all-sellers-switch-toggle--active"
                        : "admin-all-sellers-switch-toggle",
                      "admin-all-sellers-switch-toggle"
                    )}
                  />
                </Switch>
              </div>

              {/* Email */}
              <div className="admin-all-sellers-form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  value={currentSeller.email || ""}
                  onChange={(e) =>
                    setCurrentSeller({ ...currentSeller, email: e.target.value })
                  }
                />
              </div>

              {/* Description */}
              <div className="admin-all-sellers-form-group-wide">
                <label htmlFor="description">Description:</label>
                <textarea
                  id="description"
                  value={currentSeller.description || ""}
                  onChange={(e) =>
                    setCurrentSeller({ ...currentSeller, description: e.target.value })
                  }
                />
              </div>

              {/* Avatar */}
              <div className="admin-all-sellers-form-group">
                <label htmlFor="avatar">Avatar:</label>
                <input
                  type="file"
                  id="avatar"
                  onChange={(e) => setAvatarFile(e.target.files[0])}
                />
              </div>

              {/* Banner */}
              <div className="admin-all-sellers-form-group">
                <label htmlFor="banner">Banner:</label>
                <input
                  type="file"
                  id="banner"
                  onChange={(e) => setBannerFile(e.target.files[0])}
                />
              </div>

              {/* Address */}
              <div className="admin-all-sellers-form-group">
                <label htmlFor="address">Address:</label>
                <input
                  type="text"
                  id="address"
                  value={currentSeller.address || ""}
                  onChange={(e) =>
                    setCurrentSeller({ ...currentSeller, address: e.target.value })
                  }
                />
              </div>

              {/* Phone */}
              <div className="admin-all-sellers-form-group">
                <label htmlFor="phoneNumber">Phone Number:</label>
                <input
                  type="text"
                  id="phoneNumber"
                  value={currentSeller.phoneNumber || ""}
                  onChange={(e) =>
                    setCurrentSeller({ ...currentSeller, phoneNumber: e.target.value })
                  }
                />
              </div>

              {/* Zip Code */}
              <div className="admin-all-sellers-form-group">
                <label htmlFor="zipCode">Zip Code:</label>
                <input
                  type="text"
                  id="zipCode"
                  value={currentSeller.zipCode || ""}
                  onChange={(e) =>
                    setCurrentSeller({ ...currentSeller, zipCode: e.target.value })
                  }
                />
              </div>

              {/* Lat/Long */}
              <div className="admin-all-sellers-form-group">
                <label htmlFor="latitude">Latitude:</label>
                <input
                  type="text"
                  id="latitude"
                  value={currentSeller.latitude || ""}
                  onChange={(e) =>
                    setCurrentSeller({ ...currentSeller, latitude: e.target.value })
                  }
                />
              </div>
              <div className="admin-all-sellers-form-group">
                <label htmlFor="longitude">Longitude:</label>
                <input
                  type="text"
                  id="longitude"
                  value={currentSeller.longitude || ""}
                  onChange={(e) =>
                    setCurrentSeller({ ...currentSeller, longitude: e.target.value })
                  }
                />
              </div>

              {/* Opening Day */}
              <div className="admin-all-sellers-form-group">
                <label htmlFor="day">Opening Day:</label>
                <select
                  id="day"
                  value={currentSeller.openingHours?.day || ""}
                  onChange={(e) =>
                    setCurrentSeller({
                      ...currentSeller,
                      openingHours: { ...currentSeller.openingHours, day: e.target.value },
                    })
                  }
                >
                  <option value="">Select a Day</option>
                  {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(
                    (day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    )
                  )}
                </select>
              </div>

              {/* Open/Close Time */}
              <div className="admin-all-sellers-form-group">
                <label htmlFor="openTime">Open Time:</label>
                <input
                  type="time"
                  id="openTime"
                  value={currentSeller.openingHours?.openTime || ""}
                  onChange={(e) =>
                    setCurrentSeller({
                      ...currentSeller,
                      openingHours: {
                        ...currentSeller.openingHours,
                        openTime: e.target.value,
                      },
                    })
                  }
                />
              </div>
              <div className="admin-all-sellers-form-group">
                <label htmlFor="closeTime">Close Time:</label>
                <input
                  type="time"
                  id="closeTime"
                  value={currentSeller.openingHours?.closeTime || ""}
                  onChange={(e) =>
                    setCurrentSeller({
                      ...currentSeller,
                      openingHours: {
                        ...currentSeller.openingHours,
                        closeTime: e.target.value,
                      },
                    })
                  }
                />
              </div>

              {/* Average Rating (readOnly) */}
              <div className="admin-all-sellers-form-group">
                <label htmlFor="averageRating">Average Rating:</label>
                <input
                  type="number"
                  id="averageRating"
                  value={currentSeller.averageRating || 0}
                  readOnly
                />
              </div>

              <button type="submit" className="admin-all-sellers-save-button">
                Save Changes
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllSellers;

