import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProduct } from "../../redux/actions/product";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import { Switch } from '@headlessui/react';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { fetchBrands, fetchBrandCatalogItems } from "../../redux/actions/brand";
import TagSelector from "../Tags/TagSelector";
import TaxCodeSelector from "./TaxCodeSelector";
import ImageCropperModal from "../Image/ImageCropperModal";
import Loader from '../Layout/Loader';
import Variants from './Variants';
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import StandardPackageSelector from "../Shipping/StandardPackageSelector";

const CreateProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const { brands } = useSelector((state) => state.brand);
  const catalogItems = useSelector((state) => state.brand.catalogItems);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Basic product fields
  const [brandTypeSelection, setBrandTypeSelection] = useState('custom'); 
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedCatalogItem, setSelectedCatalogItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [productType, setProductType] = useState(["retail"]);
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]); 
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [availableSubCategories, setAvailableSubCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();
  const [availableForPickup, setAvailableForPickup] = useState(false);
  const [availableForDelivery, setAvailableForDelivery] = useState(true);
  const [brand, setBrand] = useState("");
  const [SKU, setSKU] = useState("");
  const [lowInventoryThreshold, setLowInventoryThreshold] = useState();
  const [unitOfMeasurement, setUnitOfMeasurement] = useState("");
  const [barcode, setBarcode] = useState([]);
  const [barcodePreviews, setBarcodePreviews] = useState([]);
  const [testResults, setTestResults] = useState([]);
  const [testResultPreviews, setTestResultPreviews] = useState([]);
  const [nutritionInfoImage, setNutritionInfoImage] = useState([]);
  const [nutritionInfoImagePreviews, setNutritionInfoImagePreviews] = useState([]);
  const [isEdible, setIsEdible] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [videos, setVideos] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [barcodeString, setBarcodeString] = useState('');
  const [catalogImages, setCatalogImages] = useState([]);
  const [catalogVideos, setCatalogVideos] = useState([]);
  const [catalogBarcodes, setCatalogBarcodes] = useState([]);
  const [catalogTestResults, setCatalogTestResults] = useState([]);
  const [catalogNutritionInfoImages, setCatalogNutritionInfoImages] = useState([]);
  const [isOnSale, setIsOnSale] = useState(false);
  const [taxCode, setTaxCode] = useState("");

  // Dimensions & weight fields
  const [weight, setWeight] = useState(0);
  const [weightUnitOfMeasurement, setWeightUnitOfMeasurement] = useState("");
  const [dimensionUnitOfMeasurement, setDimensionUnitOfMeasurement] = useState("");
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  // For variable products (with variants)
  const [isVariableProduct, setIsVariableProduct] = useState(false);
  const [variants, setVariants] = useState([]);
  const productWeight = weight;
  const productDimensions = { length, width, height };

  // For controlling dimension selection mode (custom vs. standard package)
  const [useCustomDimensions, setUseCustomDimensions] = useState(false);

  // State for capturing the user’s selected standard package
  const [selectedStandardPackage, setSelectedStandardPackage] = useState(null);

  // Cropper UI logic
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [selectedImageForCrop, setSelectedImageForCrop] = useState(null);
  const [remainingImagesToCrop, setRemainingImagesToCrop] = useState([]);
  const [fieldNameBeingCropped, setFieldNameBeingCropped] = useState("");

  // Form error-handling
  const [formErrors, setFormErrors] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const refs = {
    name: useRef(null),
    description: useRef(null),
    category: useRef(null),
    subCategory: useRef(null),
    SKU: useRef(null),
    brand: useRef(null),
    productType: useRef(null),
    originalPrice: useRef(null),
    discountPrice: useRef(null),
    stock: useRef(null),
    lowInventoryThreshold: useRef(null),
    taxCode: useRef(null),
    weight: useRef(null),
    weightUnitOfMeasurement: useRef(null),
    length: useRef(null),
    width: useRef(null),
    height: useRef(null),
  };

  // =========================
  //    HELPER FUNCTIONS
  // =========================

  const getRef = (fieldName) => {
    return refs[fieldName];
  };

  function convertDimensionToInches(value, unit) {
    switch (unit) {
      case 'cm':
        return value / 2.54;   // 1 inch = 2.54 cm
      case 'm':
        return value * 39.37;  // 1 meter = ~39.37 inches
      case 'in':
      default:
        return value; // Already inches
    }
  }

  // Helper to convert weight to kg for comparison with maxWeight
  function convertWeightToKg(value, unit) {
    switch (unit) {
      case 'lbs':
        return value * 0.45359237;
      case 'g':
        return value / 1000;
      case 'kg':
      default:
        return value;
    }
  }

  // =========================
  //    FORM VALIDATION
  // =========================

  const validateForm = () => {
    const errors = {};

    // Basic required checks
    if (!name.trim()) {
      errors.name = "Product name is required.";
    }
    if (!description.trim()) {
      errors.description = "Product description is required.";
    }
    if (!category) {
      errors.category = "Category is required.";
    }
    if (availableSubCategories.length > 0 && !subCategory) {
      errors.subCategory = "Subcategory is required.";
    }
    if (!SKU.trim()) {
      errors.SKU = "SKU is required.";
    }
    if (!brand.trim()) {
      errors.brand = "Brand is required.";
    }
    if (productType.length === 0) {
      errors.productType = "At least one product type must be selected.";
    }
    if (!originalPrice || parseFloat(originalPrice) <= 0) {
      errors.originalPrice = "Original price must be greater than 0.";
    }
    if (isOnSale && (!discountPrice || parseFloat(discountPrice) <= 0)) {
      errors.discountPrice = "Discount price must be greater than 0.";
    }
    if (!stock || parseInt(stock) < 0) {
      errors.stock = "Stock must be 0 or more.";
    }
    if (!lowInventoryThreshold || parseInt(lowInventoryThreshold) < 0) {
      errors.lowInventoryThreshold = "Low inventory threshold must be 0 or more.";
    }
    if (!taxCode) {
      errors.taxCode = "Tax code is required.";
    }

    // Delivery constraints
    if (availableForDelivery) {
      if (!weight || parseFloat(weight) <= 0) {
        errors.weight = "Weight must be greater than 0.";
      }
      if (!weightUnitOfMeasurement) {
        errors.weightUnitOfMeasurement = "Weight unit of measurement is required.";
      }
      if (!length || parseFloat(length) <= 0) {
        errors.length = "Length must be greater than 0.";
      }
      if (!width || parseFloat(width) <= 0) {
        errors.width = "Width must be greater than 0.";
      }
      if (!height || parseFloat(height) <= 0) {
        errors.height = "Height must be greater than 0.";
      }
      if (!dimensionUnitOfMeasurement) {
        errors.dimensionUnitOfMeasurement = "Dimension unit of measurement is required.";
      }
    }

    // Dimension constraints if using custom dimensions
    if (availableForDelivery && useCustomDimensions) {
      const lengthInches = convertDimensionToInches(parseFloat(length), dimensionUnitOfMeasurement);
      const widthInches = convertDimensionToInches(parseFloat(width), dimensionUnitOfMeasurement);
      const heightInches = convertDimensionToInches(parseFloat(height), dimensionUnitOfMeasurement);
      const MAX_LENGTH_PLUS_GIRTH = 165; // example limit
      const lengthPlusGirth = lengthInches + 2 * (widthInches + heightInches);

      if (lengthPlusGirth > MAX_LENGTH_PLUS_GIRTH) {
        errors.dimensionConstraints = `Dimensions too large! Length + girth cannot exceed ${MAX_LENGTH_PLUS_GIRTH} inches. (Yours is ${lengthPlusGirth.toFixed(2)} inches)`;
      }
    }

    // If user is using a standard package, check if there's a maxWeight
    if (
      availableForDelivery &&
      !useCustomDimensions &&
      selectedStandardPackage?.maxWeight
    ) {
      const productWeightInKg = convertWeightToKg(parseFloat(weight), weightUnitOfMeasurement);
      if (productWeightInKg > selectedStandardPackage.maxWeight) {
        errors.weight = `This package has a weight limit of ${selectedStandardPackage.maxWeight} ${selectedStandardPackage.maxWeightUnit}. You entered ${productWeightInKg.toFixed(2)} kg.`;
      }
    }

    // Validate Variants if it's a variable product
    if (isVariableProduct) {
      if (variants.length === 0) {
        errors.variants = "At least one variant must be created.";
      } else {
        const variantErrors = variants
          .map((variant, index) => {
            const variantError = {};
            if (!variant.sku.trim()) {
              variantError.sku = "SKU is required for variant.";
            }
            if (!variant.stock || parseInt(variant.stock) < 0) {
              variantError.stock = "Stock must be 0 or more for variant.";
            }
            // Add more variant validations as needed
            return Object.keys(variantError).length > 0 ? variantError : null;
          })
          .filter((error) => error !== null);

        if (variantErrors.length > 0) {
          errors.variants = variantErrors;
        }
      }
    }

    return errors;
  };

  const handleVariantErrors = (variantErrors) => {
    if (variantErrors.length > 0) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        variants: variantErrors,
      }));
    } else {
      setFormErrors((prevErrors) => {
        const { variants, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  // ================================
  //    LIFECYCLE & ASYNC EFFECTS
  // ================================

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Product created successfully!");
      navigate(`/dashboard/${seller.handle}`);
      window.location.reload();
    }
  }, [dispatch, error, success]);

  useEffect(() => {
    if (brandTypeSelection === 'branded') {
      dispatch(fetchBrands());
    }
  }, [brandTypeSelection, dispatch]);

  useEffect(() => {
    if (selectedBrand) {
      dispatch(fetchBrandCatalogItems(selectedBrand));
    }
  }, [selectedBrand, dispatch]);

  // If user clicks a catalog item from brand’s data, load it up
  useEffect(() => {
    if (selectedCatalogItem) {
      setName(selectedCatalogItem.name);
      setDescription(selectedCatalogItem.description);
      setCategory(selectedCatalogItem.category);
      setSKU(selectedCatalogItem.SKU);
      setTags(selectedCatalogItem.tags);
      setProductType(selectedCatalogItem.productType);
      setWeightUnitOfMeasurement(selectedCatalogItem.weightUnitOfMeasurement);
      setDimensionUnitOfMeasurement(selectedCatalogItem.dimensionUnitOfMeasurement);
      setIsEdible(selectedCatalogItem.isEdible);
      setBrand(selectedCatalogItem.brand.name);
      setDiscountPrice(selectedCatalogItem.priceFloor);
      setTaxCode(selectedCatalogItem.taxCode);

      const imageUrls = selectedCatalogItem?.images.map((image) => image.url);
      setImagePreviews(imageUrls);
      setCatalogImages(selectedCatalogItem.images);

      const videoUrls = selectedCatalogItem?.videos.map((video) => video.url);
      setVideoPreviews(videoUrls);
      setCatalogVideos(selectedCatalogItem?.videos);

      const testResultUrls = selectedCatalogItem?.testResults.map((result) => result.url);
      setTestResultPreviews(testResultUrls);
      setCatalogTestResults(selectedCatalogItem?.testResults);

      const nutritionInfoImageUrls = selectedCatalogItem?.nutritionInfoImage.map((image) => image.url);
      setNutritionInfoImagePreviews(nutritionInfoImageUrls);
      setCatalogNutritionInfoImages(selectedCatalogItem?.nutritionInfoImage);

      const barcodeUrls = selectedCatalogItem?.barcodes.map((result) => result.url);
      setBarcodePreviews(barcodeUrls);
      setCatalogBarcodes(selectedCatalogItem?.barcodes);
    }
  }, [selectedCatalogItem]);

  // =========================
  //    EVENT HANDLERS
  // =========================

  const handleDiscountPriceChange = (newPrice) => {
    if (selectedCatalogItem) {
      const adjustedPrice = Math.max(newPrice, selectedCatalogItem.priceFloor);
      setDiscountPrice(adjustedPrice);
    } else {
      setDiscountPrice(newPrice);
    }
  };

  const handleImageChange = (e, fieldName) => {
    const newFiles = Array.from(e.target.files);
    if (newFiles.length > 0) {
      setRemainingImagesToCrop(newFiles);
      setSelectedImageForCrop(newFiles[0]);
      setFieldNameBeingCropped(fieldName);
      setShowImageCropper(true);
    }
  };

  const handleImageCropCompletion = (croppedImage) => {
    let updatedFiles = [];
    let updatedPreviews = [];

    switch (fieldNameBeingCropped) {
      case "images":
        updatedFiles = [...images, croppedImage];
        updatedPreviews = updatedFiles.map((file) => URL.createObjectURL(file));
        setImages(updatedFiles);
        setImagePreviews(updatedPreviews);
        break;

      case "barcode":
        updatedFiles = [...barcode, croppedImage];
        updatedPreviews = updatedFiles.map((file) => URL.createObjectURL(file));
        setBarcode(updatedFiles);
        setBarcodePreviews(updatedPreviews);
        break;

      case "testResults":
        updatedFiles = [...testResults, croppedImage];
        updatedPreviews = updatedFiles.map((file) => URL.createObjectURL(file));
        setTestResults(updatedFiles);
        setTestResultPreviews(updatedPreviews);
        break;

      case "nutritionInfoImage":
        updatedFiles = [...nutritionInfoImage, croppedImage];
        updatedPreviews = updatedFiles.map((file) => URL.createObjectURL(file));
        setNutritionInfoImage(updatedFiles);
        setNutritionInfoImagePreviews(updatedPreviews);
        break;

      default:
        return;
    }

    // Move to the next image in the queue
    const newQueue = [...remainingImagesToCrop];
    newQueue.shift(); // remove the just-cropped image from queue

    if (newQueue.length > 0) {
      setRemainingImagesToCrop(newQueue);
      setSelectedImageForCrop(newQueue[0]);
      setShowImageCropper(true);
    } else {
      setShowImageCropper(false);
      setSelectedImageForCrop(null);
      setFieldNameBeingCropped("");
    }
  };

  const handleImageDrop = (e, fieldName) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);

    const newFiles = Array.from(e.dataTransfer.files);
    if (newFiles.length > 0) {
      setRemainingImagesToCrop(newFiles);
      setSelectedImageForCrop(newFiles[0]);
      setFieldNameBeingCropped(fieldName);
      setShowImageCropper(true);
    }
  };

  const handleVideoChange = (e) => {
    const newFiles = Array.from(e.target.files);
    const updatedFiles = [...videos, ...newFiles];
    const updatedPreviews = updatedFiles.map((file) => URL.createObjectURL(file));
    setVideos(updatedFiles);
    setVideoPreviews(updatedPreviews);
  };

  const handleVideoDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);

    const newFiles = Array.from(e.dataTransfer.files).filter((file) =>
      file.type.startsWith("video/")
    );
    let updatedFiles = [...videos, ...newFiles];
    let updatedPreviews = updatedFiles.map((file) => URL.createObjectURL(file));
    setVideos(updatedFiles);
    setVideoPreviews(updatedPreviews);
  };

  const handleRemoveVideo = (index) => {
    const newVideos = videos.filter((_, i) => i !== index);
    const newPreviews = newVideos.map((file) => URL.createObjectURL(file));
    setVideos(newVideos);
    setVideoPreviews(newPreviews);
  };

  const handleRemoveImage = (index, files, setFiles, setPreviews) => {
    const newFiles = files.filter((_, i) => i !== index);
    const newPreviews = newFiles.map((file) => URL.createObjectURL(file));
    setFiles(newFiles);
    setPreviews(newPreviews);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newImages = Array.from(images);
    const newPreviews = Array.from(imagePreviews);

    const [removedImage] = newImages.splice(result.source.index, 1);
    const [removedPreview] = newPreviews.splice(result.source.index, 1);

    newImages.splice(result.destination.index, 0, removedImage);
    newPreviews.splice(result.destination.index, 0, removedPreview);

    setImages(newImages);
    setImagePreviews(newPreviews);
  };

  const handleProductTypeChange = (type) => {
    setProductType((prevTypes) =>
      prevTypes.includes(type)
        ? prevTypes.filter((t) => t !== type)
        : [...prevTypes, type]
    );
  };

  const handleBrandTypeSelectionChange = (type) => {
    setBrandTypeSelection(type);

    if (type === "custom") {
      setSelectedBrand("");
      setSelectedCatalogItem(null);
      setName("");
      setDescription("");
      setCategory("");
      setSubCategory("");
      setSKU("");
      setTags([]);
      setProductType("retail");
      setWeightUnitOfMeasurement("");
      setDimensionUnitOfMeasurement("");
      setIsEdible(false);
      setBrand("");
      setDiscountPrice("");
      setTaxCode("");
      setImagePreviews([]);
      setVideoPreviews([]);
      setTestResultPreviews([]);
      setNutritionInfoImagePreviews([]);
      setBarcodePreviews([]);
      setCatalogImages([]);
      setCatalogVideos([]);
      setCatalogTestResults([]);
      setCatalogNutritionInfoImages([]);
      setCatalogBarcodes([]);
    }
  };

  const preventScrollChange = (event) => {
    // Prevent mouse scroll from incrementing/decrementing input fields
    event.target.blur();
  };

  const updateTags = (newTags) => {
    setTags(newTags);
  };
  
  const updateTaxCode = (newTaxCode) => {
    setTaxCode(newTaxCode);
  };

  const handleProductTypeSwitch = () => {
    setIsVariableProduct(!isVariableProduct);
  };

  // ================================
  //    FORM SUBMIT LOGIC
  // ================================

  const newForm = new FormData();

  const handleSubmit = (e) => {
    e.preventDefault();
    setHasSubmitted(true);
    setIsLoading(true);

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsLoading(false);

      // Attempt to scroll to first error
      let firstErrorField = null;

      // Check main form errors
      for (const field of [
        "name",
        "description",
        "category",
        "subCategory",
        "SKU",
        "brand",
        "productType",
        "originalPrice",
        "discountPrice",
        "stock",
        "lowInventoryThreshold",
        "taxCode",
        "weight",
        "weightUnitOfMeasurement",
        "length",
        "width",
        "height",
      ]) {
        if (errors[field]) {
          firstErrorField = field;
          break;
        }
      }

      // If no main form errors, check variants
      if (!firstErrorField && errors.variants) {
        for (let i = 0; i < errors.variants.length; i++) {
          const variantError = errors.variants[i];
          for (const field in variantError) {
            firstErrorField = `variants.${i}.${field}`;
            break;
          }
          if (firstErrorField) break;
        }
      }

      // Scroll to the first error field
      if (firstErrorField) {
        if (firstErrorField.startsWith("variants")) {
          const [_, index, field] = firstErrorField.split(".");
          const variantField = document.querySelector(
            `[data-variant-index="${index}"][name="${field}"]`
          );
          if (variantField) {
            variantField.scrollIntoView({ behavior: "smooth", block: "center" });
            variantField.focus();
          }
        } else {
          const ref = getRef(firstErrorField);
          if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
            ref.current.focus();
          }
        }
      }
      return; 
    }

    // Build the form data
    newForm.append(
      "variants",
      JSON.stringify(
        variants.map((variant) => {
          const finalPrice = variant.price || originalPrice;
          return {
            sku: variant.sku,
            price: finalPrice,
            stock: variant.stock,
            weight: variant.weight || weight,
            weightUnitOfMeasurement: variant.weightUnitOfMeasurement || weightUnitOfMeasurement,
            dimensions: variant.dimensions || productDimensions,
            dimensionUnitOfMeasurement:
              variant.dimensionUnitOfMeasurement || dimensionUnitOfMeasurement,
            description: variant.description,
            color: variant.color || "",
            colorSwatch: variant.colorSwatch || "",
            size: variant.size,
            customTitle: variant.customTitle || "",
            customValue: variant.customValue || "",
          };
        })
      )
    );

    // Attach variant images
    let generalImageAppended = false;
    variants.forEach((variant, index) => {
      if (variant?.image && variant?.image?.file) {
        newForm.append(`variant_${index}_image`, variant.image.file);
      } else if (images.length > 0 && !generalImageAppended) {
        // Fallback: attach first general image to variant
        newForm.append(`variant_${index}_image`, images[0]);
        generalImageAppended = true;
      }
    });

    // Attach main images
    images.forEach((image) => newForm.append("images", image));
    newForm.append("catalogImages", JSON.stringify(catalogImages));

    // Barcode images
    barcode.forEach((code) => newForm.append("barcode", code));
    newForm.append("catalogBarcodes", JSON.stringify(catalogBarcodes));

    // Test result images
    testResults.forEach((result) => newForm.append("testResults", result));
    newForm.append("catalogTestResults", JSON.stringify(catalogTestResults));

    // Nutrition info images
    nutritionInfoImage.forEach((img) => newForm.append("nutritionInfoImage", img));
    newForm.append("catalogNutritionInfoImages", JSON.stringify(catalogNutritionInfoImages));

    // Product videos
    videos.forEach((video) => newForm.append("videos", video));
    newForm.append("catalogVideos", JSON.stringify(catalogVideos));

    // Basic fields
    newForm.append("name", name);
    newForm.append("brandTypeSelection", brandTypeSelection);
    newForm.append("description", description);
    newForm.append("category", category);
    newForm.append("subCategory", subCategory);
    newForm.append("tags", JSON.stringify(tags));
    newForm.append("originalPrice", originalPrice);
    newForm.append("discountPrice", discountPrice);
    newForm.append("stock", stock);
    newForm.append("brand", brand);
    newForm.append("shopId", seller._id);
    newForm.append("handle", seller.handle);
    newForm.append("productType", JSON.stringify(productType));
    newForm.append("availableForPickup", availableForPickup);
    newForm.append("availableForDelivery", availableForDelivery);
    newForm.append("SKU", SKU);
    newForm.append("lowInventoryThreshold", lowInventoryThreshold);
    newForm.append("weightUnitOfMeasurement", weightUnitOfMeasurement);
    newForm.append("dimensionUnitOfMeasurement", dimensionUnitOfMeasurement);
    newForm.append("isEdible", isEdible);
    newForm.append("barcodeString", barcodeString);
    newForm.append("isOnSale", isOnSale);
    newForm.append("taxCode", JSON.stringify(taxCode));

    // Dimensions
    newForm.append("weight", weight);
    newForm.append("dimensions", JSON.stringify({ length, width, height }));

    // Submit
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        "current-profile": seller.name || "defaultProfile",
      },
      withCredentials: true,
    };

    dispatch(createProduct(newForm, config)).finally(() => setIsLoading(false));
  };

  // =========================
  //    RENDER
  // =========================

  return (
    <div className="create-product-component-wrapper">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          

          {/* BRAND TYPE */}
          <div className="create-product-component-section">
            <fieldset className="create-product-component-radio-group">
              <legend className="sr-only">Brand Type</legend>
              <div className="create-product-component-form-label">
                Brand Type <span className="create-product-component-form-required">*</span>
                <a
                  href="/docs/brand-creation"
                  target="_blank"
                  className="help-icon-link"
                  aria-label="Help about Brand"
                >
                  <QuestionMarkCircleIcon className="help-icon" />
                  <span className="help-text">Help</span>
                </a>
              </div>
              <div className="create-product-component-brand-type-container">
                {[
                  { name: "Custom", value: "custom", description: "A custom brand type for your unique products." },
                  { name: "Branded", value: "branded", description: "A branded product that follows an established brand." },
                ].map((brandType) => (
                  <label
                    key={brandType.value}
                    className={`create-product-component-radio-item ${
                      brandTypeSelection === brandType.value ? "checked" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name="brandType"
                      value={brandType.value}
                      checked={brandTypeSelection === brandType.value}
                      onChange={() => handleBrandTypeSelectionChange(brandType.value)}
                      className="create-product-component-radio-input"
                    />
                    <span className="create-product-component-radio-circle" />
                    <span className="create-product-component-radio-label-container">
                      <span className="create-product-component-radio-label">{brandType.name}</span>
                      <span className="create-product-component-radio-description">
                        {brandType.description}
                      </span>
                    </span>
                  </label>
                ))}
              </div>
            </fieldset>
          </div>

          {/* IF BRANDED, SHOW BRAND SELECT + CATALOG */}
          {brandTypeSelection === "branded" && (
            <select
              value={selectedBrand}
              onChange={(e) => setSelectedBrand(e.target.value)}
              className="create-product-component-select"
            >
              <option value="">Select a Brand</option>
              {brands.map((brand) => (
                <option key={brand._id} value={brand._id}>
                  {brand.name}
                </option>
              ))}
            </select>
          )}

          <div className="create-product-component-catalog-items">
            {selectedBrand &&
              catalogItems.map((item) => (
                <div
                  key={item._id}
                  className="create-product-component-catalog-item"
                  onClick={() => setSelectedCatalogItem(item)}
                >
                  <div className="create-product-component-catalog-item-image-container">
                    {item.images.length > 0 && (
                      <img
                        src={item.images[0].url}
                        alt={item.name}
                        className="create-product-component-catalog-item-image"
                      />
                    )}
                  </div>
                  <div className="create-product-component-catalog-item-info">
                    <h3 className="create-product-component-catalog-item-name">{item.name}</h3>
                    <p className="create-product-component-catalog-item-category">{item.category}</p>
                    <p className="create-product-component-catalog-item-price">${item.priceFloor}</p>
                  </div>
                </div>
              ))}
          </div>

          {/* MAIN FORM */}
          <form onSubmit={handleSubmit} className="create-product-component-form" noValidate>
            {/* NAME */}
            <div className="create-product-component-form-section">
              <div className="create-product-component-form-group">
                <label htmlFor="name" className="create-product-component-form-label">
                  Name <span className="create-product-component-form-required">*</span>
                </label>
                <input
                  ref={refs.name}
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                  className="create-product-component-form-input"
                  placeholder="Enter your product name..."
                />
                {formErrors.name && <p className="form-error-message">{formErrors.name}</p>}
              </div>
            </div>

            {/* DESCRIPTION */}
            <div className="create-product-component-form-section">
              <label htmlFor="description" className="create-product-component-form-label">
                Description <span className="create-product-component-form-required">*</span>
              </label>
              <textarea
                ref={refs.description}
                id="description"
                name="description"
                rows={4}
                required
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="create-product-component-form-textarea"
                placeholder="Enter your product description..."
              />
              {formErrors.description && <p className="form-error-message">{formErrors.description}</p>}
            </div>

            {/* PRODUCT IMAGES */}
            <div className="create-product-component-upload-section">
              <label htmlFor="upload" className="create-product-component-upload-label">
                Upload Product Images
              </label>
              <div
                className={`create-product-component-dropzone ${
                  isDragOver ? "create-product-component-dropzone-active" : ""
                }`}
                onDragEnter={() => setIsDragOver(true)}
                onDragLeave={() => setIsDragOver(false)}
                onDragOver={(e) => {
                  e.preventDefault();
                  setIsDragOver(true);
                }}
                onDrop={(e) => handleImageDrop(e, "images")}
              >
                <div className="create-product-component-dropzone-content">
                  <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
                  <label htmlFor="upload" className="create-product-component-dropzone-label">
                    <span>Drag and drop your images here or click to upload</span>
                    <input
                      type="file"
                      id="upload"
                      multiple
                      onChange={(e) => handleImageChange(e, "images")}
                      className="sr-only"
                    />
                  </label>
                  <p className="create-product-component-dropzone-note">PNG, JPG, GIF up to 10MB</p>
                </div>
              </div>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppableImages" direction="horizontal">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="create-product-component-image-previews"
                  >
                    {imagePreviews.map((imageUrl, index) => (
                      <Draggable key={index} draggableId={`image-${index}`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`create-product-component-image-preview ${
                              snapshot.isDragging
                                ? "create-product-component-image-preview-dragging"
                                : ""
                            }`}
                            style={{
                              ...provided.draggableProps.style,
                              height: index === 0 ? "216px" : "144px",
                              width: index === 0 ? "216px" : "144px",
                            }}
                          >
                            <img
                              src={imageUrl}
                              alt={`Uploaded ${index}`}
                              className="create-product-component-image-preview-img"
                            />
                            <div className="create-product-component-image-preview-badge">
                              {index + 1}
                            </div>
                            <button
                              onClick={() =>
                                handleRemoveImage(index, images, setImages, setImagePreviews)
                              }
                              className="create-product-component-image-preview-remove"
                              aria-label="Remove image"
                            >
                              &times;
                            </button>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {/* PRODUCT VIDEOS */}
            <div className="create-product-component-upload-section">
              <label htmlFor="video-upload" className="create-product-component-upload-label">
                Upload A Product Video
              </label>
              <div
                className={`create-product-component-dropzone ${
                  isDragOver ? "create-product-component-dropzone-active" : ""
                }`}
                onDragEnter={() => setIsDragOver(true)}
                onDragLeave={() => setIsDragOver(false)}
                onDragOver={(e) => {
                  e.preventDefault();
                  setIsDragOver(true);
                }}
                onDrop={handleVideoDrop}
              >
                <div className="create-product-component-dropzone-content">
                  <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
                  <label htmlFor="video-upload" className="create-product-component-dropzone-label">
                    <span>Drag and drop your videos here or click to upload</span>
                    <input
                      type="file"
                      id="video-upload"
                      multiple
                      onChange={handleVideoChange}
                      className="sr-only"
                    />
                  </label>
                  <p className="create-product-component-dropzone-note">MP4 up to 50MB</p>
                </div>
              </div>
              <div className="create-product-component-video-previews">
                {videoPreviews.map((videoUrl, index) => (
                  <div key={index} className="create-product-component-video-preview">
                    <video
                      src={videoUrl}
                      alt="Video Preview"
                      className="create-product-component-video-preview-video"
                      controls
                    />
                    <button
                      onClick={() => handleRemoveVideo(index)}
                      className="create-product-component-video-preview-remove"
                      aria-label="Remove video"
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* PRODUCT TYPE SWITCH (Retail / Wholesale) */}
            <div className="create-product-component-form-section">
              <label className="create-product-component-form-label">
                Product Type <span className="create-product-component-form-required">*</span>
              </label>
              <div className="create-product-component-product-type-section">
                {/* Retail Switch */}
                <Switch.Group
                  as="div"
                  className={`create-product-component-switch-group ${
                    productType.includes("retail") ? "create-product-component-switch-group-active" : ""
                  }`}
                >
                  <span className="create-product-component-switch-label">Retail</span>
                  <Switch
                    checked={productType.includes("retail")}
                    onChange={() => handleProductTypeChange("retail")}
                    className={`create-product-component-switch ${
                      productType.includes("retail")
                        ? "create-product-component-switch-active"
                        : "create-product-component-switch-inactive"
                    }`}
                  >
                    <span
                      className={`create-product-component-switch-toggle ${
                        productType.includes("retail")
                          ? "create-product-component-switch-toggle-active"
                          : "create-product-component-switch-toggle-inactive"
                      }`}
                    />
                  </Switch>
                </Switch.Group>

                {/* Wholesale Switch */}
                <Switch.Group
                  as="div"
                  className={`create-product-component-switch-group ${
                    productType.includes("wholesale") ? "create-product-component-switch-group-active" : ""
                  }`}
                >
                  <span className="create-product-component-switch-label">Wholesale</span>
                  <Switch
                    checked={productType.includes("wholesale")}
                    onChange={() => handleProductTypeChange("wholesale")}
                    className={`create-product-component-switch ${
                      productType.includes("wholesale")
                        ? "create-product-component-switch-active"
                        : "create-product-component-switch-inactive"
                    }`}
                  >
                    <span
                      className={`create-product-component-switch-toggle ${
                        productType.includes("wholesale")
                          ? "create-product-component-switch-toggle-active"
                          : "create-product-component-switch-toggle-inactive"
                      }`}
                    />
                  </Switch>
                </Switch.Group>
              </div>
              {formErrors.productType && (
                <p className="form-error-message" role="alert">
                  {formErrors.productType}
                </p>
              )}
            </div>

            {/* SKU, BRAND FIELDS */}
            <div className="create-product-component-form-section create-product-component-form-group-row">
              {!isVariableProduct && (
                <div className="create-product-component-form-group">
                  <label htmlFor="sku" className="create-product-component-form-label">
                    SKU <span className="create-product-component-form-required">*</span>
                    <a
                      href="/docs/what-is-a-sku"
                      target="_blank"
                      className="help-icon-link"
                      aria-label="Help about Brand"
                    >
                      <QuestionMarkCircleIcon className="help-icon" />
                      <span className="help-text">Help</span>
                    </a>
                  </label>
                  <input
                    type="text"
                    name="sku"
                    id="sku"
                    value={SKU}
                    onChange={(e) => setSKU(e.target.value)}
                    className="create-product-component-form-input"
                    placeholder="Enter SKU..."
                  />
                  {formErrors.SKU && <p className="form-error-message">{formErrors.SKU}</p>}
                </div>
              )}

              <div className="create-product-component-form-group">
                <label htmlFor="brand" className="create-product-component-form-label">
                  Brand <span className="create-product-component-form-required">*</span>
                </label>
                <input
                  ref={refs.brand}
                  type="text"
                  name="brand"
                  id="brand"
                  value={brand}
                  required
                  onChange={(e) => setBrand(e.target.value)}
                  className="create-product-component-form-input"
                  placeholder="Enter your product brand..."
                />
                {formErrors.brand && <p className="form-error-message">{formErrors.brand}</p>}
              </div>
            </div>

            {/* CATEGORY / SUBCATEGORY */}
            <div className="create-product-component-form-section">
              <label htmlFor="category" className="create-product-component-form-label">
                Category <span className="create-product-component-form-required">*</span>
              </label>
              <select
                ref={refs.category}
                id="category"
                name="category"
                value={category}
                required
                onChange={(e) => {
                  const selectedCategory = e.target.value;
                  setCategory(selectedCategory);
                  setSubCategory("");
                  const categoryObj = categoriesData.find((cat) => cat.title === selectedCategory);
                  if (categoryObj && categoryObj.subCategories) {
                    setAvailableSubCategories(categoryObj.subCategories);
                  } else {
                    setAvailableSubCategories([]);
                  }
                }}
                className="create-product-component-select"
              >
                <option value="">Choose a category</option>
                {categoriesData.map((cat, index) => (
                  <option key={index} value={cat.title}>
                    {cat.title}
                  </option>
                ))}
              </select>
              {formErrors.category && (
                <p className="form-error-message" role="alert">
                  {formErrors.category}
                </p>
              )}
            </div>

            {availableSubCategories.length > 0 && (
              <div className="create-product-component-form-section">
                <label htmlFor="sub-category" className="create-product-component-form-label">
                  Subcategory <span className="create-product-component-form-required">*</span>
                </label>
                <select
                  ref={refs.subCategory}
                  id="sub-category"
                  name="sub-category"
                  value={subCategory}
                  required
                  onChange={(e) => setSubCategory(e.target.value)}
                  className="create-product-component-select"
                >
                  <option value="">Choose a subcategory</option>
                  {availableSubCategories.map((subCat) => (
                    <option key={subCat.id} value={subCat.title}>
                      {subCat.title}
                    </option>
                  ))}
                </select>
                {formErrors.subCategory && (
                  <p className="form-error-message" role="alert">
                    {formErrors.subCategory}
                  </p>
                )}
              </div>
            )}

            {/* ON SALE SWITCH */}
            <div className="create-product-component-form-section">
              <Switch.Group
                as="div"
                className={`create-product-component-switch-group ${
                  isOnSale ? "create-product-component-switch-group-active" : ""
                }`}
              >
                <span className="create-product-component-switch-label">On Sale</span>
                <Switch
                  checked={isOnSale}
                  onChange={setIsOnSale}
                  className={`${
                    isOnSale ? "create-product-component-switch-active" : "create-product-component-switch-inactive"
                  } create-product-component-switch`}
                >
                  <span
                    className={`${
                      isOnSale
                        ? "create-product-component-switch-toggle-active"
                        : "create-product-component-switch-toggle-inactive"
                    } create-product-component-switch-toggle`}
                  />
                </Switch>
              </Switch.Group>
            </div>

            {/* PRICING */}
            <div className="create-product-component-form-section create-product-component-form-group-row">
              <div className="create-product-component-form-group">
                <label htmlFor="original-price" className="create-product-component-form-label">
                  {isOnSale ? "Original Price" : "Price"}{" "}
                  <span className="create-product-component-form-required">*</span>
                </label>
                <input
                  type="number"
                  name="original-price"
                  id="original-price"
                  value={originalPrice}
                  onChange={(e) => {
                    setOriginalPrice(e.target.value);
                    if (!isOnSale) {
                      setDiscountPrice(e.target.value);
                    }
                  }}
                  onWheel={preventScrollChange}
                  className="create-product-component-form-input"
                  placeholder={isOnSale ? "Enter the original price..." : "Enter the price..."}
                />
              </div>
              {isOnSale && (
                <div className="create-product-component-form-group">
                  <label htmlFor="discount-price" className="create-product-component-form-label">
                    Discount Price
                  </label>
                  <input
                    type="number"
                    name="discount-price"
                    id="discount-price"
                    value={discountPrice}
                    onChange={(e) => handleDiscountPriceChange(e.target.value)}
                    onWheel={preventScrollChange}
                    className="create-product-component-form-input"
                    placeholder="Enter the discounted price..."
                  />
                </div>
              )}
            </div>

            {/* STOCK / LOW INVENTORY */}
            <div className="create-product-component-form-section create-product-component-form-group-row">
              <div className="create-product-component-form-group">
                <label htmlFor="stock" className="create-product-component-form-label">
                  Stock <span className="create-product-component-form-required">*</span>
                  <a
                    href="/docs/inventory"
                    target="_blank"
                    className="help-icon-link"
                    aria-label="Help about Brand"
                  >
                    <QuestionMarkCircleIcon className="help-icon" />
                    <span className="help-text">Help</span>
                  </a>
                </label>
                <input
                  type="number"
                  name="stock"
                  id="stock"
                  value={stock}
                  onChange={(e) => setStock(e.target.value)}
                  onWheel={preventScrollChange}
                  className="create-product-component-form-input"
                  placeholder="Enter the stock amount..."
                />
                {formErrors.stock && (
                  <p className="form-error-message" role="alert">
                    {formErrors.stock}
                  </p>
                )}
              </div>
              <div className="create-product-component-form-group">
                <label
                  htmlFor="low-inventory-threshold"
                  className="create-product-component-form-label"
                >
                  Low Inventory Threshold
                  <a
                    href="/docs/inventory"
                    target="_blank"
                    className="help-icon-link"
                    aria-label="Help about Brand"
                  >
                    <QuestionMarkCircleIcon className="help-icon" />
                    <span className="help-text">Help</span>
                  </a>
                </label>
                <input
                  type="number"
                  name="low-inventory-threshold"
                  id="low-inventory-threshold"
                  value={lowInventoryThreshold}
                  onChange={(e) => setLowInventoryThreshold(e.target.value)}
                  onWheel={preventScrollChange}
                  className="create-product-component-form-input"
                  placeholder="Set the low inventory threshold..."
                />
                {formErrors.lowInventoryThreshold && (
                  <p className="form-error-message" role="alert">
                    {formErrors.lowInventoryThreshold}
                  </p>
                )}
              </div>
            </div>

            {/* TAX CODE */}
            <div ref={refs.taxCode} className="create-product-component-form-section">
              <TaxCodeSelector
                selectedTaxCode={taxCode}
                updateTaxCode={updateTaxCode}
                productCategory={category}
                productSubCategory={subCategory}
                productName={name}
                productDescription={description}
                productCategories={categoriesData.map((cat) => cat.title)}
              />
              {formErrors.taxCode && (
                <p className="form-error-message" role="alert">
                  {formErrors.taxCode}
                </p>
              )}
            </div>

            {/* TAGS */}
            <div className="create-product-component-form-section">
              <label htmlFor="tagSelector" className="tag-selector-label">
                Tag Selector
                <a href="/docs/tags" target="_blank" className="help-icon-link" aria-label="Help about Brand">
                  <QuestionMarkCircleIcon className="help-icon" />
                  <span className="help-text">Help</span>
                </a>
              </label>
              <TagSelector selectedTags={tags} updateTags={updateTags} />
            </div>

            {/* PICKUP / DELIVERY SWITCHES */}
            <div className="create-product-component-form-section create-product-component-form-group-row">
              <div
                className={`create-product-component-switch-group ${
                  availableForPickup ? "create-product-component-switch-group-active" : ""
                }`}
              >
                <label className="create-product-component-switch-label">Available for Pickup</label>
                <Switch
                  checked={availableForPickup}
                  onChange={setAvailableForPickup}
                  className={`create-product-component-switch ${
                    availableForPickup
                      ? "create-product-component-switch-active"
                      : "create-product-component-switch-inactive"
                  }`}
                >
                  <span
                    className={`create-product-component-switch-toggle ${
                      availableForPickup
                        ? "create-product-component-switch-toggle-active"
                        : "create-product-component-switch-toggle-inactive"
                    }`}
                  />
                </Switch>
              </div>

              <div
                className={`create-product-component-switch-group ${
                  availableForDelivery ? "create-product-component-switch-group-active" : ""
                }`}
              >
                <label className="create-product-component-switch-label">Available for Delivery</label>
                <Switch
                  checked={availableForDelivery}
                  onChange={setAvailableForDelivery}
                  className={`create-product-component-switch ${
                    availableForDelivery
                      ? "create-product-component-switch-active"
                      : "create-product-component-switch-inactive"
                  }`}
                >
                  <span
                    className={`create-product-component-switch-toggle ${
                      availableForDelivery
                        ? "create-product-component-switch-toggle-active"
                        : "create-product-component-switch-toggle-inactive"
                    }`}
                  />
                </Switch>
              </div>
            </div>

            {/* DELIVERY-RELATED FIELDS */}
            {availableForDelivery && (
              <>
                {/* Weight Unit */}
                <div className="create-product-component-form-section">
                  <label
                    htmlFor="weight-unit-of-measurement"
                    className="create-product-component-form-label"
                  >
                    Weight Unit of Measurement <span className="tax-code-selector-required">*</span>
                  </label>
                  <select
                    ref={refs.weightUnitOfMeasurement}
                    id="weight-unit-of-measurement"
                    name="weight-unit-of-measurement"
                    value={weightUnitOfMeasurement}
                    onChange={(e) => setWeightUnitOfMeasurement(e.target.value)}
                    className="create-product-component-select"
                  >
                    <option value="">Select Weight Unit</option>
                    <option value="g">Grams (g)</option>
                    <option value="lbs">Pounds (lbs)</option>
                    <option value="kg">Kilograms (kg)</option>
                  </select>
                  {formErrors.weightUnitOfMeasurement && (
                    <p className="form-error-message" role="alert">
                      {formErrors.weightUnitOfMeasurement}
                    </p>
                  )}
                </div>

                {/* Weight */}
                <div className="create-product-component-form-section">
                  <label htmlFor="weight" className="create-product-component-form-label">
                    Weight <span className="create-product-component-form-required">*</span>
                  </label>
                  <input
                    ref={refs.weight}
                    type="number"
                    name="weight"
                    id="weight"
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                    onWheel={preventScrollChange}
                    className="create-product-component-form-input"
                    placeholder="Enter the product weight..."
                    required
                  />
                  {formErrors.weight && (
                    <p className="form-error-message" role="alert">
                      {formErrors.weight}
                    </p>
                  )}
                </div>

                {/* SWITCH FOR CUSTOM DIMENSIONS */}
                <div className="create-product-component-form-section">
                  <Switch.Group
                    as="div"
                    className={`create-product-component-switch-group ${
                      useCustomDimensions ? "create-product-component-switch-group-active" : ""
                    }`}
                  >
                    <span className="create-product-component-switch-label">Use Custom Dimensions</span>
                    <Switch
                      checked={useCustomDimensions}
                      onChange={setUseCustomDimensions}
                      className={`create-product-component-switch ${
                        useCustomDimensions
                          ? "create-product-component-switch-active"
                          : "create-product-component-switch-inactive"
                      }`}
                    >
                      <span
                        className={`create-product-component-switch-toggle ${
                          useCustomDimensions
                            ? "create-product-component-switch-toggle-active"
                            : "create-product-component-switch-toggle-inactive"
                        }`}
                      />
                    </Switch>
                  </Switch.Group>
                </div>

                {useCustomDimensions ? (
                  <>
                    {/* Dimension Unit */}
                    <div className="create-product-component-form-section">
                      <label htmlFor="dimension-unit-of-measurement" className="create-product-component-form-label">
                        Dimension Unit of Measurement
                      </label>
                      <select
                        id="dimension-unit-of-measurement"
                        name="dimension-unit-of-measurement"
                        value={dimensionUnitOfMeasurement}
                        onChange={(e) => setDimensionUnitOfMeasurement(e.target.value)}
                        onWheel={preventScrollChange}
                        className="create-product-component-select"
                      >
                        <option value="">Select Dimension Unit</option>
                        <option value="cm">Centimeters (cm)</option>
                        <option value="in">Inches (in)</option>
                        <option value="m">Meters (m)</option>
                      </select>
                      {formErrors.dimensionUnitOfMeasurement && (
                        <p className="form-error-message" role="alert">
                          {formErrors.dimensionUnitOfMeasurement}
                        </p>
                      )}
                    </div>

                    {/* Dimensions */}
                    <div className="create-product-component-measurement-section">
                      <div className="create-product-component-form-section">
                        <label htmlFor="length" className="create-product-component-form-label">
                          Length <span className="create-product-component-form-required">*</span>
                        </label>
                        <input
                          type="number"
                          name="length"
                          id="length"
                          value={length}
                          onChange={(e) => setLength(e.target.value)}
                          onWheel={preventScrollChange}
                          className="create-product-component-form-input"
                          placeholder="Enter the product length..."
                          required
                        />
                        {formErrors.length && (
                          <p className="form-error-message" role="alert">
                            {formErrors.length}
                          </p>
                        )}
                      </div>
                      <div className="create-product-component-form-section">
                        <label htmlFor="width" className="create-product-component-form-label">
                          Width <span className="create-product-component-form-required">*</span>
                        </label>
                        <input
                          type="number"
                          name="width"
                          id="width"
                          value={width}
                          onChange={(e) => setWidth(e.target.value)}
                          onWheel={preventScrollChange}
                          className="create-product-component-form-input"
                          placeholder="Enter the product width..."
                          required
                        />
                        {formErrors.width && (
                          <p className="form-error-message" role="alert">
                            {formErrors.width}
                          </p>
                        )}
                      </div>
                      <div className="create-product-component-form-section">
                        <label htmlFor="height" className="create-product-component-form-label">
                          Height <span className="create-product-component-form-required">*</span>
                        </label>
                        <input
                          type="number"
                          name="height"
                          id="height"
                          value={height}
                          onChange={(e) => setHeight(e.target.value)}
                          onWheel={preventScrollChange}
                          className="create-product-component-form-input"
                          placeholder="Enter the product height..."
                          required
                        />
                        {formErrors.height && (
                          <p className="form-error-message" role="alert">
                            {formErrors.height}
                          </p>
                        )}
                      </div>
                    </div>

                    {formErrors.dimensionConstraints && (
                      <p className="form-error-message" role="alert">
                        {formErrors.dimensionConstraints}
                      </p>
                    )}
                  </>
                ) : (
                  <StandardPackageSelector
                    carriers={seller?.carriers}
                    setLength={setLength}
                    setWidth={setWidth}
                    setHeight={setHeight}
                    setDimensionUnitOfMeasurement={setDimensionUnitOfMeasurement}
                    onPackageSelected={(pkg) => setSelectedStandardPackage(pkg)}
                  />
                )}
              </>
            )}

            {/* BARCODE STRING */}
            <div className="create-product-component-form-section">
              <label htmlFor="barcode-string" className="create-product-component-form-label">
                Barcode
              </label>
              <input
                type="text"
                id="barcode-string"
                value={barcodeString}
                onChange={(e) => setBarcodeString(e.target.value)}
                className="create-product-component-form-input"
                placeholder="Enter barcode..."
              />
            </div>

            {/* BARCODE IMAGES */}
            <div className="create-product-component-upload-section">
              <label htmlFor="barcode-upload" className="create-product-component-upload-label">
                Upload Barcode
              </label>
              <div
                className={`create-product-component-dropzone ${
                  isDragOver ? "create-product-component-dropzone-active" : ""
                }`}
                onDragEnter={() => setIsDragOver(true)}
                onDragLeave={() => setIsDragOver(false)}
                onDragOver={(e) => {
                  e.preventDefault();
                  setIsDragOver(true);
                }}
                onDrop={(e) => handleImageDrop(e, "barcode")}
              >
                <div className="create-product-component-dropzone-content">
                  <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
                  <label htmlFor="barcode-upload" className="create-product-component-dropzone-label">
                    <span>Drag and drop your barcodes here or click to upload</span>
                    <input
                      type="file"
                      id="barcode-upload"
                      multiple
                      onChange={(e) => handleImageChange(e, "barcode")}
                      className="sr-only"
                    />
                  </label>
                  <p className="create-product-component-dropzone-note">PNG, JPG, GIF up to 10MB</p>
                </div>
              </div>
              <div className="create-product-component-barcode-previews">
                {barcodePreviews.map((barcodeUrl, index) => (
                  <div key={index} className="create-product-component-barcode-preview">
                    <img src={barcodeUrl} alt="Barcode" className="create-product-component-barcode-preview-img" />
                    <button
                      onClick={() => handleRemoveImage(index, barcode, setBarcode, setBarcodePreviews)}
                      className="create-product-component-barcode-preview-remove"
                      aria-label="Remove barcode"
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* IS EDIBLE SWITCH */}
            <div
              className={`create-product-component-switch-group ${
                isEdible ? "create-product-component-switch-group-active" : ""
              }`}
            >
              <label className="create-product-component-switch-label">Is Edible</label>
              <Switch
                checked={isEdible}
                onChange={setIsEdible}
                className={`create-product-component-switch ${
                  isEdible ? "create-product-component-switch-active" : "create-product-component-switch-inactive"
                }`}
              >
                <span
                  className={`create-product-component-switch-toggle ${
                    isEdible
                      ? "create-product-component-switch-toggle-active"
                      : "create-product-component-switch-toggle-inactive"
                  }`}
                />
              </Switch>
            </div>

            {/* NUTRITION INFO UPLOAD IF EDIBLE */}
            {isEdible && (
              <div className="create-product-component-upload-section">
                <label htmlFor="nutrition-info-upload" className="create-product-component-upload-label">
                  Upload Nutrition Info
                </label>
                <div
                  className={`create-product-component-dropzone ${
                    isDragOver ? "create-product-component-dropzone-active" : ""
                  }`}
                  onDragEnter={() => setIsDragOver(true)}
                  onDragLeave={() => setIsDragOver(false)}
                  onDragOver={(e) => {
                    e.preventDefault();
                    setIsDragOver(true);
                  }}
                  onDrop={(e) => handleImageDrop(e, "nutritionInfoImage")}
                >
                  <div className="create-product-component-dropzone-content">
                    <PhotoIcon className="create-product-component-dropzone-icon" aria-hidden="true" />
                    <label
                      htmlFor="nutrition-info-upload"
                      className="create-product-component-dropzone-label"
                    >
                      <span>Drag and drop nutrition info here or click to upload</span>
                      <input
                        type="file"
                        id="nutrition-info-upload"
                        multiple
                        onChange={(e) => handleImageChange(e, "nutritionInfoImage")}
                        className="sr-only"
                      />
                    </label>
                    <p className="create-product-component-dropzone-note">PNG, JPG, GIF up to 10MB</p>
                  </div>
                </div>
                <div className="create-product-component-nutrition-info-previews">
                  {nutritionInfoImagePreviews.map((nutritionInfoUrl, index) => (
                    <div key={index} className="create-product-component-nutrition-info-preview">
                      <img
                        src={nutritionInfoUrl}
                        alt="Nutrition Info"
                        className="create-product-component-nutrition-info-preview-img"
                      />
                      <button
                        onClick={() =>
                          handleRemoveImage(index, nutritionInfoImage, setNutritionInfoImage, setNutritionInfoImagePreviews)
                        }
                        className="create-product-component-nutrition-info-preview-remove"
                        aria-label="Remove nutrition info"
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* VARIABLE PRODUCT SWITCH */}
            <Switch.Group as="div" className="create-product-component-switch-group">
              <span className="create-product-component-switch-label">
                Variable Product
                <a
                  href="/docs/product-variants"
                  target="_blank"
                  className="help-icon-link"
                  aria-label="Help about Brand"
                >
                  <QuestionMarkCircleIcon className="help-icon" />
                  <span className="help-text">Help</span>
                </a>
              </span>
              <Switch
                checked={isVariableProduct}
                onChange={handleProductTypeSwitch}
                className={`create-product-component-switch ${
                  isVariableProduct
                    ? "create-product-component-switch-active"
                    : "create-product-component-switch-inactive"
                }`}
              >
                <span
                  className={`create-product-component-switch-toggle ${
                    isVariableProduct
                      ? "create-product-component-switch-toggle-active"
                      : "create-product-component-switch-toggle-inactive"
                  }`}
                />
              </Switch>
            </Switch.Group>

            {/* RENDER VARIANTS IF VARIABLE */}
            {isVariableProduct && (
              <Variants
                variants={variants}
                setVariants={setVariants}
                isVariableProduct={isVariableProduct}
                productWeight={productWeight}
                weightUnitOfMeasurement={weightUnitOfMeasurement}
                productDimensions={productDimensions}
                dimensionUnitOfMeasurement={dimensionUnitOfMeasurement}
                reportErrors={handleVariantErrors}
                formErrors={formErrors}
                hasSubmitted={hasSubmitted}
              />
            )}

            {/* SUBMIT BUTTON */}
            <div className="create-product-component-submit">
              <button type="submit" className="create-product-component-submit-button">
                Create Product
              </button>
            </div>
          </form>

          {/* IMAGE CROP MODAL */}
          {showImageCropper && selectedImageForCrop && (
            <ImageCropperModal
              updateAvatar={handleImageCropCompletion}
              closeModal={() => {
                setShowImageCropper(false);
                setSelectedImageForCrop(null);
                setFieldNameBeingCropped("");
              }}
              initialImage={selectedImageForCrop}
              aspectRatio={1}
              minWidth={400}
              isAvatar={false}
              isPost={false}
              isLogo={false}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CreateProduct;

