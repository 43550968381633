import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { server } from '../../server';
import { AiOutlineClose } from "react-icons/ai";


const AdminTagManagement = () => {
    const [tags, setTags] = useState([]);
    const [tagName, setTagName] = useState('');
    const [tagDescription, setTagDescription] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchTags();
    }, []);

    const fetchTags = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${server}/tags/tags`, { withCredentials: true });
            setTags(response.data);
        } catch (error) {
            console.error('Failed to fetch tags:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!tagName.trim() || !tagDescription.trim()) {
            alert('Please provide both name and description for the tag.');
            return;
        }
        try {
            const response = await axios.post(`${server}/tags/tag`, { 
                name: tagName, 
                description: tagDescription 
            }, { withCredentials: true });
            setTags([...tags, response.data]);
            setTagName('');
            setTagDescription('');
        } catch (error) {
            console.error('Error adding tag:', error);
        }
    };

    const handleApprove = async (id) => {
        try {
            await axios.patch(`${server}/tags/tags/approve/${id}`, {}, { withCredentials: true });
            setTags(tags.map(tag => tag._id === id ? { ...tag, approved: true } : tag));
        } catch (error) {
            console.error('Error approving tag:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${server}/tags/delete-tag/${id}`, { withCredentials: true });
            setTags(tags.filter(tag => tag._id !== id));
        } catch (error) {
            console.error('Error deleting tag:', error);
        }
    };

    const approvedTags = tags.filter(tag => tag.approved);
    const unapprovedTags = tags.filter(tag => !tag.approved);

    return (
        <div className="admin-tag-management">
            <h2 className="admin-heading">Manage Tags</h2>
            <form onSubmit={handleSubmit} className="admin-tag-form">
                <input
                    type="text"
                    className="admin-tag-input admin-tag-name-input"
                    placeholder="Tag Name"
                    value={tagName}
                    onChange={(e) => setTagName(e.target.value)}
                    required
                />
                <input
                    type="text"
                    className="admin-tag-input admin-tag-description-input"
                    placeholder="Tag Description"
                    value={tagDescription}
                    onChange={(e) => setTagDescription(e.target.value)}
                    required
                />
                <button type="submit" className="admin-tagbtn admin-tagbtn-add-tag">Add Tag</button>
            </form>
            {loading ? (
                <p className="admin-loading-text">Loading tags...</p>
            ) : (
                <div className="admin-tags-container">
                    <section className="admin-tag-section">
                        <h3 className="admin-tag-section-title">Approved Tags</h3>
                        {approvedTags.length === 0 ? (
                            <p className="admin-no-tags-text">No approved tags available.</p>
                        ) : (
                            <ul className="admin-tag-list">
                                {approvedTags.map(tag => (
                                    <li key={tag._id} className="admin-tag-item">
                                        <div className="admin-tag-info">
                                            <span className="admin-tag-name approved">{tag.name}</span>
                                            <span className="admin-tag-description">{tag.description}</span>
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => handleDelete(tag._id)}
                                            className="admin-tagbtn admin-tagbtn-delete"
                                            aria-label={`Delete tag ${tag.name}`}
                                        >
                                            <AiOutlineClose />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </section>
                    <section className="admin-tag-section">
                        <h3 className="admin-tag-section-title">Tags Waiting for Approval</h3>
                        {unapprovedTags.length === 0 ? (
                            <p className="admin-no-tags-text">No tags waiting for approval.</p>
                        ) : (
                            <ul className="admin-tag-list">
                                {unapprovedTags.map(tag => (
                                    <li key={tag._id} className="admin-tag-item">
                                        <div className="admin-tag-info">
                                            <span className="admin-tag-name unapproved">{tag.name}</span>
                                            <span className="admin-tag-description">{tag.description}</span>
                                        </div>
                                        <div className="admin-tag-actions">
                                            <button
                                                onClick={() => handleApprove(tag._id)}
                                                className="admin-tagbtn admin-tagbtn-approve"
                                                aria-label={`Approve tag ${tag.name}`}
                                            >
                                                Approve
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => handleDelete(tag._id)}
                                                className="admin-tagbtn admin-tagbtn-delete"
                                                aria-label={`Delete tag ${tag.name}`}
                                            >
                                                <AiOutlineClose />
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </section>
                </div>
            )}
        </div>
    );
};

export default AdminTagManagement;


