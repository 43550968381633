import React, { useEffect } from "react";
import { AiOutlineArrowRight, AiOutlineDollar, AiOutlineMoneyCollect, AiOutlineOrderedList, AiOutlineShop } from "react-icons/ai";
import { MdBorderClear } from "react-icons/md";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfAdmin } from "../../redux/actions/order";
import { getAllSellers } from "../../redux/actions/sellers";
import Loader from "../Layout/Loader";

const AdminDashboardMain = () => {
  const dispatch = useDispatch();
  const { adminOrders, adminOrderLoading } = useSelector((state) => state.order);
  const { sellers } = useSelector((state) => state.seller);

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
    dispatch(getAllSellers());
  }, [dispatch]);

  const adminEarning = adminOrders?.reduce((acc, item) => acc + item.totalPrice * 0.1, 0);
  const adminBalance = adminEarning?.toFixed(2);

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.7 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.row.status === "Delivered" ? "greenColor" : "redColor";
      },
    },
    {
      field: "itemsQty",
      headerName: "Items Qty",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "total",
      headerName: "Total",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
    {
      field: "createdAt",
      headerName: "Order Date",
      type: "number",
      minWidth: 130,
      flex: 0.8,
    },
  ];

  const rows = adminOrders?.map((item) => ({
    id: item._id,
    itemsQty: item.cart.reduce((acc, cartItem) => acc + cartItem.qty, 0),
    total: `${item.totalPrice} $`,
    status: item.status,
    createdAt: item.createdAt.slice(0, 10),
  })) || [];

  return (
    <>
      {adminOrderLoading ? (
        <Loader />
      ) : (
        <div className="admin-dashboard-main-container">
          <div className="admin-dashboard-main-overview">
            <div className="admin-dashboard-main-card">
              <div className="admin-dashboard-main-card-content">
                <AiOutlineDollar size={30} className="admin-dashboard-main-icon" />
                <h3 className="admin-dashboard-main-card-title">Total Earning</h3>
              </div>
              <h5 className="admin-dashboard-main-card-value">$ {adminBalance}</h5>
            </div>

            <div className="admin-dashboard-main-card">
              <div className="admin-dashboard-main-card-content">
                <AiOutlineShop size={30} className="admin-dashboard-main-icon" />
                <h3 className="admin-dashboard-main-card-title">All Sellers</h3>
              </div>
              <h5 className="admin-dashboard-main-card-value">{sellers?.length}</h5>
              <Link to="/admin-sellers" className="admin-dashboard-main-card-link">View Sellers</Link>
            </div>

            <div className="admin-dashboard-main-card">
              <div className="admin-dashboard-main-card-content">
                <AiOutlineOrderedList size={30} className="admin-dashboard-main-icon" />
                <h3 className="admin-dashboard-main-card-title">All Orders</h3>
              </div>
              <h5 className="admin-dashboard-main-card-value">{adminOrders?.length}</h5>
              <Link to="/admin-orders" className="admin-dashboard-main-card-link">View Orders</Link>
            </div>
          </div>
          <div className="admin-dashboard-orders__section">
              <h2 className="admin-dashboard-orders__title">Latest Orders</h2>
              <div className="admin-dashboard-orders__table-container">
                <table className="admin-dashboard-orders__table">
                  <thead className="admin-dashboard-orders__thead">
                    <tr>
                      {columns.map((column) => (
                        <th key={column.field} style={{ minWidth: column.minWidth }}>
                          {column.headerName}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="admin-dashboard-orders__tbody">
                    {rows.map((row) => (
                      <tr
                        key={row.id}
                        className="admin-dashboard-orders__tr"
                      >
                        <td>{row.id}</td>
                        <td
                          className={`admin-dashboard-orders__status ${
                            row.status === "Delivered"
                              ? "admin-dashboard-orders__status--delivered"
                              : "admin-dashboard-orders__status--pending"
                          }`}
                        >
                          {row.status}
                        </td>
                        <td>{row.itemsQty}</td>
                        <td>{row.total}</td>
                        <td>{row.createdAt}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
        </div>
      )}
    </>
  );
};

export default AdminDashboardMain;