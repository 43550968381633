import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, removeFromWishlist } from '../../redux/actions/wishlist';
import { toast } from 'react-toastify';
import { AiFillHeart, AiOutlineHeart, AiOutlineStar, AiFillStar } from 'react-icons/ai';
import QuickViewModal from './QuickViewModal';
import { debounce } from 'lodash';

const ProductInfiniteMosaic = ({ allProducts }) => {
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const [filterCategory, setFilterCategory] = useState('All');
  const [sortOption, setSortOption] = useState('Newest');
  const [hasMore, setHasMore] = useState(true);
  const [currentCount, setCurrentCount] = useState(0);
  const productsPerLoad = 20;

  const dispatch = useDispatch();
  const { wishlist } = useSelector((state) => state.wishlist);

  // Helper: shuffle an array
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // Deduplicate products by 'name'
  const deduplicateByName = (products) => {
    const uniqueProductsMap = new Map();
    for (const product of products) {
      const productKey = product.name; // use product.name as the key
      if (!uniqueProductsMap.has(productKey)) {
        uniqueProductsMap.set(productKey, product);
      }
    }
    return Array.from(uniqueProductsMap.values());
  };

  useEffect(() => {
    console.log('All Products:', allProducts);
    if (Array.isArray(allProducts)) {
      // Deduplicate products by name
      const uniqueProducts = deduplicateByName(allProducts);

      const shuffledProducts = shuffleArray(uniqueProducts);
      const initialLoad = shuffledProducts.slice(0, productsPerLoad);
      setDisplayedProducts(initialLoad);
      setCurrentCount(productsPerLoad);
      setHasMore(shuffledProducts.length > productsPerLoad);
    }
  }, [allProducts, productsPerLoad, filterCategory, sortOption]);

  const loadMoreProducts = () => {
    if (!Array.isArray(allProducts)) {
      setHasMore(false);
      return;
    }

    const uniqueProducts = deduplicateByName(allProducts);
    const shuffledProducts = shuffleArray(uniqueProducts);
    const newProducts = shuffledProducts.slice(currentCount, currentCount + productsPerLoad);
    setDisplayedProducts((prevProducts) => [...prevProducts, ...newProducts]);
    setCurrentCount((prevCount) => prevCount + productsPerLoad);

    if (currentCount + productsPerLoad >= shuffledProducts.length) {
      setHasMore(false);
    }
  };

  useEffect(() => {
    if (!hasMore) return;

    const handleScroll = debounce(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 100 >=
        document.documentElement.offsetHeight
      ) {
        loadMoreProducts();
      }
    }, 200);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasMore, currentCount, allProducts]);

  // Filter and sort products
  let filteredProducts = displayedProducts.filter(
    (product) => filterCategory === 'All' || product.category === filterCategory
  );

  let sortedProducts = [...filteredProducts];

  switch (sortOption) {
    case 'Price: Low to High':
      sortedProducts.sort((a, b) => a.originalPrice - b.originalPrice);
      break;
    case 'Price: High to Low':
      sortedProducts.sort((a, b) => b.originalPrice - a.originalPrice);
      break;
    case 'Rating':
      sortedProducts.sort((a, b) => (b.ratings || 0) - (a.ratings || 0));
      break;
    default: // 'Newest'
      sortedProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }

  const categories = Array.isArray(allProducts)
    ? Array.from(new Set(allProducts.map((product) => product.category)))
    : [];

  if (!Array.isArray(allProducts)) {
    return <div className="mosaic-error">No products available.</div>;
  }

  return (
    <div className="mosaic-product-mosaic-container">
      <div className="mosaic-control-panel">
        <select
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
          aria-label="Filter Products by Category"
          className="mosaic-control-select"
        >
          <option value="All">All Categories</option>
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>

        <select
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          aria-label="Sort Products"
          className="mosaic-control-select"
        >
          <option value="Newest">Newest</option>
          <option value="Price: Low to High">Price: Low to High</option>
          <option value="Price: High to Low">Price: High to Low</option>
          <option value="Rating">Rating</option>
        </select>
      </div>

      <div className="mosaic-product-mosaic">
        {sortedProducts.map((product) => (
          <MosaicItem key={product._id} product={product} wishlist={wishlist} dispatch={dispatch} />
        ))}
      </div>

      {hasMore && <div className="mosaic-loading-indicator"></div>}
    </div>
  );
};

const MosaicItem = ({ product, wishlist, dispatch }) => {
  const isFavorited = wishlist && wishlist.find((item) => item._id === product._id);
  const [showModal, setShowModal] = useState(false);

  const handleFavorite = (e) => {
    e.stopPropagation();
    if (isFavorited) {
      dispatch(removeFromWishlist(product));
      toast.info('Removed from wishlist');
    } else {
      dispatch(addToWishlist(product));
      toast.success('Added to wishlist');
    }
  };

  const handleClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <div
        className="mosaic-mosaic-item"
        onClick={handleClick}
        role="button"
        aria-label={`View details for ${product.name}`}
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === 'Enter') handleClick();
        }}
      >
        <img
          src={product.images && product.images[0]?.url ? product.images[0].url : '/placeholder-image.jpg'}
          alt={product.name}
          className="mosaic-mosaic-image"
          loading="lazy"
        />
        <div
          className="mosaic-favorite-icon"
          onClick={handleFavorite}
          aria-label={isFavorited ? 'Remove from wishlist' : 'Add to wishlist'}
        >
          {isFavorited ? <AiFillStar size={22} className="favorite-icon-active" /> : <AiOutlineStar size={22} />}
        </div>
        <div className="mosaic-mosaic-overlay">
          <h3 className="mosaic-product-name">{product.name}</h3>
          <p className="mosaic-product-price">
            ${product.originalPrice ? product.originalPrice.toFixed(2) : 'N/A'}
          </p>
          <div className="mosaic-rating">
            {Array.from({ length: 5 }, (_, i) => (
              <span key={i} className={`mosaic-star ${i < (product.ratings || 0) ? 'filled' : ''}`}>
                ★
              </span>
            ))}
          </div>
        </div>
      </div>

      {showModal && <QuickViewModal product={product} onClose={() => setShowModal(false)} />}
    </>
  );
};

export default ProductInfiniteMosaic;








