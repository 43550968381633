import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { ecommerceServer } from '../../server';

// Map ShipEngine's `friendlyName` codes to display names
const carrierDisplayNames = {
  'stamps_com': 'Stamps.com',
  'ups': 'UPS',
  'fedex': 'FedEx',
  'dhl_express_mydhl_api': 'DHL',
  'usps': 'USPS',
};

// Map display names to logos
const carrierLogos = {
  'UPS': 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/48f4c6bc-8f27-4a35-e0c3-f220dd276b00/public',
  'FedEx': 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/59d69ef9-0e9d-4c0d-2025-990266929f00/public',
  'DHL': 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/665e5e0b-948a-425a-6b66-e3154ca82400/public',
  'USPS': 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/38263d2d-8074-45b6-2c65-6b094b542a00/public',
  'Stamps.com': 'https://imagedelivery.net/mEHe7vWpwEHOhne-YSqzMw/38263d2d-8074-45b6-2c65-6b094b542a00/public',
};

const DashboardCarriersSettings = () => {
  const [myCarriers, setMyCarriers] = useState([]);        // The IDs from the user's shop
  const [allCarriers, setAllCarriers] = useState([]);       // All possible carriers from ShipEngine
  const [selectedCarriers, setSelectedCarriers] = useState([]); // Our local working list (checkbox states)

  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const { seller } = useSelector((state) => state.seller);
  const currentProfileName = sessionStorage.getItem('currentProfile');

  // 1. Fetch MY carriers from the new route
  useEffect(() => {
    const fetchMyCarriers = async () => {
      try {
        const res = await axios.get(`${ecommerceServer}/shipping/my-carriers`, {
          headers: {
            'current-profile': currentProfileName || 'defaultProfile',
          },
          withCredentials: true,
        });

        if (res.data && res.data.myCarriers) {
          setMyCarriers(res.data.myCarriers);
          setSelectedCarriers(res.data.myCarriers); // initialize local state
        }
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }
    };

    fetchMyCarriers();
  }, [currentProfileName]);

  // 2. Fetch ALL carriers from /available
  useEffect(() => {
    const fetchAllCarriers = async () => {
      try {
        const res = await axios.get(`${ecommerceServer}/shipping/available`, {
          headers: {
            'current-profile': currentProfileName || 'defaultProfile',
          },
          withCredentials: true,
        });
        if (res.data && res.data.carriers) {
          setAllCarriers(res.data.carriers);
        }
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }
    };

    fetchAllCarriers();
  }, [currentProfileName]);

  // 3. Separate them into "currently selected" vs. "others"
  const selectedCarrierItems = allCarriers.filter((c) => selectedCarriers.includes(c.id));
  const otherCarrierItems = allCarriers.filter((c) => !selectedCarriers.includes(c.id));

  // 4. Handle check/uncheck
  const handleCheckboxChange = (carrierId) => {
    setError(null);
    setSuccessMessage('');

    setSelectedCarriers((prev) =>
      prev.includes(carrierId)
        ? prev.filter((id) => id !== carrierId)
        : [...prev, carrierId]
    );
  };

  // 5. Save changes to backend
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedCarriers.length === 0) {
      setError('Please select at least one carrier.');
      return;
    }

    try {
      setError(null);
      setSuccessMessage('');

      // PUT /shipping/update-carriers expects { carriers: [...] }
      const response = await axios.put(
        `${ecommerceServer}/shipping/update-carriers`,
        { carriers: selectedCarriers },
        {
          headers: {
            'current-profile': currentProfileName || 'defaultProfile',
          },
          withCredentials: true,
        }
      );

      setSuccessMessage('Carriers updated successfully!');
      console.log('Carriers updated:', response.data);

      // Optionally update the myCarriers state to reflect the newly saved carriers
      setMyCarriers(selectedCarriers);
    } catch (err) {
      setError(err.response?.data?.message || err.message || 'Failed to update carriers.');
    }
  };

  return (
    <div className="dashboard-shipping-settings">
      <h2 className="dashboard-shipping-settings__title">Manage Shipping Carriers</h2>

      {error && <p className="dashboard-shipping-settings__error">{error}</p>}
      {successMessage && <p className="dashboard-shipping-settings__success">{successMessage}</p>}

      <form onSubmit={handleSubmit} className="dashboard-shipping-settings__form">
        <fieldset className="dashboard-shipping-settings__fieldset">

          {/* ----- Currently Selected Carriers ----- */}
          <div className="dashboard-shipping-settings__section">
            <h3>Currently Selected Carriers</h3>
            {selectedCarrierItems.length === 0 && (
              <p>No carriers are currently selected.</p>
            )}
            <div className="dashboard-shipping-settings__carriers-list">
              {selectedCarrierItems.map((carrier) => {
                const displayName = carrierDisplayNames[carrier.code] || carrier.code;
                const logo = carrierLogos[displayName] || 'https://via.placeholder.com/40';

                return (
                  <label key={carrier.id} className="dashboard-shipping-settings__carrier-option">
                    <input
                      type="checkbox"
                      checked={true}
                      onChange={() => handleCheckboxChange(carrier.id)}
                    />
                    <div className="carrier-option__logo-container">
                      <img
                        src={logo}
                        alt={`${displayName} logo`}
                        className="carrier-option__logo"
                      />
                    </div>
                    <span className="carrier-option__label">{displayName}</span>
                  </label>
                );
              })}
            </div>
          </div>

          {/* ----- Other Available Carriers ----- */}
          <div className="dashboard-shipping-settings__section">
            <h3>Other Available Carriers</h3>
            {otherCarrierItems.length === 0 && (
              <p>All available carriers have been selected.</p>
            )}
            <div className="dashboard-shipping-settings__carriers-list">
              {otherCarrierItems.map((carrier) => {
                const displayName = carrierDisplayNames[carrier.code] || carrier.code;
                const logo = carrierLogos[displayName] || 'https://via.placeholder.com/40';

                return (
                  <label key={carrier.id} className="dashboard-shipping-settings__carrier-option">
                    <input
                      type="checkbox"
                      checked={false}
                      onChange={() => handleCheckboxChange(carrier.id)}
                    />
                    <div className="carrier-option__logo-container">
                      <img
                        src={logo}
                        alt={`${displayName} logo`}
                        className="carrier-option__logo"
                      />
                    </div>
                    <span className="carrier-option__label">{displayName}</span>
                  </label>
                );
              })}
            </div>
          </div>
        </fieldset>

        <button type="submit" className="dashboard-shipping-settings__submit-btn">
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default DashboardCarriersSettings;


