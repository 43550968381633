import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addTocart } from '../../redux/actions/cart';
import { addToWishlist, removeFromWishlist } from '../../redux/actions/wishlist';
import { toast } from 'react-toastify';
import { AiFillHeart, AiFillStar, AiOutlineHeart, AiOutlineShoppingCart, AiOutlineStar } from 'react-icons/ai';
import AuthenticationPrompt from '../Profile/AuthenticationPrompt'; 

function truncateDescription(text = "", wordLimit = 30) {
  const words = text.split(/\s+/);
  return words.length <= wordLimit
    ? text
    : words.slice(0, wordLimit).join(" ") + "...";
}

// Helper function to concatenate class names
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const QuickViewModal = ({ product, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Grab cart and wishlist from Redux
  const { cart } = useSelector((state) => state.cart);
  const { wishlist } = useSelector((state) => state.wishlist);

  // Grab user & seller from Redux to check if they're logged in
  const { user } = useSelector((state) => state.user);
  const { activeSeller: seller } = useSelector((state) => state.seller);

  // If user OR seller is present, consider them "logged in"
  const isLoggedIn = Boolean(user?._id || seller?._id);

  const isFavorited = wishlist && wishlist.find((item) => item._id === product._id);

  // State for showing the authentication prompt
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);

  // Local states for variants
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedCustomValue, setSelectedCustomValue] = useState(null);
  const [quantity, setQuantity] = useState(1);

  // Check if the product is a variable product
  const isVariableProduct = Array.isArray(product.variants) && product.variants.length > 0;

  // Helper function to map color names to background colors
  function getColorBg(colorName) {
    const colorMap = {
      Black: '#000000',
      White: '#FFFFFF',
      Red: '#FF0000',
      Blue: '#0000FF',
      Green: '#008000',
      // Add more mappings as needed
    };
    return colorMap[colorName] || '#CCCCCC'; // Default color
  }

  // Extract unique colors, sizes, and custom values if variable
  const colors = isVariableProduct
    ? Array.from(new Set(product.variants.map((variant) => variant.color))).map((color) => ({
        name: color,
        bgColor: getColorBg(color),
      }))
    : [];

  const sizes = isVariableProduct
    ? Array.from(new Set(product.variants.map((variant) => variant.size))).map((size) => ({
        name: size,
        inStock: product.variants.some((variant) => variant.size === size && variant.stock > 0),
      }))
    : [];

  const customValues = isVariableProduct
    ? Array.from(new Set(product.variants.map((variant) => variant.customValue))).map((customValue) => ({
        name: customValue,
      }))
    : [];

  // Set default variant on modal open
  useEffect(() => {
    if (isVariableProduct && product.variants.length > 0) {
      const defaultVariant = product.variants[0];
      setSelectedVariant(defaultVariant);
      setSelectedColor(defaultVariant.color);
      setSelectedSize(defaultVariant.size);
      setSelectedCustomValue(defaultVariant.customValue);
    } else {
      // If no variants exist, treat the product as the selected "variant"
      setSelectedVariant(product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  // Helper to require login before performing an action
  const requireLogin = (action) => {
    if (!isLoggedIn) {
      setShowAuthPrompt(true);
      return;
    }
    action();
  };

  // Handle Add/Remove from wishlist, wrapped with requireLogin
  const handleFavorite = () => {
    requireLogin(() => {
      if (isFavorited) {
        dispatch(removeFromWishlist(product));
        toast.info('Removed from wishlist');
      } else {
        dispatch(addToWishlist(product));
        toast.success('Added to wishlist');
      }
    });
  };

  // Handle variant changes
  const handleVariantChange = (variant) => {
    setSelectedVariant(variant);
    setSelectedColor(variant.color);
    setSelectedSize(variant.size);
    setSelectedCustomValue(variant.customValue);
    setQuantity(1);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    const matchingVariant = product.variants.find(
      (variant) =>
        variant.color === color.name &&
        variant.size === selectedSize?.name &&
        variant.customValue === selectedCustomValue?.name
    );
    setSelectedVariant(matchingVariant || null);
    setQuantity(1);
  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
    const matchingVariant = product.variants.find(
      (variant) =>
        variant.size === size.name &&
        variant.color === selectedColor?.name &&
        variant.customValue === selectedCustomValue?.name
    );
    setSelectedVariant(matchingVariant || null);
    setQuantity(1);
  };

  const handleCustomValueChange = (customValue) => {
    setSelectedCustomValue(customValue);
    const matchingVariant = product.variants.find(
      (variant) =>
        variant.customValue === customValue.name &&
        variant.color === selectedColor?.name &&
        variant.size === selectedSize?.name
    );
    setSelectedVariant(matchingVariant || null);
    setQuantity(1);
  };

  // Handle quantity
  const handleQuantityChange = (amount) => {
    setQuantity((prevQty) => {
      const maxQty = isVariableProduct
        ? product.variants.find(
            (variant) =>
              variant.color === (selectedColor ? selectedColor.name : '') &&
              variant.size === (selectedSize ? selectedSize.name : '') &&
              variant.customValue === (selectedCustomValue ? selectedCustomValue.name : '')
          )?.stock || 1
        : product.stock || 1;

      const newQty = prevQty + amount;
      if (newQty < 1) return 1;
      if (newQty > maxQty) return maxQty;
      return newQty;
    });
  };

  // Handle Add to Cart
  const handleAddToCart = () => {
    // Check if the product has variants
    if (isVariableProduct && !selectedVariant) {
      toast.error("Please select a valid variant before adding to cart!");
      return;
    }

    // Identify if the item is already in the cart
    const isItemExists = cart && cart.find(
      (item) =>
        item._id === product._id &&
        (!item.variant || (item.variant._id === selectedVariant?._id))
    );

    if (isItemExists) {
      toast.error("This product is already in the cart!");
    } else {
      // Check stock
      const availableStock = isVariableProduct ? selectedVariant.stock : product.stock;

      if (availableStock < 1) {
        toast.error("Product is out of stock!");
      } else {
        // Prepare the data to be sent to the cart
        const cartData = {
          _id: product._id,
          name: product.name,
          brand: product.brand,
          SKU: isVariableProduct ? selectedVariant.sku : product.SKU,
          category: product.category,
          brandTypeSelection: product.brandTypeSelection,
          description: isVariableProduct ? selectedVariant.description : product.description,
          price: isVariableProduct ? selectedVariant.price : product.discountPrice || product.price,
          stock: isVariableProduct ? selectedVariant.stock : product.stock,
          weight: isVariableProduct ? selectedVariant.weight : product.weight,
          weightUnitOfMeasurement: isVariableProduct
            ? selectedVariant.weightUnitOfMeasurement
            : product.weightUnitOfMeasurement,
          dimensions: isVariableProduct ? selectedVariant.dimensions : product.dimensions,
          dimensionUnitOfMeasurement: isVariableProduct
            ? selectedVariant.dimensionUnitOfMeasurement
            : product.dimensionUnitOfMeasurement,
          image: isVariableProduct
            ? selectedVariant.image?.url || product.images[0]?.url
            : product.images[0]?.url || '/placeholder-image.jpg',
          qty: quantity,
          variant: isVariableProduct ? selectedVariant : null,
          availableForDelivery: product.availableForDelivery,
          availableForPickup: product.availableForPickup,
          barcodeString: product.barcodeString,
          isEdible: product.isEdible,
          isOnSale: product.isOnSale,
          taxCode: product.taxCode,
          shopId: product.shopId,
          shop: product.shop,
        };

        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
        onClose(); // Close the modal after adding to cart
      }
    }
  };

  // Handle View Full Details
  const handleViewDetails = () => {
    navigate(`/product/${product._id}`);
    onClose();
  };

  return (
    <div className="mosaic-modal-overlay" onClick={onClose} role="dialog" aria-modal="true">
      <div className="mosaic-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="mosaic-modal-close" onClick={onClose} aria-label="Close">
          ×
        </button>
        <div className="mosaic-modal-inner">
          <div className="mosaic-modal-image-section">
            {isVariableProduct && selectedVariant ? (
              <img
                src={selectedVariant.image?.url || '/placeholder-image.jpg'}
                alt={product.name}
                className="mosaic-modal-image"
              />
            ) : (
              <img
                src={product.images && product.images[0]?.url ? product.images[0].url : '/placeholder-image.jpg'}
                alt={product.name}
                className="mosaic-modal-image"
              />
            )}
          </div>

          <div className="mosaic-modal-details-section">
            <h2 className="mosaic-modal-product-name">{product.name}</h2>
            <p className="mosaic-modal-price">
              ${isVariableProduct && selectedVariant
                ? selectedVariant.price.toFixed(2)
                : (product.discountPrice || product.originalPrice).toFixed(2)}
            </p>

            {/* Wishlist Icon (requires login) */}
            <div className="mosaic-modal-row">
              <div
                className="mosaic-modal-wishlist-icon"
                onClick={() => requireLogin(handleFavorite)} // <--- Wrap in requireLogin
                aria-label={isFavorited ? 'Remove from wishlist' : 'Add to wishlist'}
              >
                {isFavorited ? (
                  <AiFillStar size={25} className="favorite-icon-active" />
                ) : (
                  <AiOutlineStar size={25} color="#333" />
                )}
              </div>

              <div className="mosaic-modal-rating">
                <span className="mosaic-modal-rating-value">{product.ratings || '0'}</span>
                <div className="mosaic-modal-stars">
                  {Array.from({ length: 5 }, (_, i) => (
                    <span
                      key={i}
                      className={classNames(
                        i < (product.ratings || 0)
                          ? 'mosaic-modal-star-filled'
                          : 'mosaic-modal-star-empty'
                      )}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <span className="mosaic-modal-review-count">
                  ({product.reviewCount || 0} reviews)
                </span>
              </div>
            </div>

            <p className="mosaic-modal-description">
              {truncateDescription(product.description, 30)}
            </p>

            {/* Color Picker */}
            {isVariableProduct && colors.length > 0 && (
              <div className="mosaic-modal-section">
                <h3 className="mosaic-modal-section-title">Color</h3>
                <div className="mosaic-modal-options">
                  {colors.map((color) => (
                    <label
                      key={color.name}
                      className={classNames(
                        selectedColor && selectedColor.name === color.name
                          ? 'mosaic-modal-option-selected'
                          : '',
                        'mosaic-modal-option'
                      )}
                    >
                      <input
                        type="radio"
                        name="color"
                        value={color.name}
                        checked={selectedColor && selectedColor.name === color.name}
                        onChange={() => handleColorChange(color)}
                        className="mosaic-modal-option-input"
                      />
                      <span
                        className="mosaic-modal-option-color"
                        style={{ backgroundColor: color.bgColor }}
                        aria-label={color.name}
                      ></span>
                      <span className="mosaic-modal-option-name">{color.name}</span>
                    </label>
                  ))}
                </div>
              </div>
            )}

            {/* Size Picker */}
            {isVariableProduct && sizes.length > 0 && (
              <div className="mosaic-modal-section">
                <h3 className="mosaic-modal-section-title">Size</h3>
                <div className="mosaic-modal-options">
                  {sizes.map((size) => (
                    <label
                      key={size.name}
                      className={classNames(
                        selectedSize && selectedSize.name === size.name
                          ? 'mosaic-modal-option-selected'
                          : '',
                        size.inStock ? 'mosaic-modal-option' : 'mosaic-modal-option-disabled'
                      )}
                    >
                      <input
                        type="radio"
                        name="size"
                        value={size.name}
                        checked={selectedSize && selectedSize.name === size.name}
                        onChange={() => handleSizeChange(size)}
                        disabled={!size.inStock}
                        className="mosaic-modal-option-input"
                      />
                      <span className="mosaic-modal-option-name">{size.name}</span>
                    </label>
                  ))}
                </div>
              </div>
            )}

            {/* Custom Value Picker */}
            {isVariableProduct && customValues.length > 0 && (
              <div className="mosaic-modal-section">
                <h3 className="mosaic-modal-section-title">
                  {product.variants[0]?.customTitle || 'Customization'}
                </h3>
                <div className="mosaic-modal-options">
                  {customValues.map((customValue) => (
                    <label
                      key={customValue.name}
                      className={classNames(
                        selectedCustomValue && selectedCustomValue.name === customValue.name
                          ? 'mosaic-modal-option-selected'
                          : '',
                        'mosaic-modal-option'
                      )}
                    >
                      <input
                        type="radio"
                        name="customValue"
                        value={customValue.name}
                        checked={
                          selectedCustomValue && selectedCustomValue.name === customValue.name
                        }
                        onChange={() => handleCustomValueChange(customValue)}
                        className="mosaic-modal-option-input"
                      />
                      <span className="mosaic-modal-option-name">{customValue.name}</span>
                    </label>
                  ))}
                </div>
              </div>
            )}

            <div className="mosaic-modal-buttons">
              {/* Add to Cart Button */}
              <button
                className="mosaic-modal-add-to-bag-button"
                onClick={handleAddToCart}
              >
                <AiOutlineShoppingCart size={20} style={{ marginRight: '5px' }} />
                Add to Cart
              </button>

              {/* View Full Details Button */}
              <div className="mosaic-modal-full-details">
                <button className="mosaic-modal-view-details-button" onClick={handleViewDetails}>
                  View full details
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Reviews Section */}
        {product.reviews && product.reviews.length > 0 && (
          <div className="mosaic-modal-reviews-section">
            <h3>Customer Reviews</h3>
            {product.reviews.map((review) => (
              <div key={review._id} className="mosaic-modal-review">
                <p className="mosaic-modal-reviewer">
                  <strong>{review.reviewerName || 'Anonymous'}</strong>
                </p>
                <p className="mosaic-modal-review-comment">{review.comment}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* If user is not logged in, show the Auth prompt in a React Portal */}
      {showAuthPrompt &&
        ReactDOM.createPortal(
          <div className="auth-modal-backdrop" onClick={() => setShowAuthPrompt(false)}>
            <div
              className="auth-modal-content"
              onClick={(e) => e.stopPropagation()}
              role="dialog"
              aria-modal="true"
            >
              <AuthenticationPrompt />
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

export default QuickViewModal;
